/**-----------------------------------------------------------------------------------------
* Copyright © 2025 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Component, Input, ContentChildren, QueryList, Directive, Optional, forwardRef, SkipSelf, Host, ContentChild, NgModule } from '@angular/core';
import { saveAs } from '@progress/kendo-file-saver';
import { IntlService, ExcelExporter, Workbook } from '@progress/kendo-ooxml';
export * from '@progress/kendo-ooxml';
import { toString } from '@progress/kendo-intl';
import * as i1 from '@progress/kendo-angular-l10n';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { validatePackage } from '@progress/kendo-licensing';
const compileTemplate = (templateRef, context, updateContext) => {
  let embeddedView = templateRef.createEmbeddedView(context);
  const result = data => {
    updateContext(context, data);
    embeddedView.detectChanges();
    return embeddedView.rootNodes.reduce((content, rootNode) => {
      return content + rootNode.textContent;
    }, '').trim();
  };
  result.destroy = () => {
    embeddedView.destroy();
    embeddedView = null;
  };
  return result;
};
const updateGroupHeaderContext = (context, data) => {
  context.$implicit = context.group = data;
  context.field = data.field;
  context.value = data.value;
  context.aggregates = data.aggregates;
};
const updateGroupFooterContext = (context, data) => {
  context.group = data.group;
  context.$implicit = context.aggregates = data;
};
const updateFooterContext = (context, data) => {
  context.aggregates = data.aggregates;
};
/**
 * @hidden
 */
const toExporterColumns = sourceColumns => {
  const exporterColumns = [];
  let columnIndex = 0;
  const addColumns = (columns, result, level) => {
    columns.forEach(column => {
      if (column.level === level) {
        const exporterColumn = new ExporterColumn(column, columnIndex);
        result.push(exporterColumn);
        if (column.children && column.children.some(c => c !== column)) {
          const children = exporterColumn.columns = [];
          addColumns(column.children, children, level + 1);
        } else {
          columnIndex++;
        }
      }
    });
  };
  addColumns(sourceColumns, exporterColumns, 0);
  return exporterColumns;
};
/**
 * @hidden
 */
const destroyColumns = columns => {
  if (columns) {
    columns.forEach(column => {
      column.destroy();
    });
  }
};
/**
 * @hidden
 */
class ExporterColumn {
  title;
  field;
  hidden;
  locked;
  width;
  columns;
  groupHeaderTemplate;
  groupHeaderColumnTemplate;
  groupFooterTemplate;
  footerTemplate;
  headerCellOptions;
  cellOptions;
  groupHeaderCellOptions;
  groupFooterCellOptions;
  footerCellOptions;
  constructor(column, columnIndex) {
    this.title = column.title;
    this.field = column.field;
    this.hidden = column.hidden;
    this.locked = column.locked;
    this.width = column.width;
    this.headerCellOptions = column.headerCellOptions;
    this.cellOptions = column.cellOptions;
    this.groupHeaderCellOptions = column.groupHeaderCellOptions;
    this.groupFooterCellOptions = column.groupFooterCellOptions;
    this.footerCellOptions = column.footerCellOptions;
    if (column.footerTemplate) {
      this.footerTemplate = compileTemplate(column.footerTemplate.templateRef, {
        $implicit: column,
        column: column,
        columnIndex: columnIndex
      }, updateFooterContext);
    }
    if (column.groupFooterTemplate) {
      this.groupFooterTemplate = compileTemplate(column.groupFooterTemplate.templateRef, {
        column: column,
        field: column.field
      }, updateGroupFooterContext);
    }
    if (column.groupHeaderTemplate) {
      this.groupHeaderTemplate = compileTemplate(column.groupHeaderTemplate.templateRef, {}, updateGroupHeaderContext);
    }
    if (column.groupHeaderColumnTemplate) {
      this.groupHeaderColumnTemplate = compileTemplate(column.groupHeaderColumnTemplate.templateRef, {}, updateGroupHeaderContext);
    }
  }
  destroy() {
    if (this.footerTemplate) {
      this.footerTemplate.destroy();
    }
    if (this.groupFooterTemplate) {
      this.groupFooterTemplate.destroy();
    }
    if (this.groupHeaderTemplate) {
      this.groupHeaderTemplate.destroy();
    }
    if (this.groupHeaderColumnTemplate) {
      this.groupHeaderColumnTemplate.destroy();
    }
    destroyColumns(this.columns);
  }
}
IntlService.register({
  toString
});
/**
 *
 * @hidden
 */
const workbookOptions = options => {
  const columns = toExporterColumns(options.columns);
  const exporter = new ExcelExporter({
    columns: columns,
    data: options.data,
    filterable: options.filterable,
    groups: options.group,
    paddingCellOptions: options.paddingCellOptions,
    headerPaddingCellOptions: options.headerPaddingCellOptions,
    collapsible: options.collapsible,
    hierarchy: options.hierarchy,
    aggregates: options.aggregates
  });
  const result = exporter.workbook();
  result.creator = options.creator;
  result.date = options.date;
  result.rtl = options.rtl;
  destroyColumns(columns);
  return result;
};
/**
 * @hidden
 */
const toDataURL = options => {
  const workbook = new Workbook(options);
  return workbook.toDataURL();
};
/**
 * @hidden
 */
const isWorkbookOptions = value => {
  return value && value.sheets;
};

/**
 * @hidden
 */
class ColumnBase {
  parent;
  /**
   * The title of the column.
   */
  title;
  /**
   * The width of the column in pixels.
   */
  width;
  /**
   * Toggles the locked (frozen) state of the column ([see example]({% slug columns_excel-export %}#toc-locked-state)).
   *
   * @default false
   */
  locked;
  /**
   * Sets the visibility of the column ([see example]({% slug columns_excel-export %}#toc-hidden-state)).
   *
   * @default false
   */
  hidden;
  /**
   * The options of the column header cell.
   */
  headerCellOptions;
  /**
   * @hidden
   */
  children;
  /**
   * @hidden
   */
  get level() {
    return this.parent ? this.parent.level + 1 : 0;
  }
  constructor(parent) {
    this.parent = parent;
  }
  static ɵfac = function ColumnBase_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ColumnBase)(i0.ɵɵdirectiveInject(ColumnBase));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ColumnBase,
    selectors: [["ng-component"]],
    contentQueries: function ColumnBase_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, ColumnBase, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
      }
    },
    inputs: {
      title: "title",
      width: "width",
      locked: "locked",
      hidden: "hidden",
      headerCellOptions: "headerCellOptions"
    },
    decls: 0,
    vars: 0,
    template: function ColumnBase_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnBase, [{
    type: Component,
    args: [{
      template: ''
    }]
  }], function () {
    return [{
      type: ColumnBase
    }];
  }, {
    title: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    locked: [{
      type: Input
    }],
    hidden: [{
      type: Input
    }],
    headerCellOptions: [{
      type: Input
    }],
    children: [{
      type: ContentChildren,
      args: [ColumnBase]
    }]
  });
})();

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-excel-export',
  productName: 'Kendo UI for Angular',
  productCode: 'KENDOUIANGULAR',
  productCodes: ['KENDOUIANGULAR'],
  publishDate: 1739287177,
  version: '18.1.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

// eslint-disable max-line-length
/**
 * Represents the [Kendo UI Excel Export component for Angular]({% slug overview_excelexport %}).
 * Configures the settings for the Excel export of the Kendo UI Grid.
 */
class ExcelExportComponent {
  localization;
  zone;
  /**
   * Specifies the name of the file that is exported to Excel.
   * @default "Export.xlsx"
   */
  fileName = 'Export.xlsx';
  /**
   * Enables or disables the column filtering in the Excel file
   * ([see example]({% slug filtering_excelexport %})).
   */
  filterable;
  /**
   * Specifies if groups are collapsible in the excel file.
   */
  collapsible;
  /**
   * The author of the workbook.
   */
  creator;
  /**
   * The date on which the workbook is created.
   * The default value is `new Date()`.
   */
  date;
  /**
   * If `forceProxy` is set to `true`, the content is forwarded to `proxyURL`
   * even if the browser supports the local saving of files.
   */
  forceProxy;
  /**
   * The URL of the server-side proxy which will stream the file to the end user. When the browser is not
   * capable of saving files locally&mdash;for example, Internet Explorer 9 and earlier, and Safari&mdash;a
   * proxy is used. The implementation of the server-side proxy has to be done by you.
   *
   * The proxy receives a POST request with the following parameters in the request body:
   * - `contentType`&mdash;The MIME type of the file.
   * - `base64`&mdash;The base-64 encoded file content.
   * - `fileName`&mdash;The file name, as requested by the caller.
   * The proxy is expected to return the decoded file with the **Content-Disposition** header set to `attachment; filename="<fileName.xslx>"`.
   */
  proxyURL;
  /**
   * The exported data. If grouped, the data must be structured as described by the
   * [`GroupResult`]({% slug api_kendo-data-query_groupresult %}) option of the Kendo UI Data Query component.
   */
  data;
  /**
   * The exported data groups. The groups must be compatible with the
   * [`GroupDescriptor`]({% slug api_kendo-data-query_groupdescriptor %}) option of the Kendo UI Data Query component.
   */
  group;
  /**
   * The options of the cells that are inserted before the data, group, and footer cells
   * to indicate the group hierarchy when the data is grouped
   * ([see example]({% slug cells_excelexport %}#toc-padding-cells)).
   */
  paddingCellOptions;
  /**
   * The options of the cells that are inserted before the header cells
   * to align the headers and the column values when the data is grouped
   * ([see example]({% slug cells_excelexport %}#toc-header-padding-cells)).
   */
  headerPaddingCellOptions;
  /**
   * @hidden
   */
  columns = new QueryList();
  constructor(localization, zone) {
    this.localization = localization;
    this.zone = zone;
    validatePackage(packageMetadata);
    this.saveFile = this.saveFile.bind(this);
  }
  /**
   * Saves the data to Excel.
   *
   * @param exportData - An optional parameter. Can be the data that will be exported or [`WorkbookOptions`]({% slug api_excel-export_workbookoptions %}).
   */
  save(exportData) {
    this.toDataURL(exportData).then(this.saveFile);
  }
  /**
   * Based on the specified columns and data, returns
   * [`WorkbookOptions`]({% slug api_excel-export_workbookoptions %})
   * ([see example]({% slug customrowsandcells_excelexport %})).
   *
   * @param exportData - The optional data to be exported.
   * @returns {WorkbookOptions} - The workbook options.
   */
  workbookOptions(exportData) {
    const currentData = this.getExportData(exportData);
    const options = workbookOptions({
      columns: this.columns,
      data: currentData.data,
      group: currentData.group,
      filterable: this.filterable,
      creator: this.creator,
      date: this.date,
      rtl: this.localization.rtl,
      paddingCellOptions: this.paddingCellOptions,
      headerPaddingCellOptions: this.headerPaddingCellOptions,
      collapsible: this.collapsible
    });
    return options;
  }
  /**
   * Returns a promise which will be resolved with the file data URI
   * ([see example]({% slug filesaving_excelexport %})).
   *
   * @param exportData - The optional data or [`WorkbookOptions`]({% slug api_excel-export_workbookoptions %}) that will be used to generate the data URI.
   * @returns {Promise<string>} - The promise that will be resolved by the file data URI.
   */
  toDataURL(exportData) {
    const options = isWorkbookOptions(exportData) ? exportData : this.workbookOptions(exportData);
    return this.zone.runOutsideAngular(() => toDataURL(options));
  }
  getExportData(exportData) {
    let result;
    if (exportData) {
      if (Array.isArray(exportData)) {
        result = {
          data: exportData
        };
      } else {
        result = exportData;
      }
    } else {
      result = {
        data: this.data,
        group: this.group
      };
    }
    return result;
  }
  saveFile(dataURL) {
    saveAs(dataURL, this.fileName, {
      forceProxy: this.forceProxy,
      proxyURL: this.proxyURL
    });
  }
  static ɵfac = function ExcelExportComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ExcelExportComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ExcelExportComponent,
    selectors: [["kendo-excelexport"]],
    contentQueries: function ExcelExportComponent_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, ColumnBase, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.columns = _t);
      }
    },
    inputs: {
      fileName: "fileName",
      filterable: "filterable",
      collapsible: "collapsible",
      creator: "creator",
      date: "date",
      forceProxy: "forceProxy",
      proxyURL: "proxyURL",
      data: "data",
      group: "group",
      paddingCellOptions: "paddingCellOptions",
      headerPaddingCellOptions: "headerPaddingCellOptions"
    },
    exportAs: ["kendoExcelExport"],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([LocalizationService, {
      provide: L10N_PREFIX,
      useValue: 'kendo.excelexport'
    }]), i0.ɵɵStandaloneFeature],
    decls: 0,
    vars: 0,
    template: function ExcelExportComponent_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExcelExportComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoExcelExport',
      selector: 'kendo-excelexport',
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.excelexport'
      }],
      template: ``,
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.NgZone
    }];
  }, {
    fileName: [{
      type: Input
    }],
    filterable: [{
      type: Input
    }],
    collapsible: [{
      type: Input
    }],
    creator: [{
      type: Input
    }],
    date: [{
      type: Input
    }],
    forceProxy: [{
      type: Input
    }],
    proxyURL: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    group: [{
      type: Input
    }],
    paddingCellOptions: [{
      type: Input
    }],
    headerPaddingCellOptions: [{
      type: Input
    }],
    columns: [{
      type: ContentChildren,
      args: [ColumnBase, {
        descendants: true
      }]
    }]
  });
})();

/**
 * Represents the footer cell template of the Excel Export column component
 * ([see example]({% slug columns_excel-export %}#toc-footer-template)).
 * Enables you to customize the footer cell of the column.
 */
class FooterTemplateDirective {
  templateRef;
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static ɵfac = function FooterTemplateDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FooterTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: FooterTemplateDirective,
    selectors: [["", "kendoExcelExportFooterTemplate", ""]],
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FooterTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoExcelExportFooterTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents the group header cell template of the Excel Export column component
 * ([see example]({% slug columns_excel-export %}#toc-group-header-template)).
 * Enables you to customize the content of the group header item.
 */
class GroupHeaderTemplateDirective {
  templateRef;
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static ɵfac = function GroupHeaderTemplateDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || GroupHeaderTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: GroupHeaderTemplateDirective,
    selectors: [["", "kendoExcelExportGroupHeaderTemplate", ""]],
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GroupHeaderTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoExcelExportGroupHeaderTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents the group header column template of the Excel Export column component
 * ([see example]({% slug columns_excel-export %}#toc-group-header-column-template)).
 */
class GroupHeaderColumnTemplateDirective {
  templateRef;
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static ɵfac = function GroupHeaderColumnTemplateDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || GroupHeaderColumnTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: GroupHeaderColumnTemplateDirective,
    selectors: [["", "kendoExcelExportGroupHeaderColumnTemplate", ""]],
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GroupHeaderColumnTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoExcelExportGroupHeaderColumnTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents the group footer cell template of the Excel Export column component
 * ([see example]({% slug columns_excel-export %}#toc-group-footer-template)).
 * Enables you to customize the group footer cell of the column.
 */
class GroupFooterTemplateDirective {
  templateRef;
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static ɵfac = function GroupFooterTemplateDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || GroupFooterTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: GroupFooterTemplateDirective,
    selectors: [["", "kendoExcelExportGroupFooterTemplate", ""]],
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GroupFooterTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoExcelExportGroupFooterTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents the columns of the Kendo UI Excel Export component for Angular.
 */
class ColumnComponent extends ColumnBase {
  /**
   * The field to which the column is bound.
   */
  field;
  /**
   * The options of the column data cells
   * ([see example]({% slug cells_excelexport %}#toc-data-cells)).
   */
  cellOptions;
  /**
   * The options of the column group header cells
   * ([see example]({% slug cells_excelexport %}#toc-header-cells)).
   */
  groupHeaderCellOptions;
  /**
   * The options of the column group footer cells
   * ([see example]({% slug cells_excelexport %}#toc-group-footer-cells)).
   */
  groupFooterCellOptions;
  /**
   * The options of the column footer cell
   * ([see example]({% slug cells_excelexport %}#toc-footer-cells)).
   */
  footerCellOptions;
  /**
   * @hidden
   */
  groupHeaderTemplate;
  /**
   * @hidden
   */
  groupHeaderColumnTemplate;
  /**
   * @hidden
   */
  groupFooterTemplate;
  /**
   * @hidden
   */
  footerTemplate;
  constructor(parent) {
    super(parent);
  }
  static ɵfac = function ColumnComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ColumnComponent)(i0.ɵɵdirectiveInject(ColumnBase, 13));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ColumnComponent,
    selectors: [["kendo-excelexport-column"]],
    contentQueries: function ColumnComponent_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, GroupHeaderTemplateDirective, 5);
        i0.ɵɵcontentQuery(dirIndex, GroupHeaderColumnTemplateDirective, 5);
        i0.ɵɵcontentQuery(dirIndex, GroupFooterTemplateDirective, 5);
        i0.ɵɵcontentQuery(dirIndex, FooterTemplateDirective, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupHeaderTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupHeaderColumnTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupFooterTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
      }
    },
    inputs: {
      field: "field",
      cellOptions: "cellOptions",
      groupHeaderCellOptions: "groupHeaderCellOptions",
      groupFooterCellOptions: "groupFooterCellOptions",
      footerCellOptions: "footerCellOptions"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([{
      provide: ColumnBase,
      useExisting: forwardRef(() => ColumnComponent)
    }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 0,
    vars: 0,
    template: function ColumnComponent_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: ColumnBase,
        useExisting: forwardRef(() => ColumnComponent)
      }],
      selector: 'kendo-excelexport-column',
      template: ``,
      standalone: true
    }]
  }], function () {
    return [{
      type: ColumnBase,
      decorators: [{
        type: SkipSelf
      }, {
        type: Host
      }, {
        type: Optional
      }]
    }];
  }, {
    field: [{
      type: Input
    }],
    cellOptions: [{
      type: Input
    }],
    groupHeaderCellOptions: [{
      type: Input
    }],
    groupFooterCellOptions: [{
      type: Input
    }],
    footerCellOptions: [{
      type: Input
    }],
    groupHeaderTemplate: [{
      type: ContentChild,
      args: [GroupHeaderTemplateDirective, {
        static: false
      }]
    }],
    groupHeaderColumnTemplate: [{
      type: ContentChild,
      args: [GroupHeaderColumnTemplateDirective, {
        static: false
      }]
    }],
    groupFooterTemplate: [{
      type: ContentChild,
      args: [GroupFooterTemplateDirective, {
        static: false
      }]
    }],
    footerTemplate: [{
      type: ContentChild,
      args: [FooterTemplateDirective, {
        static: false
      }]
    }]
  });
})();

/**
 * Represents the column group component of the Kendo UI Excel Export component.
 */
class ColumnGroupComponent extends ColumnBase {
  parent;
  constructor(parent) {
    super(parent);
    this.parent = parent;
  }
  static ɵfac = function ColumnGroupComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ColumnGroupComponent)(i0.ɵɵdirectiveInject(ColumnBase, 13));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ColumnGroupComponent,
    selectors: [["kendo-excelexport-column-group"]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([{
      provide: ColumnBase,
      useExisting: forwardRef(() => ColumnGroupComponent)
    }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 0,
    vars: 0,
    template: function ColumnGroupComponent_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnGroupComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: ColumnBase,
        useExisting: forwardRef(() => ColumnGroupComponent)
      }],
      selector: 'kendo-excelexport-column-group',
      template: ``,
      standalone: true
    }]
  }], function () {
    return [{
      type: ColumnBase,
      decorators: [{
        type: SkipSelf
      }, {
        type: Host
      }, {
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Utility array that contains all `@progress/kendo-angular-excel-export` related components and directives
 */
const KENDO_EXCELEXPORT = [ExcelExportComponent, ColumnComponent, ColumnGroupComponent, FooterTemplateDirective, GroupFooterTemplateDirective, GroupHeaderColumnTemplateDirective, GroupHeaderTemplateDirective];

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Excel Export component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the ExcelExportModule module
 * import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, ExcelExportModule], // import ExcelExportModule module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class ExcelExportModule {
  static ɵfac = function ExcelExportModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ExcelExportModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ExcelExportModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExcelExportModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_EXCELEXPORT],
      exports: [...KENDO_EXCELEXPORT]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ColumnBase, ColumnComponent, ColumnGroupComponent, ExcelExportComponent, ExcelExportModule, FooterTemplateDirective, GroupFooterTemplateDirective, GroupHeaderColumnTemplateDirective, GroupHeaderTemplateDirective, KENDO_EXCELEXPORT, isWorkbookOptions, toDataURL, workbookOptions };