import { PluginKey, Plugin } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

// https://discuss.prosemirror.net/t/passing-data-between-plugins/1843
const textHighlightKey = new PluginKey('highlight');
function textHighlight(key = textHighlightKey) {
  return new Plugin({
    key,
    state: {
      init() {
        return null;
      },
      apply(tr) {
        return tr.getMeta(this.spec.key);
      }
    },
    props: {
      decorations(state) {
        const decorations = (this.spec.key.getState(state) || []).map(d => Decoration.inline(d.from, d.to, d.attrs));
        return DecorationSet.create(state.doc, decorations);
      }
    }
  });
}
export { textHighlight, textHighlightKey };