import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AccessInfo } from '../../shared-access-module/openapi';
import { SectionHeaderAction, Stepper } from '../../shared-interfaces/shared-interfaces';
import { PrintEventService } from '../../shared-services/print-event/print-event.service';
import { PillDisplayConfig } from '../pill-v2/pill-v2.component';
import { LeftSideSectionDirective } from './left-side-section.directive';

@Component({
  selector: 'prism-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent {
  @ContentChild(LeftSideSectionDirective, {read: TemplateRef}) leftSideTemplate;
  @Input() title: string;
  @Input() pillText: string | null;
  @Input() pillDisplay: string | null;
  @Input() pillDisplayCfg: PillDisplayConfig;
  @Input() actions: SectionHeaderAction[];
  @Input() checkAccessFn: (widget: any) => Array<AccessInfo.ActionsEnum>;
  @Input() breadcrumbs: string;
  /**
   * @deprecated Use `backBtnclicked` emitter instead.
  */
  @Input() backAction: () => void;
  @Input() backActionIcon = 'arrow-chevron-left';
  @Input() stepper: Stepper;
  @Input() showPrint = false;
  @Input() showBreadCrumbs = true;
  @Input() enableBreadCrumbs = true;
  @Output() stepChange: EventEmitter<number> = new EventEmitter();
  @Output() backBtnClicked: EventEmitter<void> = new EventEmitter();

  constructor(private printEventService: PrintEventService) { }

  public get showBackBtn() {
    if (this.backAction) return true;
    if (this.backBtnClicked.observers.length > 0) return true;

    return false;
  }

  print(): void {
    this.printEventService.print();
  }

  public onBackClicked() {
    this.backBtnClicked.emit();
    if (this.backAction) this.backAction();
  }
}
