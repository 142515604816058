import { Injectable } from '@angular/core';
import { ApiFilterRequest, IntegraSearchConfigColumn, IntegraSearchConfigRow } from '../../shared-interfaces';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdvSearchFilterService {

  constructor() { }

  public getAllKeys(advSrchConfig: Array<IntegraSearchConfigRow>): string[] {
    return advSrchConfig.reduce((result, row) => {
      result.push(...row.columns.map(col => col.key));
      return result;
    }, []);
  }

  public mapSrchQueryParams(searchFilters: Array<ApiFilterRequest>, advSrchConfig: Array<IntegraSearchConfigRow>): object {
    return searchFilters?.reduce((reduced, filter) => {
      const config = this.getAdvSearchColumnByProp(filter.property, filter.operator, advSrchConfig);
      reduced[config ? config.key : filter.property] = filter.value;
      return reduced;
    }, {});
  }

  private getAdvSearchColumnByProp(property: string, operator: string,
                                        advSrchConfig: Array<IntegraSearchConfigRow>): IntegraSearchConfigColumn {
    let configColumn: IntegraSearchConfigColumn;
    advSrchConfig.forEach((configRow) => {
      const config = configRow.columns.find(column => column.property === property && column.operator === operator);
      if (config) {
        configColumn = config;
      }
    });

    return configColumn;
  }

  public getAdvSearchColumnByKey(key: string, advSrchConfig: Array<IntegraSearchConfigRow>): IntegraSearchConfigColumn {
    let configColumn: IntegraSearchConfigColumn;
    advSrchConfig?.forEach((configRow) => {
      const config = configRow.columns.find(column => column.key === key);
      if (config) {
        configColumn = config;
      }
    });

    return configColumn;
  }

  public initializeFilters(activatedRoute: ActivatedRoute, advSearchConfig: Array<IntegraSearchConfigRow>): boolean {
    let initializedFilter = false;
    activatedRoute.snapshot.queryParamMap.keys.forEach(key => {
      const column = this.getAdvSearchColumnByKey(key, advSearchConfig);
      if (column) {
        column.value = activatedRoute.snapshot.queryParamMap.get(key);

        initializedFilter = true;
      }
    });

    return initializedFilter;
  }
}
