const SERIES_COLORS = 30;
const seriesVar = '--kendo-chart-series-';
const elementStyles = element => element.ownerDocument.defaultView.getComputedStyle(element);
const cache = {};
const toColor = (colorMix, element) => {
  if (cache[colorMix]) {
    return cache[colorMix];
  }
  const curColor = element.style.color;
  element.style.color = colorMix;
  const color = elementStyles(element).color;
  element.style.color = curColor;
  cache[colorMix] = color;
  return color;
};
const getProp = (element, prop) => {
  let value = elementStyles(element).getPropertyValue(prop);
  if (/^color-mix/i.test(value)) {
    value = toColor(value, element);
  }
  return value;
};
const getNumberProp = (element, prop) => parseFloat(elementStyles(element).getPropertyValue(prop));
const getFont = (element, weightProp, sizeProp, familyProp) => {
  const styles = elementStyles(element);
  return [styles.getPropertyValue(weightProp), styles.getPropertyValue(sizeProp), styles.getPropertyValue(familyProp) || styles.fontFamily].join(" ");
};
const getSeriesColors = element => {
  const styles = elementStyles(element);
  const result = [];
  let count = 1;
  let color = styles.getPropertyValue(`${seriesVar}${count}`);
  while (color || count <= SERIES_COLORS) {
    result.push(color);
    count++;
    color = styles.getPropertyValue(`${seriesVar}${count}`);
  }
  return result;
};
const defaultFont = element => getFont(element, "--kendo-font-weight", '--kendo-chart-font-size', "--kendo-font-family");
const paneTitleFont = element => getFont(element, "--kendo-chart-pane-title-font-weight", '--kendo-chart-pane-title-font-size', "--kendo-font-family");
const normalTextColor = element => getProp(element, "--kendo-chart-text");
const title = element => ({
  color: normalTextColor(element),
  font: getFont(element, "--kendo-font-weight", '--kendo-chart-title-font-size', "--kendo-font-family")
});
const sankeyLegend = element => {
  const textColorNormal = normalTextColor(element);
  return {
    labels: {
      color: textColorNormal,
      font: defaultFont(element)
    },
    title: {
      color: textColorNormal
    }
  };
};
const chartLegend = element => {
  const inactiveColor = getProp(element, "--kendo-chart-inactive");
  return Object.assign({}, {
    inactiveItems: {
      labels: {
        color: inactiveColor
      },
      markers: {
        color: inactiveColor
      }
    }
  }, sankeyLegend(element));
};
export const gaugeTheme = element => {
  const textColorNormal = normalTextColor(element);
  return {
    pointer: {
      color: getProp(element, "--kendo-chart-gauge-pointer")
    },
    scale: {
      labels: {
        color: textColorNormal
      },
      rangePlaceholderColor: getProp(element, "--kendo-chart-gauge-track"),
      minorTicks: {
        color: textColorNormal
      },
      majorTicks: {
        color: textColorNormal
      },
      line: {
        color: textColorNormal
      }
    }
  };
};
export const sankeyTheme = element => ({
  labels: {
    color: normalTextColor(element),
    font: defaultFont(element),
    stroke: {
      color: getProp(element, "--kendo-chart-bg")
    }
  },
  links: {
    color: getProp(element, "--kendo-color-subtle")
  },
  nodeColors: getSeriesColors(element),
  title: title(element),
  legend: sankeyLegend(element)
});
const notes = element => ({
  icon: {
    background: getProp(element, "--kendo-chart-notes-bg"),
    border: {
      color: getProp(element, "--kendo-chart-notes-border")
    }
  },
  line: {
    color: getProp(element, "--kendo-chart-notes-lines")
  },
  label: {
    font: defaultFont(element)
  }
});
export const chartTheme = element => {
  const majorLines = getProp(element, "--kendo-chart-major-lines");
  const normalTextColor = getProp(element, "--kendo-chart-text");
  const axisLabelFont = getFont(element, "--kendo-font-weight", "--kendo-chart-label-font-size", "--kendo-font-family");
  const chartBg = getProp(element, "--kendo-chart-bg");
  const notesProps = notes(element);
  const areaOpacity = getNumberProp(element, "--kendo-chart-area-opacity");
  const surfaceColor = getProp(element, "--kendo-color-surface");
  const primaryBg = getProp(element, "--kendo-chart-primary-bg");
  const boxPlot = () => ({
    downColor: majorLines,
    mean: {
      color: surfaceColor
    },
    median: {
      color: surfaceColor
    },
    whiskers: {
      color: primaryBg
    }
  });
  const waterfall = () => ({
    line: {
      color: majorLines
    }
  });
  const area = () => ({
    opacity: areaOpacity,
    highlight: {
      inactiveOpacity: getNumberProp(element, "--kendo-chart-area-inactive-opacity")
    }
  });
  const line = () => ({
    highlight: {
      inactiveOpacity: getNumberProp(element, "--kendo-chart-line-inactive-opacity")
    }
  });
  const bullet = () => ({
    target: {
      color: normalTextColor
    }
  });
  return {
    axisDefaults: {
      crosshair: {
        color: getProp(element, "--kendo-chart-crosshair-bg")
      },
      labels: {
        color: normalTextColor,
        font: axisLabelFont
      },
      line: {
        color: majorLines
      },
      majorGridLines: {
        color: majorLines
      },
      minorGridLines: {
        color: getProp(element, "--kendo-chart-minor-lines")
      },
      notes: structuredClone(notesProps),
      title: {
        color: normalTextColor,
        font: defaultFont(element)
      }
    },
    chartArea: {
      background: chartBg
    },
    legend: chartLegend(element),
    seriesColors: getSeriesColors(element),
    seriesDefaults: {
      area: area(),
      verticalArea: area(),
      radarArea: area(),
      boxPlot: boxPlot(),
      verticalBoxPlot: boxPlot(),
      bullet: bullet(),
      verticalBullet: bullet(),
      horizontalWaterfall: waterfall(),
      waterfall: waterfall(),
      line: line(),
      verticalLine: line(),
      candlestick: {
        downColor: normalTextColor,
        line: {
          color: normalTextColor
        }
      },
      errorBars: {
        color: getProp(element, "--kendo-chart-error-bars-bg")
      },
      icon: {
        border: {
          color: majorLines
        }
      },
      labels: {
        background: chartBg,
        color: normalTextColor,
        font: axisLabelFont,
        opacity: areaOpacity
      },
      notes: structuredClone(notesProps)
    },
    subtitle: {
      color: normalTextColor,
      font: paneTitleFont(element)
    },
    title: title(element),
    paneDefaults: {
      title: {
        font: paneTitleFont(element)
      }
    }
  };
};