const listsTypes = {
  orderedList: 'ordered_list',
  bulletList: 'bullet_list',
  listItem: 'list_item'
};
/**
 * The object of the OrderedList tool settings.
 */
const orderedList = {
  listType: listsTypes.orderedList,
  types: Object.assign({}, listsTypes)
};
/**
 * The object of the UnorderedList tool settings.
 */
const bulletList = {
  listType: listsTypes.bulletList,
  types: Object.assign({}, listsTypes)
};
export { bulletList, listsTypes, orderedList };