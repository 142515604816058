/**-----------------------------------------------------------------------------------------
* Copyright © 2025 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Directive, Input, forwardRef, Component, ChangeDetectionStrategy, ViewChild, HostBinding, Optional, ElementRef, EventEmitter, inject, SkipSelf, ContentChildren, Output, HostListener, NgModule } from '@angular/core';
import * as i1 from '@progress/kendo-angular-l10n';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { Subject, Subscription } from 'rxjs';
import { caretAltLeftIcon, caretAltToLeftIcon, caretAltRightIcon, caretAltToRightIcon } from '@progress/kendo-svg-icons';
import { isFocusable, Keys, EventsOutsideAngularDirective, anyChanged, ResizeSensorComponent, isPresent, ResizeBatchService } from '@progress/kendo-angular-common';
import { ButtonComponent } from '@progress/kendo-angular-buttons';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { NgIf, NgFor, NgClass, NgTemplateOutlet } from '@angular/common';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { validatePackage } from '@progress/kendo-licensing';
import { IconsService } from '@progress/kendo-angular-icons';
import { PopupService } from '@progress/kendo-angular-popup';

/**
 * @hidden
 */
const _c0 = ["dropdownlist"];
const _c1 = ["select"];
const _c2 = ["numbers"];
const _c3 = a0 => ({
  "k-selected": a0
});
function PagerNumericButtonsComponent_option_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 9);
    i0.ɵɵtext(1, "... ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("selected", false);
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(ctx_r1.start - 1));
  }
}
function PagerNumericButtonsComponent_option_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const num_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", num_r3.toString())("selected", num_r3 === ctx_r1.currentPage)("ngClass", i0.ɵɵpureFunction1(6, _c3, ctx_r1.currentPage === num_r3));
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(num_r3))("aria-current", ctx_r1.currentPage === num_r3 ? "page" : undefined);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", num_r3, " ");
  }
}
function PagerNumericButtonsComponent_option_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 11);
    i0.ɵɵtext(1, "... ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("selected", false);
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(ctx_r1.end + 1));
  }
}
function PagerNumericButtonsComponent_button_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵlistener("click", function PagerNumericButtonsComponent_button_7_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.changePage(ctx_r1.start - 2));
    });
    i0.ɵɵtext(1, "...");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("size", ctx_r1.size);
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(ctx_r1.start - 1))("title", ctx_r1.pageLabel(ctx_r1.start - 1));
  }
}
function PagerNumericButtonsComponent_button_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵlistener("click", function PagerNumericButtonsComponent_button_8_Template_button_click_0_listener() {
      const num_r6 = i0.ɵɵrestoreView(_r5).$implicit;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.changePage(num_r6 - 1));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const num_r6 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("size", ctx_r1.size)("selected", ctx_r1.currentPage === num_r6);
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(num_r6))("title", ctx_r1.pageLabel(num_r6))("aria-current", ctx_r1.currentPage === num_r6 ? "page" : undefined);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", num_r6, " ");
  }
}
function PagerNumericButtonsComponent_button_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵlistener("click", function PagerNumericButtonsComponent_button_9_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.changePage(ctx_r1.end));
    });
    i0.ɵɵtext(1, "...");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("size", ctx_r1.size);
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(ctx_r1.end + 1))("title", ctx_r1.pageLabel(ctx_r1.end + 1));
  }
}
const _c4 = a0 => ({
  "aria-label": a0
});
const _c5 = (a0, a1) => ({
  keydown: a0,
  focusout: a1
});
function PagerComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.template.first == null ? null : ctx_r0.template.first.templateRef)("ngTemplateOutletContext", ctx_r0.templateContext);
  }
}
function PagerComponent_ng_template_3_kendo_pager_prev_buttons_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-pager-prev-buttons", 11);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.size);
  }
}
function PagerComponent_ng_template_3_kendo_pager_numeric_buttons_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-pager-numeric-buttons", 12);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.size)("buttonCount", ctx_r0.buttonCount);
  }
}
function PagerComponent_ng_template_3_kendo_pager_input_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-pager-input", 11);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.size);
  }
}
function PagerComponent_ng_template_3_kendo_pager_next_buttons_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-pager-next-buttons", 11);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.size);
  }
}
function PagerComponent_ng_template_3_kendo_pager_page_sizes_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-pager-page-sizes", 13);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.size)("pageSizes", ctx_r0._pageSizeValues);
  }
}
function PagerComponent_ng_template_3_kendo_pager_info_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-pager-info");
  }
}
function PagerComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, PagerComponent_ng_template_3_kendo_pager_prev_buttons_1_Template, 1, 1, "kendo-pager-prev-buttons", 7)(2, PagerComponent_ng_template_3_kendo_pager_numeric_buttons_2_Template, 1, 2, "kendo-pager-numeric-buttons", 8)(3, PagerComponent_ng_template_3_kendo_pager_input_3_Template, 1, 1, "kendo-pager-input", 7)(4, PagerComponent_ng_template_3_kendo_pager_next_buttons_4_Template, 1, 1, "kendo-pager-next-buttons", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, PagerComponent_ng_template_3_kendo_pager_page_sizes_5_Template, 1, 2, "kendo-pager-page-sizes", 9)(6, PagerComponent_ng_template_3_kendo_pager_info_6_Template, 1, 0, "kendo-pager-info", 10);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.previousNext);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.type === "numeric" && ctx_r0.buttonCount > 0);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.type === "input");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.previousNext);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0._pageSizeValues.length);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.info);
  }
}
function PagerComponent_kendo_resize_sensor_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "kendo-resize-sensor", 14);
    i0.ɵɵlistener("resize", function PagerComponent_kendo_resize_sensor_5_Template_kendo_resize_sensor_resize_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.resizeHandler());
    });
    i0.ɵɵelementEnd();
  }
}
class PreventableEvent {
  prevented = false;
  /**
   * Prevents the default action for a specified event.
   * In this way, the source component suppresses the built-in behavior that follows the event.
   */
  preventDefault() {
    this.prevented = true;
  }
  /**
   * If the event is prevented by any of its subscribers, returns `true`.
   *
   * @returns `true` if the default action was prevented. Otherwise, returns `false`.
   */
  isDefaultPrevented() {
    return this.prevented;
  }
}

/**
 * Arguments for the `pageSizeChange` event. The `pageSizeChange` event fires when the page size
 * is changed from the UI. If you cancel the event, the change is prevented.
 */
class PageSizeChangeEvent extends PreventableEvent {
  /**
   * The newly selected page size.
   */
  newPageSize;
  /**
   * Constructs the event arguments for the `pageSizeChange` event.
   * @param newPageSize - The newly selected page size.
   * @hidden
   */
  constructor(newPageSize) {
    super();
    this.newPageSize = newPageSize;
  }
}

/**
 * @hidden
 */
class Messages extends ComponentMessages {
  /**
   * The label of the pager. Follows the pattern **Page navigation, page {currentPage} of {totalPages}** by default.
   * Тhe default label text when the current page is 1, and the total number of pages is 10 will be
   * **Page navigation, page 1 of 10**.
   *
   * The message consists of several parts - the current page number, the total number of pages, and a localizable string.
   * To allow for reordering its parts, the `ariaLabel` input accepts a string with placeholders for the current page
   * and total number of pages. The `{currentPage}` and `{totalPages}` placeholders will be replaced
   * internally with the respective actual values.
   */
  ariaLabel;
  /**
   * The label for the **First page** button.
   */
  firstPage;
  /**
   * The label for the **Last page** button.
   */
  lastPage;
  /**
   * The label for the **Previous page** button.
   */
  previousPage;
  /**
   * The label for the **Next page** button.
   */
  nextPage;
  /**
   * The label displayed before the pager input.
   */
  page;
  /**
   * The title attribute of the page number input element.
   */
  pageNumberInputTitle;
  /**
   * The label displayed after the page-size selector.
   */
  itemsPerPage;
  /**
   * The label before the total-page number.
   */
  of;
  /**
   * The label after the total-page number.
   */
  items;
  /**
   * The text of the title and aria-label attributes applied to the page chooser.
   */
  selectPage;
  /**
   * The text of the aria-label attribute applied to the input element for entering the page number."
   */
  inputLabel;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵMessages_BaseFactory;
    return function Messages_Factory(__ngFactoryType__) {
      return (ɵMessages_BaseFactory || (ɵMessages_BaseFactory = i0.ɵɵgetInheritedFactory(Messages)))(__ngFactoryType__ || Messages);
    };
  })();
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: Messages,
    selectors: [["kendoPagerMessages"]],
    inputs: {
      ariaLabel: "ariaLabel",
      firstPage: "firstPage",
      lastPage: "lastPage",
      previousPage: "previousPage",
      nextPage: "nextPage",
      page: "page",
      pageNumberInputTitle: "pageNumberInputTitle",
      itemsPerPage: "itemsPerPage",
      of: "of",
      items: "items",
      selectPage: "selectPage",
      inputLabel: "inputLabel"
    },
    features: [i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Messages, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: 'kendoPagerMessages'
    }]
  }], null, {
    ariaLabel: [{
      type: Input
    }],
    firstPage: [{
      type: Input
    }],
    lastPage: [{
      type: Input
    }],
    previousPage: [{
      type: Input
    }],
    nextPage: [{
      type: Input
    }],
    page: [{
      type: Input
    }],
    pageNumberInputTitle: [{
      type: Input
    }],
    itemsPerPage: [{
      type: Input
    }],
    of: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    selectPage: [{
      type: Input
    }],
    inputLabel: [{
      type: Input
    }]
  });
})();

/**
 * Custom component messages override default component messages
 * ([see example]({% slug pager_globalization %}#toc-messages)).
 */
class CustomMessagesComponent extends Messages {
  service;
  constructor(service) {
    super();
    this.service = service;
  }
  get override() {
    return true;
  }
  static ɵfac = function CustomMessagesComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CustomMessagesComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: CustomMessagesComponent,
    selectors: [["kendo-datapager-messages"], ["kendo-pager-messages"]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([{
      provide: Messages,
      useExisting: forwardRef(() => CustomMessagesComponent)
    }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 0,
    vars: 0,
    template: function CustomMessagesComponent_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomMessagesComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: Messages,
        useExisting: forwardRef(() => CustomMessagesComponent)
      }],
      selector: 'kendo-datapager-messages, kendo-pager-messages',
      template: ``,
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, null);
})();

/**
 * @hidden
 */
class LocalizedMessagesDirective extends Messages {
  service;
  constructor(service) {
    super();
    this.service = service;
  }
  static ɵfac = function LocalizedMessagesDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LocalizedMessagesDirective)(i0.ɵɵdirectiveInject(i1.LocalizationService));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: LocalizedMessagesDirective,
    selectors: [["", "kendoPagerLocalizedMessages", ""]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([{
      provide: Messages,
      useExisting: forwardRef(() => LocalizedMessagesDirective)
    }]), i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizedMessagesDirective, [{
    type: Directive,
    args: [{
      providers: [{
        provide: Messages,
        useExisting: forwardRef(() => LocalizedMessagesDirective)
      }],
      selector: '[kendoPagerLocalizedMessages]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, null);
})();

/**
 * @hidden
 */
class PagerContextService {
  total;
  skip;
  pageSize;
  localization;
  changes = new Subject();
  pageChange = new Subject();
  pageSizeChange = new Subject();
  get currentPage() {
    return this.skip / this.pageSize;
  }
  notifyChanges(changes) {
    this.total = changes.total;
    this.pageSize = changes.pageSize;
    this.skip = changes.skip;
    this.changes.next(changes);
  }
  changePage(page) {
    this.pageChange.next({
      skip: page * this.pageSize,
      take: this.pageSize
    });
  }
  changePageSize(event) {
    this.pageSizeChange.next(event);
  }
  nextPage() {
    const nextPage = this.currentPage + 1;
    if (nextPage * this.pageSize < this.total) {
      this.changePage(nextPage);
    }
  }
  prevPage() {
    const prevPage = this.currentPage - 1;
    if (prevPage * this.pageSize >= 0) {
      this.changePage(prevPage);
    }
  }
}

/**
 * @hidden
 */
class PagerElementComponent {
  localization;
  pagerContext;
  cd;
  total;
  skip;
  pageSize;
  caretAltLeftIcon = caretAltLeftIcon;
  caretAltToLeftIcon = caretAltToLeftIcon;
  caretAltRightIcon = caretAltRightIcon;
  caretAltToRightIcon = caretAltToRightIcon;
  /**
   * @hidden
   *
   * @readonly
   * @type {number}
   * @memberOf PagerElementComponent
   */
  get currentPage() {
    return Math.floor((this.skip || 0) / this.pageSize) + 1;
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {number}
   * @memberOf PagerElementComponent
   */
  get totalPages() {
    return Math.ceil((this.total || 0) / this.pageSize);
  }
  subscriptions;
  constructor(localization, pagerContext, cd) {
    this.localization = localization;
    this.pagerContext = pagerContext;
    this.cd = cd;
    this.total = pagerContext.total;
    this.skip = pagerContext.skip;
    this.pageSize = pagerContext.pageSize;
  }
  /**
   * @hidden
   *
   * @param {string} key
   * @returns {string}
   *
   * @memberOf PagerElementComponent
   */
  textFor(key) {
    const isPagerLocalization = this.localization.prefix === 'kendo.pager';
    return this.localization.get(isPagerLocalization ? key : `pager${key[0].toLocaleUpperCase()}${key.slice(1)}`);
  }
  /**
   * @hidden
   *
   * @param {number} page
   *
   * @memberOf PagerElementComponent
   */
  changePage(page) {
    this.pagerContext.changePage(page);
    return false;
  }
  /**
   * @hidden
   *
   * @memberOf PagerElementComponent
   */
  ngOnInit() {
    this.subscriptions = this.pagerContext.changes.subscribe(this.onChanges.bind(this));
    this.subscriptions.add(this.localization.changes.subscribe(() => this.cd.markForCheck()));
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  get prevArrowIcons() {
    return !this.localization.rtl ? ['caret-alt-to-left', 'caret-alt-left'] : ['caret-alt-to-right', 'caret-alt-right'];
  }
  get prevArrowSVGIcons() {
    return !this.localization.rtl ? [this.caretAltToLeftIcon, this.caretAltLeftIcon] : [this.caretAltToRightIcon, this.caretAltRightIcon];
  }
  get nextArrowIcons() {
    return !this.localization.rtl ? ['caret-alt-right', 'caret-alt-to-right'] : ['caret-alt-left', 'caret-alt-to-left'];
  }
  get nextArrowSVGIcons() {
    return !this.localization.rtl ? [this.caretAltRightIcon, this.caretAltToRightIcon] : [this.caretAltLeftIcon, this.caretAltToLeftIcon];
  }
  static ɵfac = function PagerElementComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerElementComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PagerElementComponent,
    selectors: [["kendo-pager-element"]],
    decls: 0,
    vars: 0,
    template: function PagerElementComponent_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerElementComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-pager-element',
      template: ``
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: PagerContextService
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * @hidden
 */
const DEFAULT_PAGE_SIZE_VALUES = [5, 10, 20].map(n => ({
  text: String(n),
  value: n
}));
/**
 * @hidden
 */
const focusableDirectiveSelector = '[kendoPagerFocusable]';
/**
 * @hidden
 */
const getAllFocusableChildren = parent => {
  return parent.querySelectorAll(focusableDirectiveSelector);
};
/**
 * @hidden
 */
const focusableSelector = ['a[href]:not([disabled]):not([aria-hidden="true"])', 'area[href]:not([disabled]):not([aria-hidden="true"])', 'input:not([disabled]):not([aria-hidden="true"])', 'select:not([disabled]):not([aria-hidden="true"])', 'textarea:not([disabled]):not([aria-hidden="true"])', 'button:not([aria-hidden="true"])', 'iframe:not([disabled])', 'object:not([disabled])', 'embed:not([disabled])', '*[tabindex]:not([disabled]):not([aria-hidden="true"])', '*[contenteditable]:not([disabled]):not([contenteditable="false"])'].join(',');
/**
 * @hidden
 */
const DEFAULT_SIZE = 'medium';
const SIZES = {
  small: 'sm',
  medium: 'md',
  large: 'lg'
};
/**
 * @hidden
 *
 * Returns the styling classes to be added and removed
 */
const getStylingClasses = (componentType, stylingOption, previousValue, newValue) => {
  switch (stylingOption) {
    case 'size':
      return {
        toRemove: `k-${componentType}-${SIZES[previousValue]}`,
        toAdd: newValue !== 'none' ? `k-${componentType}-${SIZES[newValue]}` : ''
      };
    default:
      break;
  }
};
/**
 * @hidden
 */
const replaceMessagePlaceholder = (message, name, value) => message.replace(new RegExp(`\{\\s*${name}\\s*\}`, 'g'), value);

/**
 * @hidden
 */
class PagerNavigationService {
  isNavigable = false;
  innerNavigationChange = new Subject();
  toggleInnerNavigation(value) {
    this.innerNavigationChange.next(value);
  }
  keepFocusWithinComponent(wrapper, target, event) {
    const [firstFocusable, lastFocusable] = this.getFirstAndLastFocusable(wrapper);
    const tabAfterLastFocusable = !event.shiftKey && target === lastFocusable;
    const shiftTabAfterFirstFocusable = event.shiftKey && target === firstFocusable;
    if (tabAfterLastFocusable) {
      event.preventDefault();
      firstFocusable.focus();
    }
    if (shiftTabAfterFirstFocusable) {
      event.preventDefault();
      lastFocusable.focus();
    }
  }
  getFirstAndLastFocusable(wrapper) {
    const all = getAllFocusableChildren(wrapper);
    const firstFocusable = all.length > 0 ? all[0] : wrapper;
    const lastFocusable = all.length > 0 ? all[all.length - 1] : wrapper;
    return [isFocusable(firstFocusable) ? firstFocusable : firstFocusable.querySelector(focusableSelector), isFocusable(lastFocusable) ? lastFocusable : lastFocusable.querySelector(focusableSelector)];
  }
}

/**
 * Place the directive on custom focusable elements in the [`kendoPagerTemplate`]({% slug api_pager_pagertemplatedirective %}) to include them in the built-in Pager keyboard navigation.
 */
class PagerFocusableDirective {
  navigationService;
  element;
  renderer;
  subscriptions = new Subscription();
  constructor(navigationService, element, renderer) {
    this.navigationService = navigationService;
    this.element = element;
    this.renderer = renderer;
  }
  ngOnInit() {
    if (!(this.nativeElement instanceof HTMLElement)) {
      return;
    }
    this.subscriptions.add(this.navigationService.innerNavigationChange.subscribe(this.innerNavigationChange.bind(this)));
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  get nativeElement() {
    return this.element.nativeElement;
  }
  innerNavigationChange(value) {
    if (!this.navigationService.isNavigable) {
      return;
    }
    const index = value ? '0' : '-1';
    if (this.nativeElement.matches(focusableSelector)) {
      this.renderer.setAttribute(this.nativeElement, 'tabindex', index);
    }
    const focusableElements = this.nativeElement.querySelectorAll(focusableSelector);
    focusableElements.forEach(el => {
      this.renderer.setAttribute(el, 'tabindex', index);
    });
  }
  static ɵfac = function PagerFocusableDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerFocusableDirective)(i0.ɵɵdirectiveInject(PagerNavigationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: PagerFocusableDirective,
    selectors: [["", "kendoPagerFocusable", ""]],
    exportAs: ["kendoPagerFocusable"],
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerFocusableDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoPagerFocusable]',
      exportAs: 'kendoPagerFocusable',
      standalone: true
    }]
  }], function () {
    return [{
      type: PagerNavigationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();

// eslint-disable no-access-missing-member
/**
 * Displays buttons for navigating to the first and to the previous page ([see example]({% slug pager_settings %})).
 */
class PagerPrevButtonsComponent extends PagerElementComponent {
  constructor(localization, pagerContext, cd) {
    super(localization, pagerContext, cd);
  }
  /**
   * Specifies the padding of the buttons.
   *
   * The possible values are:
   * * `small`
   * * `medium` (default)
   * * `large`
   * * `none`
   */
  size = DEFAULT_SIZE;
  /**
   * @hidden
   *
   * @readonly
   * @type {boolean}
   * @memberOf PagerPrevButtonsComponent
   */
  get disabled() {
    return this.currentPage === 1 || !this.total;
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = pageSize;
    this.cd.markForCheck();
  }
  static ɵfac = function PagerPrevButtonsComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerPrevButtonsComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PagerPrevButtonsComponent,
    selectors: [["kendo-datapager-prev-buttons"], ["kendo-pager-prev-buttons"]],
    inputs: {
      size: "size"
    },
    standalone: true,
    features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 2,
    vars: 16,
    consts: [["type", "button", "kendoButton", "", "kendoPagerFocusable", "", "fillMode", "flat", "rounded", "none", 1, "k-pager-nav", "k-pager-first", 3, "click", "icon", "svgIcon", "size", "title"], ["type", "button", "kendoButton", "", "kendoPagerFocusable", "", "fillMode", "flat", "rounded", "none", 1, "k-pager-nav", 3, "click", "icon", "svgIcon", "size", "title"]],
    template: function PagerPrevButtonsComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "button", 0);
        i0.ɵɵlistener("click", function PagerPrevButtonsComponent_Template_button_click_0_listener() {
          return ctx.currentPage !== 1 ? ctx.changePage(0) : false;
        });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(1, "button", 1);
        i0.ɵɵlistener("click", function PagerPrevButtonsComponent_Template_button_click_1_listener() {
          return ctx.currentPage !== 1 ? ctx.changePage(ctx.currentPage - 2) : false;
        });
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassProp("k-disabled", ctx.disabled);
        i0.ɵɵproperty("icon", ctx.prevArrowIcons[0])("svgIcon", ctx.prevArrowSVGIcons[0])("size", ctx.size)("title", ctx.textFor("firstPage"));
        i0.ɵɵattribute("aria-disabled", ctx.disabled)("aria-label", ctx.textFor("firstPage"));
        i0.ɵɵadvance();
        i0.ɵɵclassProp("k-disabled", ctx.disabled);
        i0.ɵɵproperty("icon", ctx.prevArrowIcons[1])("svgIcon", ctx.prevArrowSVGIcons[1])("size", ctx.size)("title", ctx.textFor("previousPage"));
        i0.ɵɵattribute("aria-disabled", ctx.disabled)("aria-label", ctx.textFor("previousPage"));
      }
    },
    dependencies: [ButtonComponent, PagerFocusableDirective],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerPrevButtonsComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-datapager-prev-buttons, kendo-pager-prev-buttons',
      template: `
        <button
            type="button" kendoButton
            kendoPagerFocusable
            class="k-pager-nav k-pager-first"
            [attr.aria-disabled]="disabled"
            [class.k-disabled]="disabled"
            [icon]="prevArrowIcons[0]"
            [svgIcon]="prevArrowSVGIcons[0]"
            fillMode="flat"
            rounded="none"
            [size]="size"
            [title]="textFor('firstPage')"
            [attr.aria-label]="textFor('firstPage')"
            (click)="currentPage !== 1 ? changePage(0) : false">
        </button>
        <button
            type="button" kendoButton
            kendoPagerFocusable
            class="k-pager-nav"
            [attr.aria-disabled]="disabled"
            [class.k-disabled]="disabled"
            [icon]="prevArrowIcons[1]"
            [svgIcon]="prevArrowSVGIcons[1]"
            fillMode="flat"
            rounded="none"
            [size]="size"
            [title]="textFor('previousPage')"
            [attr.aria-label]="textFor('previousPage')"
            (click)="currentPage !== 1 ? changePage(currentPage-2) : false">
        </button>
    `,
      standalone: true,
      imports: [ButtonComponent, PagerFocusableDirective]
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: PagerContextService
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    size: [{
      type: Input
    }]
  });
})();

/**
 * Displays a drop-down list for the page size selection ([see example]({% slug pager_settings %})).
 */
class PagerPageSizesComponent extends PagerElementComponent {
  pagerContext;
  element;
  ngZone;
  dropDownList;
  /**
   * The page sizes collection. Can contain numbers and [PageSizeItem]({% slug api_pager_pagesizeitem %}) objects.
   *
   * @example
   *
   * ```ts-preview
   * _@Component({
   *    selector: 'my-app',
   *    template: `
   *        <div *ngFor="let item of pagedData" style="border: 1px solid black; padding: 10px;">
   *            <span>{{item.ProductID}}. </span>
   *            <span>{{item.ProductName}}</span>
   *        </div>
   *        <kendo-pager
   *             [skip]="skip"
   *             [pageSize]="pageSize"
   *             [total]="total"
   *             (pageChange)="onPageChange($event)">
   *           <ng-template kendoPagerTemplate>
   *               <kendo-pager-page-sizes [pageSizes]="pagesizes"></kendo-pager-page-sizes>
   *           </ng-template>
   *        </kendo-pager>
   *    `
   * })
   * class AppComponent {
   *    public data: any[] = products;
   *    public pagedData = [];
   *    public skip = 0;
   *    public pageSize = 2;
   *    public total = products.length;
   *    public pagesizes = [{text: 'One', value: 1}, {text: 'Two', value: 2}, {text: 'All', value : 'all'}];
   *
   *    public ngOnInit() {
   *        this.pageData();
   *    }
   *
   *    public onPageChange(e) {
   *        this.skip = e.skip;
   *        this.pageSize = e.take;
   *        this.pageData();
   *    }
   *
   *    private pageData() {
   *        this.pagedData = this.data.slice(this.skip, this.skip + this.pageSize);
   *    }
   * }
   *
   * const products = [{
   *   'ProductID' : 1,
   *   'ProductName' : "Chai",
   *   'SupplierID' : 1,
   *   'CategoryID' : 1,
   *   'QuantityPerUnit' : "10 boxes x 20 bags",
   *   'UnitPrice' : 18.0000,
   *   'UnitsInStock' : 39,
   *   'UnitsOnOrder' : 0,
   *   'ReorderLevel' : 10,
   *   'Discontinued' : false
   *
   * }, {
   *   'ProductID' : 2,
   *   'ProductName' : "Chang",
   *   'SupplierID' : 1,
   *   'CategoryID' : 1,
   *   'QuantityPerUnit' : "24 - 12 oz bottles",
   *   'UnitPrice' : 19.0000,
   *   'UnitsInStock' : 17,
   *   'UnitsOnOrder' : 40,
   *   'ReorderLevel' : 25,
   *   'Discontinued' : false
   * }, {
   *   'ProductID' : 3,
   *   'ProductName' : "Aniseed Syrup",
   *   'SupplierID' : 1,
   *   'CategoryID' : 2,
   *   'QuantityPerUnit' : "12 - 550 ml bottles",
   *   'UnitPrice' : 10.0000,
   *   'UnitsInStock' : 13,
   *   'UnitsOnOrder' : 70,
   *   'ReorderLevel' : 25,
   *   'Discontinued' : false
   * }, {
   *   'ProductID' : 4,
   *   'ProductName' : "Chef Anton\'s Cajun Seasoning",
   *   'SupplierID' : 2,
   *  'CategoryID' : 2,
   *   'QuantityPerUnit' : "48 - 6 oz jars",
   *   'UnitPrice' : 22.0000,
   *   'UnitsInStock' : 53,
   *   'UnitsOnOrder' : 0,
   *   'ReorderLevel' : 0,
   *   'Discontinued' : false
   * }, {
   *   'ProductID' : 5,
   *   'ProductName' : "Chef Anton\'s Gumbo Mix",
   *   'SupplierID' : 2,
   *   'CategoryID' : 2,
   *   'QuantityPerUnit' : "36 boxes",
   *   'UnitPrice' : 21.3500,
   *   'UnitsInStock' : 0,
   *   'UnitsOnOrder' : 0,
   *   'ReorderLevel' : 0,
   *   'Discontinued' : true
   * }, {
   *   'ProductID' : 6,
   *   'ProductName' : "Grandma\'s Boysenberry Spread",
   *   'SupplierID' : 3,
   *   'CategoryID' : 2,
   *   'QuantityPerUnit' : "12 - 8 oz jars",
   *   'UnitPrice' : 25.0000,
   *   'UnitsInStock' : 120,
   *   'UnitsOnOrder' : 0,
   *   'ReorderLevel' : 25,
   *   'Discontinued' : false
   * }];
   * ```
   *
   */
  set pageSizes(pageSizes) {
    let normalizedItems = [];
    if (Array.isArray(pageSizes)) {
      pageSizes.forEach(item => {
        if (typeof item === 'number') {
          normalizedItems.push({
            text: item.toString(),
            value: item
          });
        } else {
          normalizedItems.push(item);
        }
      });
    } else {
      normalizedItems = DEFAULT_PAGE_SIZE_VALUES;
    }
    if (this.pageSize && !normalizedItems.some(item => item.value === this.pageSize)) {
      normalizedItems = [{
        text: this.pageSize.toString(),
        value: this.pageSize
      }, ...normalizedItems];
    }
    this._pageSizes = normalizedItems;
  }
  /**
   * Specifies the padding of the dropdown.
   *
   * The possible values are:
   * * `small`
   * * `medium` (default)
   * * `large`
   * * `none`
   */
  size = DEFAULT_SIZE;
  /**
   * @hidden
   *
   * @readonly
   */
  get classes() {
    return true;
  }
  _pageSizes = [];
  constructor(localization, cd, pagerContext, element, ngZone) {
    super(localization, pagerContext, cd);
    this.pagerContext = pagerContext;
    this.element = element;
    this.ngZone = ngZone;
  }
  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.element.nativeElement.addEventListener('keydown', this.keyDownHandler.bind(this), true);
    });
  }
  ngOnDestroy() {
    this.element.nativeElement.removeEventListener('keydown', this.keyDownHandler);
  }
  /**
   * @hidden
   */
  pageSizeChange(value, dropdownlist) {
    const event = new PageSizeChangeEvent(value);
    this.pagerContext.changePageSize(event);
    if (event.isDefaultPrevented()) {
      dropdownlist.writeValue(this.pageSize);
    }
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = typeof pageSize === 'number' ? pageSize : this.total;
    this.cd.markForCheck();
  }
  keyDownHandler(ev) {
    if (ev.keyCode === Keys.Escape && this.dropDownList.isOpen) {
      ev.stopPropagation();
      this.dropDownList.toggle(false);
    }
  }
  static ɵfac = function PagerPageSizesComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerPageSizesComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PagerPageSizesComponent,
    selectors: [["kendo-datapager-page-sizes"], ["kendo-pager-page-sizes"]],
    viewQuery: function PagerPageSizesComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropDownList = _t.first);
      }
    },
    hostVars: 2,
    hostBindings: function PagerPageSizesComponent_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("k-pager-sizes", ctx.classes);
      }
    },
    inputs: {
      pageSizes: "pageSizes",
      size: "size"
    },
    standalone: true,
    features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 3,
    vars: 6,
    consts: [["dropdownlist", ""], ["kendoPagerFocusable", "", "textField", "text", "valueField", "value", 3, "valueChange", "size", "data", "valuePrimitive", "value"]],
    template: function PagerPageSizesComponent_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "kendo-dropdownlist", 1, 0);
        i0.ɵɵlistener("valueChange", function PagerPageSizesComponent_Template_kendo_dropdownlist_valueChange_0_listener($event) {
          i0.ɵɵrestoreView(_r1);
          const dropdownlist_r2 = i0.ɵɵreference(1);
          return i0.ɵɵresetView(ctx.pageSizeChange($event, dropdownlist_r2));
        });
        i0.ɵɵelementEnd();
        i0.ɵɵtext(2);
      }
      if (rf & 2) {
        i0.ɵɵproperty("size", ctx.size)("data", ctx._pageSizes)("valuePrimitive", true)("value", ctx.pageSize);
        i0.ɵɵattribute("aria-label", ctx.textFor("itemsPerPage"));
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1(" ", ctx.textFor("itemsPerPage"), " ");
      }
    },
    dependencies: [DropDownListComponent, PagerFocusableDirective],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerPageSizesComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-datapager-page-sizes, kendo-pager-page-sizes',
      template: `
        <kendo-dropdownlist kendoPagerFocusable
            #dropdownlist
            [size]="size"
            [data]="_pageSizes"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [value]="pageSize"
            (valueChange)="pageSizeChange($event, dropdownlist)"
            [attr.aria-label]="textFor('itemsPerPage')"></kendo-dropdownlist>
        {{ textFor('itemsPerPage') }}
    `,
      standalone: true,
      imports: [DropDownListComponent, PagerFocusableDirective]
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: PagerContextService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    dropDownList: [{
      type: ViewChild,
      args: ['dropdownlist', {
        static: true
      }]
    }],
    pageSizes: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    classes: [{
      type: HostBinding,
      args: ["class.k-pager-sizes"]
    }]
  });
})();

/**
 * Represents the pager template which helps to customize the pager appearance. To define a pager
 * template, nest an `<ng-template>` tag with the `kendoPagerTemplate` directive inside `<kendo-pager>`.
 *
 * The template context provides the following fields:
 * * `currentPage`&mdash;The index of the displayed page.
 * * `pageSize`&mdash;The value of the current `pageSize`.
 * * `skip`&mdash;The current skip value.
 * * `total`&mdash;The total number of records.
 * * `totalPages`&mdash;The total number of available pages.
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *       <kendo-pager [total]="total" [pageSize]="pageSize" [skip]="skip" (pageChange)="onPageChange($event)">
 *           <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
 *               <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
 *               <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
 *               <kendo-pager-next-buttons></kendo-pager-next-buttons>
 *               <kendo-pager-info></kendo-pager-info>
 *               Current page: {{currentPage}}
 *           </ng-template>
 *        </kendo-pager>
 *     `
 * })
 *
 * class AppComponent {
 *     public skip = 0;
 *     public pageSize = 10;
 *     public total = 100;
 *
 *     public onPageChange(e: any): void {
 *         this.skip = e.skip;
 *         this.pageSize = e.take;
 *     }
 * }
 *
 * ```
 */
class PagerTemplateDirective {
  templateRef;
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static ɵfac = function PagerTemplateDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: PagerTemplateDirective,
    selectors: [["", "kendoDataPagerTemplate", ""], ["", "kendoPagerTemplate", ""]],
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoDataPagerTemplate], [kendoPagerTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Displays numeric buttons to enable navigation between the pages.
 */
class PagerNumericButtonsComponent extends PagerElementComponent {
  pagerContext;
  renderer;
  selectElement;
  numbersElement;
  /**
   * The count of the displayed buttons.
   *
   * @type {number}
   * @memberOf PagerNumericButtonsComponent
   */
  buttonCount;
  /**
   * Specifies the padding of the numeric buttons.
   *
   * The possible values are:
   * * `small`
   * * `medium` (default)
   * * `large`
   * * `none`
   */
  set size(size) {
    const newSize = size ? size : DEFAULT_SIZE;
    this.handleClasses(newSize, 'size');
    this._size = newSize;
  }
  get size() {
    return this._size;
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {number[]}
   * @memberOf PagerNumericButtonsComponent
   */
  get buttons() {
    const result = [];
    for (let idx = this.start; idx <= this.end; idx++) {
      result.push(idx);
    }
    return result;
  }
  /**
   * @hidden
   */
  get end() {
    return Math.min(this.start + this.buttonCount - 1, this.totalPages);
  }
  /**
   * @hidden
   */
  get start() {
    const page = this.currentPage;
    const buttonCount = this.buttonCount;
    if (page > buttonCount) {
      const reminder = page % buttonCount;
      return reminder === 0 ? page - buttonCount + 1 : page - reminder + 1;
    }
    return 1;
  }
  constructor(localization, cd, pagerContext, renderer) {
    super(localization, pagerContext, cd);
    this.pagerContext = pagerContext;
    this.renderer = renderer;
  }
  _size = DEFAULT_SIZE;
  ngAfterViewInit() {
    this.handleClasses(this.size, 'size');
  }
  /**
   * @hidden
   */
  pageLabel(num) {
    const pageText = this.textFor('page');
    if (pageText) {
      return pageText + ' ' + num;
    }
    return num.toString();
  }
  /**
   * @hidden
   */
  onSelectChange(e) {
    const target = e.target;
    const valueAsNumber = Number(target.value);
    if (!Number.isNaN(valueAsNumber)) {
      this.changePage(valueAsNumber - 1);
    } else {
      if (target.value === 'previousButtons') {
        this.changePage(this.start - 2);
      } else {
        this.changePage(this.end);
      }
    }
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = pageSize;
    this.cd.markForCheck();
  }
  get pageChooserLabel() {
    return this.textFor('selectPage');
  }
  handleClasses(value, input) {
    const elem = this.selectElement?.nativeElement;
    const classes = getStylingClasses('picker', input, this[input], value);
    if (!elem) {
      return;
    }
    if (classes.toRemove) {
      this.renderer.removeClass(elem, classes.toRemove);
    }
    if (classes.toAdd) {
      this.renderer.addClass(elem, classes.toAdd);
    }
  }
  static ɵfac = function PagerNumericButtonsComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerNumericButtonsComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.Renderer2));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PagerNumericButtonsComponent,
    selectors: [["kendo-datapager-numeric-buttons"], ["kendo-pager-numeric-buttons"]],
    viewQuery: function PagerNumericButtonsComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c1, 5, ElementRef);
        i0.ɵɵviewQuery(_c2, 5, ElementRef);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.selectElement = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.numbersElement = _t.first);
      }
    },
    inputs: {
      buttonCount: "buttonCount",
      size: "size"
    },
    standalone: true,
    features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 10,
    vars: 8,
    consts: [["select", ""], ["numbers", ""], ["kendoPagerFocusable", "", 1, "k-hidden", "k-dropdown-list", "k-dropdown", "k-picker", "k-picker-solid", "k-rounded-md", 3, "change"], ["value", "previousButtons", 3, "selected", 4, "ngIf"], [3, "value", "selected", "ngClass", 4, "ngFor", "ngForOf"], ["value", "nextButtons", 3, "selected", 4, "ngIf"], [1, "k-pager-numbers"], ["type", "button", "kendoPagerFocusable", "", "kendoButton", "", "fillMode", "flat", "themeColor", "primary", "rounded", "none", 3, "size", "click", 4, "ngIf"], ["type", "button", "kendoPagerFocusable", "", "kendoButton", "", "fillMode", "flat", "themeColor", "primary", "rounded", "none", 3, "size", "selected", "click", 4, "ngFor", "ngForOf"], ["value", "previousButtons", 3, "selected"], [3, "value", "selected", "ngClass"], ["value", "nextButtons", 3, "selected"], ["type", "button", "kendoPagerFocusable", "", "kendoButton", "", "fillMode", "flat", "themeColor", "primary", "rounded", "none", 3, "click", "size"], ["type", "button", "kendoPagerFocusable", "", "kendoButton", "", "fillMode", "flat", "themeColor", "primary", "rounded", "none", 3, "click", "size", "selected"]],
    template: function PagerNumericButtonsComponent_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "select", 2, 0);
        i0.ɵɵlistener("change", function PagerNumericButtonsComponent_Template_select_change_0_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onSelectChange($event));
        });
        i0.ɵɵtemplate(2, PagerNumericButtonsComponent_option_2_Template, 2, 2, "option", 3)(3, PagerNumericButtonsComponent_option_3_Template, 2, 8, "option", 4)(4, PagerNumericButtonsComponent_option_4_Template, 2, 2, "option", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div", 6, 1);
        i0.ɵɵtemplate(7, PagerNumericButtonsComponent_button_7_Template, 2, 3, "button", 7)(8, PagerNumericButtonsComponent_button_8_Template, 2, 6, "button", 8)(9, PagerNumericButtonsComponent_button_9_Template, 2, 3, "button", 7);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵattribute("title", ctx.pageChooserLabel)("aria-label", ctx.pageChooserLabel);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.start > 1);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngForOf", ctx.buttons);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.end < ctx.totalPages);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.start > 1);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngForOf", ctx.buttons);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.end < ctx.totalPages);
      }
    },
    dependencies: [PagerFocusableDirective, NgIf, NgFor, NgClass, ButtonComponent],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerNumericButtonsComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-datapager-numeric-buttons, kendo-pager-numeric-buttons',
      template: `
        <select kendoPagerFocusable #select
            class="k-hidden k-dropdown-list k-dropdown k-picker k-picker-solid k-rounded-md"
            [attr.title]="pageChooserLabel"
            [attr.aria-label]="pageChooserLabel"
            (change)="onSelectChange($event)">
            <option *ngIf="start > 1"
                value="previousButtons"
                [selected]="false"
                [attr.aria-label]="pageLabel(start - 1)">...
            </option>
            <option *ngFor="let num of buttons"
                [value]="num.toString()"
                [selected]="num === currentPage"
                [attr.aria-label]="pageLabel(num)"
                [attr.aria-current]="currentPage === num ? 'page' : undefined"
                [ngClass]="{'k-selected':currentPage === num}">
                {{num}}
            </option>
            <option *ngIf="end < totalPages"
                value="nextButtons"
                [selected]="false"
                [attr.aria-label]="pageLabel(end + 1)">...
            </option>
        </select>
        <div class="k-pager-numbers" #numbers>
            <button *ngIf="start > 1"
                type="button"
                kendoPagerFocusable
                kendoButton
                [size]="size"
                fillMode="flat"
                themeColor="primary"
                rounded="none"
                [attr.aria-label]="pageLabel(start - 1)"
                [attr.title]="pageLabel(start - 1)"
                (click)="changePage(start - 2)">...</button>
            <button *ngFor="let num of buttons"
                type="button"
                kendoPagerFocusable
                kendoButton
                [size]="size"
                fillMode="flat"
                themeColor="primary"
                rounded="none"
                [attr.aria-label]="pageLabel(num)"
                [attr.title]="pageLabel(num)"
                [attr.aria-current]="currentPage === num ? 'page' : undefined"
                [selected]="currentPage === num"
                (click)="changePage(num - 1)">
                {{num}}
            </button>
            <button *ngIf="end < totalPages"
                type="button"
                kendoPagerFocusable
                kendoButton
                [size]="size"
                fillMode="flat"
                themeColor="primary"
                rounded="none"
                [attr.aria-label]="pageLabel(end + 1)"
                [attr.title]="pageLabel(end + 1)"
                (click)="changePage(end)">...</button>
        </div>
    `,
      standalone: true,
      imports: [PagerFocusableDirective, NgIf, NgFor, NgClass, ButtonComponent]
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: PagerContextService
    }, {
      type: i0.Renderer2
    }];
  }, {
    selectElement: [{
      type: ViewChild,
      args: ['select', {
        read: ElementRef
      }]
    }],
    numbersElement: [{
      type: ViewChild,
      args: ['numbers', {
        read: ElementRef
      }]
    }],
    buttonCount: [{
      type: Input
    }],
    size: [{
      type: Input
    }]
  });
})();

// eslint-disable  no-access-missing-member
/**
 * Displays buttons for navigating to the next and to the last page ([see example]({% slug pager_settings %})).
 */
class PagerNextButtonsComponent extends PagerElementComponent {
  /**
   * @hidden
   *
   * @readonly
   * @type {boolean}
   * @memberOf PagerNextButtonsComponent
   */
  get disabled() {
    return this.currentPage === this.totalPages || !this.total;
  }
  /**
   * Specifies the padding of the buttons.
   *
   * The possible values are:
   * * `small`
   * * `medium` (default)
   * * `large`
   * * `none`
   */
  size = DEFAULT_SIZE;
  constructor(localization, pagerContext, cd) {
    super(localization, pagerContext, cd);
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = pageSize;
    this.cd.markForCheck();
  }
  static ɵfac = function PagerNextButtonsComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerNextButtonsComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PagerNextButtonsComponent,
    selectors: [["kendo-datapager-next-buttons"], ["kendo-pager-next-buttons"]],
    inputs: {
      size: "size"
    },
    standalone: true,
    features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 2,
    vars: 16,
    consts: [["kendoButton", "", "kendoPagerFocusable", "", "type", "button", "fillMode", "flat", "rounded", "none", 1, "k-pager-nav", 3, "click", "size", "icon", "svgIcon", "title"], ["kendoButton", "", "kendoPagerFocusable", "", "type", "button", "fillMode", "flat", "rounded", "none", 1, "k-pager-nav", "k-pager-last", 3, "click", "size", "icon", "svgIcon", "title"]],
    template: function PagerNextButtonsComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "button", 0);
        i0.ɵɵlistener("click", function PagerNextButtonsComponent_Template_button_click_0_listener() {
          return ctx.currentPage !== ctx.totalPages ? ctx.changePage(ctx.currentPage) : false;
        });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(1, "button", 1);
        i0.ɵɵlistener("click", function PagerNextButtonsComponent_Template_button_click_1_listener() {
          return ctx.currentPage !== ctx.totalPages ? ctx.changePage(ctx.totalPages - 1) : false;
        });
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassProp("k-disabled", ctx.disabled);
        i0.ɵɵproperty("size", ctx.size)("icon", ctx.nextArrowIcons[0])("svgIcon", ctx.nextArrowSVGIcons[0])("title", ctx.textFor("nextPage"));
        i0.ɵɵattribute("aria-disabled", ctx.disabled)("aria-label", ctx.textFor("nextPage"));
        i0.ɵɵadvance();
        i0.ɵɵclassProp("k-disabled", ctx.disabled);
        i0.ɵɵproperty("size", ctx.size)("icon", ctx.nextArrowIcons[1])("svgIcon", ctx.nextArrowSVGIcons[1])("title", ctx.textFor("lastPage"));
        i0.ɵɵattribute("aria-disabled", ctx.disabled)("aria-label", ctx.textFor("lastPage"));
      }
    },
    dependencies: [ButtonComponent, PagerFocusableDirective],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerNextButtonsComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-datapager-next-buttons, kendo-pager-next-buttons',
      template: `
        <button kendoButton kendoPagerFocusable
            type="button"
            [size]="size"
            [icon]="nextArrowIcons[0]"
            [svgIcon]="nextArrowSVGIcons[0]"
            fillMode="flat"
            rounded="none"
            class="k-pager-nav"
            [attr.aria-disabled]="disabled"
            [class.k-disabled]="disabled"
            [title]="textFor('nextPage')"
            [attr.aria-label]="textFor('nextPage')"
            (click)="currentPage !== totalPages ? changePage(currentPage) : false">
        </button>
        <button kendoButton kendoPagerFocusable
            type="button"
            [size]="size"
            [icon]="nextArrowIcons[1]"
            [svgIcon]="nextArrowSVGIcons[1]"
            fillMode="flat"
            rounded="none"
            class="k-pager-nav k-pager-last"
            [attr.aria-disabled]="disabled"
            [class.k-disabled]="disabled"
            [title]="textFor('lastPage')"
            [attr.aria-label]="textFor('lastPage')"
            (click)="currentPage !== totalPages ? changePage(totalPages-1) : false">
        </button>
    `,
      standalone: true,
      imports: [ButtonComponent, PagerFocusableDirective]
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: PagerContextService
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    size: [{
      type: Input
    }]
  });
})();

/**
 * Displays an input element which allows the typing and rendering of page numbers.
 */
class PagerInputComponent extends PagerElementComponent {
  pagerContext;
  zone;
  renderer;
  numericInput;
  /**
   * Specifies the padding of the internal numeric input element.
   *
   * The possible values are:
   * * `small`
   * * `medium` (default)
   * * `large`
   * * `none`
   */
  size = DEFAULT_SIZE;
  constructor(localization, pagerContext, zone, cd, renderer) {
    super(localization, pagerContext, cd);
    this.pagerContext = pagerContext;
    this.zone = zone;
    this.renderer = renderer;
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = pageSize;
    this.cd.markForCheck();
  }
  /**
   * @hidden
   *
   * @param {string} value
   *
   * @memberOf PagerInputComponent
   */
  handleKeyDown = event => {
    const incomingValue = this.numericInput.value || this.current;
    // eslint-disable import/no-deprecated
    if (event.keyCode === Keys.Enter) {
      event.preventDefault();
      if (incomingValue !== this.current) {
        this.zone.run(() => {
          this.changePage(incomingValue - 1);
        });
      }
    }
  };
  /**
   * @hidden
   *
   * @param {string} value
   *
   * @memberOf PagerInputComponent
   */
  handleBlur = () => {
    const inputValue = this.numericInput.value;
    if (!inputValue) {
      this.numericInput.writeValue(this.current);
      return;
    }
    if (inputValue !== this.current) {
      this.zone.run(() => {
        this.changePage(inputValue - 1);
      });
    }
  };
  /**
   * @hidden
   */
  get current() {
    return this.hasPages ? this.currentPage : 0;
  }
  /**
   * @hidden
   */
  get hasPages() {
    return this.totalPages !== 0;
  }
  static ɵfac = function PagerInputComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerInputComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.Renderer2));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PagerInputComponent,
    selectors: [["kendo-datapager-input"], ["kendo-pager-input"]],
    viewQuery: function PagerInputComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(NumericTextBoxComponent, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.numericInput = _t.first);
      }
    },
    inputs: {
      size: "size"
    },
    standalone: true,
    features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 4,
    vars: 19,
    consts: [[1, "k-pager-input"], ["kendoPagerFocusable", "", "format", "n0", 3, "spinners", "decimals", "size", "disabled", "value", "min", "max", "autoCorrect", "inputAttributes", "title", "kendoEventsOutsideAngular"]],
    template: function PagerInputComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "span", 0);
        i0.ɵɵtext(1);
        i0.ɵɵelement(2, "kendo-numerictextbox", 1);
        i0.ɵɵtext(3);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵtextInterpolate1(" ", ctx.textFor("page"), " ");
        i0.ɵɵadvance();
        i0.ɵɵproperty("spinners", false)("decimals", 0)("size", ctx.size)("disabled", !ctx.hasPages)("value", ctx.current)("min", ctx.hasPages ? 1 : 0)("max", ctx.totalPages)("autoCorrect", true)("inputAttributes", i0.ɵɵpureFunction1(14, _c4, ctx.textFor("inputLabel")))("title", ctx.textFor("pageNumberInputTitle"))("kendoEventsOutsideAngular", i0.ɵɵpureFunction2(16, _c5, ctx.handleKeyDown, ctx.handleBlur));
        i0.ɵɵadvance();
        i0.ɵɵtextInterpolate2(" ", ctx.textFor("of"), " ", ctx.totalPages, " ");
      }
    },
    dependencies: [NumericTextBoxComponent, PagerFocusableDirective, EventsOutsideAngularDirective],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerInputComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-datapager-input, kendo-pager-input',
      template: `
     <span class="k-pager-input">
        {{textFor('page')}}
        <kendo-numerictextbox kendoPagerFocusable
            [spinners]="false"
            [decimals]="0"
            format="n0"
            [size]="size"
            [disabled]="!hasPages"
            [value]="current"
            [min]="hasPages ? 1 : 0"
            [max]="totalPages"
            [autoCorrect]="true"
            [inputAttributes]="{
                'aria-label': textFor('inputLabel')
            }"
            [title]="textFor('pageNumberInputTitle')"
            [kendoEventsOutsideAngular]="{
                keydown: handleKeyDown,
                focusout: handleBlur
            }">
        </kendo-numerictextbox>
        {{textFor('of')}} {{totalPages}}
     </span>
    `,
      standalone: true,
      imports: [NgClass, NumericTextBoxComponent, PagerFocusableDirective, EventsOutsideAngularDirective]
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: PagerContextService
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    numericInput: [{
      type: ViewChild,
      args: [NumericTextBoxComponent, {
        static: true
      }]
    }],
    size: [{
      type: Input
    }]
  });
})();

// eslint-disable no-access-missing-member
/**
 * Displays information about the current page and the total number of records ([see example]({% slug pager_settings %})).
 */
class PagerInfoComponent extends PagerElementComponent {
  /**
   * @hidden
   *
   * @readonly
   * @type {number}
   * @memberOf PagerInfoComponent
   */
  get maxItems() {
    return Math.min(this.currentPage * this.pageSize, this.total);
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {number}
   * @memberOf PagerInfoComponent
   */
  get currentPageText() {
    return this.total ? (this.currentPage - 1) * this.pageSize + 1 : 0;
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {boolean}
   * @memberOf PagerInfoComponent
   */
  hostClass = true;
  constructor(localization, cd, pagerContext) {
    super(localization, pagerContext, cd);
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = pageSize;
    this.cd.markForCheck();
  }
  static ɵfac = function PagerInfoComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerInfoComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(PagerContextService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PagerInfoComponent,
    selectors: [["kendo-datapager-info"], ["kendo-pager-info"]],
    hostVars: 2,
    hostBindings: function PagerInfoComponent_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("k-pager-info", ctx.hostClass);
      }
    },
    standalone: true,
    features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 1,
    vars: 5,
    template: function PagerInfoComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtext(0);
      }
      if (rf & 2) {
        i0.ɵɵtextInterpolate5("", ctx.currentPageText, " - ", ctx.maxItems, " ", ctx.textFor("of"), " ", ctx.total, " ", ctx.textFor("items"), "");
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerInfoComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-datapager-info, kendo-pager-info',
      template: `{{currentPageText}} - {{maxItems}} {{textFor('of')}} {{total}} {{textFor('items')}}`,
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: PagerContextService
    }];
  }, {
    hostClass: [{
      type: HostBinding,
      args: ['class.k-pager-info']
    }]
  });
})();

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-pager',
  productName: 'Kendo UI for Angular',
  productCode: 'KENDOUIANGULAR',
  productCodes: ['KENDOUIANGULAR'],
  publishDate: 1739287187,
  version: '18.1.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * @hidden
 */
const RESPONSIVE_BREAKPOINT_MEDIUM = 600;
/**
 * @hidden
 */
const RESPONSIVE_BREAKPOINT_LARGE = 768;
class PagerComponent {
  pagerContext;
  element;
  localization;
  renderer;
  ngZone;
  navigationService;
  template;
  /**
   * @hidden
   */
  externalTemplate;
  /**
   * Represents the total number of data items in the collection.
   *
   * @default 0
   */
  total = 0;
  /**
   * Defines the number of data items to be skipped.
   *
   * @default 0
   */
  skip = 0;
  /**
   * Defines the number of data items per page.
   */
  pageSize;
  /**
   * Sets the maximum numeric buttons count before the buttons are collapsed.
   *
   * @default 10
   */
  buttonCount = 10;
  /**
   * Toggles the information about the current page and the total number of records.
   *
   * @default true
   */
  info = true;
  /**
   * Defines the type of the pager.
   *
   * @default 'numeric'
   */
  type = 'numeric';
  /**
   * Shows a dropdown for selecting the page size.
   * When set to `true`, the dropdown will contain the default list of options - 5, 10, 20.
   * To customize the list of options, set `pageSizeValues` to an array of the desired values.
   * The array can contain numbers and [PageSizeItem]({% slug api_pager_pagesizeitem %}) objects.
   */
  set pageSizeValues(value) {
    if (typeof value === 'boolean') {
      this._pageSizeValues = value ? DEFAULT_PAGE_SIZE_VALUES : [];
    } else {
      this._pageSizeValues = value;
    }
  }
  /**
   * Toggles the **Previous** and **Next** buttons.
   *
   * @default true
   */
  previousNext = true;
  /**
   * If set to `true`, the user can use dedicated shortcuts to interact with the Pager ([see example]({% slug keyboard_navigation_pager %})).
   * By default, navigation is disabled and the Pager content is accessible in the normal tab sequence.
   * @default false
   */
  set navigable(value) {
    this._navigable = value;
    this.navigationService.isNavigable = value;
  }
  get navigable() {
    return this._navigable;
  }
  /**
   * Specifies the padding of all Pager elements.
   *
   * The possible values are:
   * * `small`
   * * `medium` (default)
   * * `large`
   * * `none`
   */
  set size(size) {
    const newSize = size ? size : DEFAULT_SIZE;
    this.handleClasses(newSize, 'size');
    this._size = newSize;
  }
  get size() {
    return this._size;
  }
  /**
   * @hidden
   * Toggles the Pager responsive functionality.
   *
   * @default true
   */
  responsive = true;
  /**
   * Fires when the current page of the Pager is changed ([see example](slug:overview_pager)).
   * You have to handle the event yourself and page the data.
   */
  pageChange = new EventEmitter();
  /**
   * Fires when the page size of the Pager is changed.
   * You have to handle the event yourself and page the data.
   * If the event is prevented, the page size will remain unchanged ([see example]({% slug pager_events %})).
   */
  pageSizeChange = new EventEmitter();
  pagerClass = true;
  widgetRole = 'application';
  roleDescription = 'pager';
  keyShortcuts = 'Enter ArrowRight ArrowLeft';
  get hostTabindex() {
    return this.navigable ? '0' : '-1';
  }
  get dir() {
    return this.direction;
  }
  /**
   * @hidden
   */
  focusHandler(ev) {
    const isInnerNavigationEnabled = ev.target !== this.element.nativeElement;
    this.navigationService.toggleInnerNavigation(isInnerNavigationEnabled);
  }
  get totalPages() {
    return Math.ceil((this.total || 0) / this.pageSize);
  }
  get currentPage() {
    return Math.floor((this.skip || 0) / this.pageSize) + 1;
  }
  get templateContext() {
    const context = this._templateContext;
    context.totalPages = this.totalPages;
    context.total = this.total;
    context.skip = this.skip;
    context.pageSize = this.pageSize;
    context.currentPage = this.currentPage;
    return context;
  }
  subscriptions = new Subscription();
  _templateContext = {};
  _pageSizeValues = DEFAULT_PAGE_SIZE_VALUES;
  direction;
  isInnerNavigationEnabled = false;
  _navigable = false;
  _size = DEFAULT_SIZE;
  constructor(pagerContext, element, localization, renderer, ngZone, navigationService) {
    this.pagerContext = pagerContext;
    this.element = element;
    this.localization = localization;
    this.renderer = renderer;
    this.ngZone = ngZone;
    this.navigationService = navigationService;
    validatePackage(packageMetadata);
    this.direction = localization.rtl ? 'rtl' : 'ltr';
    if (!navigationService) {
      this.navigationService = inject(PagerNavigationService);
    }
    if (!pagerContext) {
      this.pagerContext = inject(PagerContextService);
    }
    this.pagerContext.localization = localization;
  }
  ngOnInit() {
    this.subscriptions.add(this.pagerContext.pageChange.subscribe(this.changePage.bind(this)));
    this.subscriptions.add(this.pagerContext.pageSizeChange.subscribe(this.changePageSize.bind(this)));
    this.subscriptions.add(this.localization.changes.subscribe(({
      rtl
    }) => {
      this.direction = rtl ? 'rtl' : 'ltr';
    }));
    this.subscriptions.add(this.navigationService.innerNavigationChange.subscribe(this.innerNavigationChange.bind(this)));
    if (this.navigable) {
      const wrapper = this.element.nativeElement;
      this.ngZone.runOutsideAngular(() => {
        this.subscriptions.add(this.renderer.listen(wrapper, 'keydown', this.keyDownHandler.bind(this)));
      });
    }
  }
  ngAfterViewInit() {
    this.responsive && this.resizeHandler();
    this.renderer.setAttribute(this.element.nativeElement, 'aria-label', this.ariaLabel);
    this.subscriptions.add(this.template.changes.subscribe(() => {
      this.responsive && this.resizeHandler();
    }));
    this.handleClasses(this.size, 'size');
  }
  ngOnChanges(changes) {
    if (anyChanged(["pageSize", "skip", "total"], changes, false)) {
      this.pagerContext.notifyChanges({
        pageSize: this.pageSize,
        skip: this.skip,
        total: this.total
      });
      this.renderer.setAttribute(this.element.nativeElement, 'aria-label', this.ariaLabel);
    }
    if (changes['responsive']) {
      this.responsive && this.resizeHandler();
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  /**
   * @hidden
   */
  changePage(event) {
    this.pageChange.emit(event);
  }
  /**
   * @hidden
   */
  changePageSize(event) {
    this.pageSizeChange.emit(event);
    if (!event.isDefaultPrevented()) {
      if (event.newPageSize === 'all') {
        this.pageChange.emit({
          skip: 0,
          take: this.total
        });
      } else {
        this.pageChange.emit({
          skip: 0,
          take: event.newPageSize
        });
      }
    }
  }
  /**
   * @hidden
   */
  onPageSizeChange(event) {
    this.pageSizeChange.emit(event);
    if (!event.isDefaultPrevented()) {
      this.pageChange.emit({
        skip: this.skip,
        take: event.newPageSize
      });
    }
  }
  /**
   * @hidden
   */
  resizeHandler = () => {
    if (this.template?.first && !this.responsive) {
      return;
    }
    const width = this.element.nativeElement.offsetWidth;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const numericButtonsElement = this.element.nativeElement.querySelector('kendo-datapager-numeric-buttons, kendo-pager-numeric-buttons');
        const pagerInfoElement = this.element.nativeElement.querySelector('.k-pager-info');
        const pagerPageSizes = this.element.nativeElement.querySelector('.k-pager-sizes');
        if (numericButtonsElement) {
          const selectElement = numericButtonsElement.querySelector('select');
          const numbersElement = numericButtonsElement.querySelector('.k-pager-numbers');
          if (width < RESPONSIVE_BREAKPOINT_MEDIUM) {
            this.renderer.removeClass(selectElement, 'k-hidden');
            this.renderer.addClass(numbersElement, 'k-hidden');
          } else {
            this.renderer.addClass(selectElement, 'k-hidden');
            this.renderer.removeClass(numbersElement, 'k-hidden');
          }
        }
        if (pagerInfoElement) {
          if (width < RESPONSIVE_BREAKPOINT_LARGE) {
            this.renderer.addClass(pagerInfoElement, 'k-hidden');
          } else {
            this.renderer.removeClass(pagerInfoElement, 'k-hidden');
          }
        }
        if (pagerPageSizes) {
          if (width < RESPONSIVE_BREAKPOINT_MEDIUM) {
            this.renderer.addClass(pagerPageSizes, 'k-hidden');
          } else {
            this.renderer.removeClass(pagerPageSizes, 'k-hidden');
          }
        }
      });
    });
  };
  get ariaLabel() {
    const localizationMsg = this.localization.get('ariaLabel') || '';
    return replaceMessagePlaceholder(replaceMessagePlaceholder(localizationMsg, 'currentPage', this.currentPage.toString()), 'totalPages', this.totalPages.toString());
  }
  keyDownHandler(e) {
    const target = e.target;
    const wrapper = this.element.nativeElement;
    const isArrowLeftOrPageUp = e.keyCode === Keys.ArrowLeft || e.keyCode === Keys.PageUp;
    const isArrowRightOrPageDown = e.keyCode === Keys.ArrowRight || e.keyCode === Keys.PageDown;
    const isEnter = e.keyCode === Keys.Enter;
    const isHome = e.keyCode === Keys.Home;
    const isEnd = e.keyCode === Keys.End;
    const isEsc = e.keyCode === Keys.Escape;
    const isTab = e.keyCode === Keys.Tab;
    const isFirstPage = this.currentPage === 1;
    const isLastPage = this.currentPage === this.totalPages;
    this.ngZone.run(() => {
      if (isHome) {
        if (e.target !== wrapper) {
          return;
        }
        e.preventDefault();
        !isFirstPage && this.pagerContext.changePage(0);
      } else if (isEnd) {
        e.preventDefault();
        if (e.target !== wrapper) {
          return;
        }
        !isLastPage && this.pagerContext.changePage(this.totalPages - 1);
      } else if (this.isInnerNavigationEnabled) {
        if (isEsc) {
          this.navigationService.toggleInnerNavigation(false);
          wrapper.focus();
        } else if (isTab) {
          this.navigationService.keepFocusWithinComponent(wrapper, target, e);
        }
      } else {
        if (e.target !== wrapper) {
          return;
        }
        if (isArrowLeftOrPageUp) {
          e.preventDefault();
          !isFirstPage && this.pagerContext.prevPage();
        } else if (isArrowRightOrPageDown) {
          e.preventDefault();
          !isLastPage && this.pagerContext.nextPage();
        } else if (isEnter) {
          e.preventDefault();
          const [firstFocusable] = this.navigationService.getFirstAndLastFocusable(wrapper);
          this.navigationService.toggleInnerNavigation(true);
          firstFocusable?.focus();
        }
      }
    });
  }
  innerNavigationChange(value) {
    this.isInnerNavigationEnabled = value;
  }
  handleClasses(value, input) {
    const elem = this.element.nativeElement;
    const classes = getStylingClasses('pager', input, this[input], value);
    if (classes.toRemove) {
      this.renderer.removeClass(elem, classes.toRemove);
    }
    if (classes.toAdd) {
      this.renderer.addClass(elem, classes.toAdd);
    }
  }
  static ɵfac = function PagerComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerComponent)(i0.ɵɵdirectiveInject(PagerContextService, 12), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PagerNavigationService, 12));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PagerComponent,
    selectors: [["kendo-datapager"], ["kendo-pager"]],
    contentQueries: function PagerComponent_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PagerTemplateDirective, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t);
      }
    },
    hostVars: 7,
    hostBindings: function PagerComponent_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("focusin", function PagerComponent_focusin_HostBindingHandler($event) {
          return ctx.focusHandler($event);
        });
      }
      if (rf & 2) {
        i0.ɵɵattribute("role", ctx.widgetRole)("aria-roledescription", ctx.roleDescription)("aria-keyshortcuts", ctx.keyShortcuts)("tabindex", ctx.hostTabindex)("dir", ctx.dir);
        i0.ɵɵclassProp("k-pager", ctx.pagerClass);
      }
    },
    inputs: {
      externalTemplate: "externalTemplate",
      total: "total",
      skip: "skip",
      pageSize: "pageSize",
      buttonCount: "buttonCount",
      info: "info",
      type: "type",
      pageSizeValues: "pageSizeValues",
      previousNext: "previousNext",
      navigable: "navigable",
      size: "size",
      responsive: "responsive"
    },
    outputs: {
      pageChange: "pageChange",
      pageSizeChange: "pageSizeChange"
    },
    exportAs: ["kendoDataPager", "kendoPager"],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([LocalizationService, PagerContextService, PagerNavigationService, {
      provide: L10N_PREFIX,
      useValue: 'kendo.pager'
    }]), i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
    decls: 6,
    vars: 4,
    consts: () => {
      let i18n_0;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The label for the first page button in the Pager
         * @meaning kendo.pager.firstPage
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_0 = goog.getMsg("Go to the first page");
        i18n_0 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_0;
      } else {
        i18n_0 = $localize`:kendo.pager.firstPage|The label for the first page button in the Pager:Go to the first page`;
      }
      let i18n_1;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The label for the previous page button in the Pager
         * @meaning kendo.pager.previousPage
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_1 = goog.getMsg("Go to the previous page");
        i18n_1 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_1;
      } else {
        i18n_1 = $localize`:kendo.pager.previousPage|The label for the previous page button in the Pager:Go to the previous page`;
      }
      let i18n_2;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The label for the next page button in the Pager
         * @meaning kendo.pager.nextPage
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_2 = goog.getMsg("Go to the next page");
        i18n_2 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_2;
      } else {
        i18n_2 = $localize`:kendo.pager.nextPage|The label for the next page button in the Pager:Go to the next page`;
      }
      let i18n_3;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The label for the last page button in the Pager
         * @meaning kendo.pager.lastPage
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_3 = goog.getMsg("Go to the last page");
        i18n_3 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_3;
      } else {
        i18n_3 = $localize`:kendo.pager.lastPage|The label for the last page button in the Pager:Go to the last page`;
      }
      let i18n_4;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The label before the current page number in the Pager
         * @meaning kendo.pager.page
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_4 = goog.getMsg("Page");
        i18n_4 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_4;
      } else {
        i18n_4 = $localize`:kendo.pager.page|The label before the current page number in the Pager:Page`;
      }
      let i18n_5;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The label before the total pages number in the Pager
         * @meaning kendo.pager.of
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_5 = goog.getMsg("of");
        i18n_5 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_5;
      } else {
        i18n_5 = $localize`:kendo.pager.of|The label before the total pages number in the Pager:of`;
      }
      let i18n_6;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The label for the pager input in the Pager
         * @meaning kendo.pager.pageNumberInputTitle
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_6 = goog.getMsg("Page Number");
        i18n_6 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_6;
      } else {
        i18n_6 = $localize`:kendo.pager.pageNumberInputTitle|The label for the pager input in the Pager:Page Number`;
      }
      let i18n_7;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The label after the total pages number in the Pager
         * @meaning kendo.pager.items
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_7 = goog.getMsg("items");
        i18n_7 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_7;
      } else {
        i18n_7 = $localize`:kendo.pager.items|The label after the total pages number in the Pager:items`;
      }
      let i18n_8;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The label for the page size chooser in the Pager
         * @meaning kendo.pager.itemsPerPage
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_8 = goog.getMsg("items per page");
        i18n_8 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_8;
      } else {
        i18n_8 = $localize`:kendo.pager.itemsPerPage|The label for the page size chooser in the Pager:items per page`;
      }
      let i18n_9;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The text of the title and aria-label attributes applied to the page chooser in the Pager
         * @meaning kendo.pager.selectPage
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_9 = goog.getMsg("Select page");
        i18n_9 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_9;
      } else {
        i18n_9 = $localize`:kendo.pager.selectPage|The text of the title and aria-label attributes applied to the page chooser in the Pager:Select page`;
      }
      let i18n_10;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The text of the aria-label attribute applied to the input element for entering the page number.
         * @meaning kendo.pager.inputLabel
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_10 = goog.getMsg("Type a page number");
        i18n_10 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_10;
      } else {
        i18n_10 = $localize`:kendo.pager.inputLabel|The text of the aria-label attribute applied to the input element for entering the page number.:Type a page number`;
      }
      let i18n_11;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The value of the aria-label attribute of the Pager
         * @meaning kendo.pager.ariaLabel
         */
        const MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_11 = goog.getMsg("{$interpolation}", {
          "interpolation": "\uFFFD0\uFFFD"
        }, {
          original_code: {
            "interpolation": "{{ 'Page navigation, page {currentPage} of {totalPages}' }}"
          }
        });
        i18n_11 = MSG__AGENT__WORK_1_S_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2022_PROGRESS_KENDO_ANGULAR_PAGER_MJS_11;
      } else {
        i18n_11 = $localize`:kendo.pager.ariaLabel|The value of the aria-label attribute of the Pager:${"\uFFFD0\uFFFD"}:INTERPOLATION:`;
      }
      return [["default", ""], ["ariaLabel", i18n_11], ["kendoPagerLocalizedMessages", "", "firstPage", i18n_0, "previousPage", i18n_1, "nextPage", i18n_2, "lastPage", i18n_3, "page", i18n_4, "of", i18n_5, "pageNumberInputTitle", i18n_6, "items", i18n_7, "itemsPerPage", i18n_8, "selectPage", i18n_9, "inputLabel", i18n_10, 6, "ariaLabel"], [3, "ngTemplateOutlet", "ngTemplateOutletContext", 4, "ngIf", "ngIfElse"], [3, "resize", 4, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "k-pager-numbers-wrap"], [3, "size", 4, "ngIf"], [3, "size", "buttonCount", 4, "ngIf"], [3, "size", "pageSizes", 4, "ngIf"], [4, "ngIf"], [3, "size"], [3, "size", "buttonCount"], [3, "size", "pageSizes"], [3, "resize"]];
    },
    template: function PagerComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementContainerStart(0, 2);
        i0.ɵɵi18nAttributes(1, 1);
        i0.ɵɵelementContainerEnd();
        i0.ɵɵtemplate(2, PagerComponent_ng_container_2_Template, 1, 2, "ng-container", 3)(3, PagerComponent_ng_template_3_Template, 7, 6, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(5, PagerComponent_kendo_resize_sensor_5_Template, 1, 0, "kendo-resize-sensor", 4);
      }
      if (rf & 2) {
        const default_r3 = i0.ɵɵreference(4);
        i0.ɵɵi18nExp("Page navigation, page {currentPage} of {totalPages}");
        i0.ɵɵi18nApply(1);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.template.first == null ? null : ctx.template.first.templateRef)("ngIfElse", default_r3);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.responsive);
      }
    },
    dependencies: [LocalizedMessagesDirective, NgIf, NgTemplateOutlet, PagerPrevButtonsComponent, PagerNumericButtonsComponent, PagerInputComponent, PagerNextButtonsComponent, PagerPageSizesComponent, PagerInfoComponent, ResizeSensorComponent],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-datapager, kendo-pager',
      exportAs: 'kendoDataPager, kendoPager',
      providers: [LocalizationService, PagerContextService, PagerNavigationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.pager'
      }],
      template: `
        <ng-container kendoPagerLocalizedMessages
            i18n-ariaLabel="kendo.pager.ariaLabel|The value of the aria-label attribute of the Pager"
            ariaLabel="{{ 'Page navigation, page {currentPage} of {totalPages}' }}"

            i18n-firstPage="kendo.pager.firstPage|The label for the first page button in the Pager"
            firstPage="Go to the first page"

            i18n-previousPage="kendo.pager.previousPage|The label for the previous page button in the Pager"
            previousPage="Go to the previous page"

            i18n-nextPage="kendo.pager.nextPage|The label for the next page button in the Pager"
            nextPage="Go to the next page"

            i18n-lastPage="kendo.pager.lastPage|The label for the last page button in the Pager"
            lastPage="Go to the last page"

            i18n-page="kendo.pager.page|The label before the current page number in the Pager"
            page="Page"

            i18n-of="kendo.pager.of|The label before the total pages number in the Pager"
            of="of"

            i18n-pageNumberInputTitle="kendo.pager.pageNumberInputTitle|The label for the pager input in the Pager"
            pageNumberInputTitle="Page Number"

            i18n-items="kendo.pager.items|The label after the total pages number in the Pager"
            items="items"

            i18n-itemsPerPage="kendo.pager.itemsPerPage|The label for the page size chooser in the Pager"
            itemsPerPage="items per page"

            i18n-selectPage="kendo.pager.selectPage|The text of the title and aria-label attributes applied to the page chooser in the Pager"
            selectPage="Select page"

            i18n-inputLabel="kendo.pager.inputLabel|The text of the aria-label attribute applied to the input element for entering the page number."
            inputLabel="Type a page number"
         >
        </ng-container>
        <ng-container
            *ngIf="template.first?.templateRef; else default"
            [ngTemplateOutlet]="template.first?.templateRef"
            [ngTemplateOutletContext]="templateContext">
        </ng-container>
        <ng-template #default>
            <div class="k-pager-numbers-wrap">
                <kendo-pager-prev-buttons [size]="size" *ngIf="previousNext"></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons
                    [size]="size"
                    *ngIf="type === 'numeric' && buttonCount > 0"
                    [buttonCount]="buttonCount">
                </kendo-pager-numeric-buttons>
                <kendo-pager-input [size]="size" *ngIf="type === 'input'"></kendo-pager-input>
                <kendo-pager-next-buttons [size]="size" *ngIf="previousNext"></kendo-pager-next-buttons>
            </div>
            <kendo-pager-page-sizes *ngIf="_pageSizeValues.length"
                [size]="size"
                [pageSizes]="_pageSizeValues">
            </kendo-pager-page-sizes>
            <kendo-pager-info *ngIf="info">
            </kendo-pager-info>
        </ng-template>
        <kendo-resize-sensor *ngIf="responsive" (resize)="resizeHandler()"></kendo-resize-sensor>
  `,
      standalone: true,
      imports: [LocalizedMessagesDirective, NgIf, NgTemplateOutlet, PagerPrevButtonsComponent, PagerNumericButtonsComponent, PagerInputComponent, PagerNextButtonsComponent, PagerPageSizesComponent, PagerInfoComponent, ResizeSensorComponent]
    }]
  }], function () {
    return [{
      type: PagerContextService,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }]
    }, {
      type: i0.ElementRef
    }, {
      type: i1.LocalizationService
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }, {
      type: PagerNavigationService,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }]
    }];
  }, {
    template: [{
      type: ContentChildren,
      args: [PagerTemplateDirective]
    }],
    externalTemplate: [{
      type: Input
    }],
    total: [{
      type: Input
    }],
    skip: [{
      type: Input
    }],
    pageSize: [{
      type: Input
    }],
    buttonCount: [{
      type: Input
    }],
    info: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    pageSizeValues: [{
      type: Input
    }],
    previousNext: [{
      type: Input
    }],
    navigable: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    responsive: [{
      type: Input
    }],
    pageChange: [{
      type: Output
    }],
    pageSizeChange: [{
      type: Output
    }],
    pagerClass: [{
      type: HostBinding,
      args: ['class.k-pager']
    }],
    widgetRole: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    roleDescription: [{
      type: HostBinding,
      args: ['attr.aria-roledescription']
    }],
    keyShortcuts: [{
      type: HostBinding,
      args: ['attr.aria-keyshortcuts']
    }],
    hostTabindex: [{
      type: HostBinding,
      args: ['attr.tabindex']
    }],
    dir: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    focusHandler: [{
      type: HostListener,
      args: ['focusin', ['$event']]
    }]
  });
})();

/**
 * Represents the Kendo UI PagerSpacer component for Angular.
 * Used to give additional white space between the Pager inner elements,
 * and provides a way for customizing the spacer width.
 */
class PagerSpacerComponent {
  hostClass = true;
  get sizedClass() {
    return isPresent(this.width);
  }
  get flexBasisStyle() {
    return this.width;
  }
  /**
   * Specifies the width of the PagerSpacer.
   * Accepts the [string values of the CSS `flex-basis` property](https://developer.mozilla.org/en-US/docs/Web/CSS/flex-basis).
   *
   * If not set, the PagerSpacer will take all the available space.
   */
  width;
  static ɵfac = function PagerSpacerComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerSpacerComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PagerSpacerComponent,
    selectors: [["kendo-pager-spacer"]],
    hostVars: 6,
    hostBindings: function PagerSpacerComponent_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵstyleProp("flex-basis", ctx.flexBasisStyle);
        i0.ɵɵclassProp("k-spacer", ctx.hostClass)("k-spacer-sized", ctx.sizedClass);
      }
    },
    inputs: {
      width: "width"
    },
    standalone: true,
    features: [i0.ɵɵStandaloneFeature],
    decls: 0,
    vars: 0,
    template: function PagerSpacerComponent_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerSpacerComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-pager-spacer',
      template: ``,
      standalone: true
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class.k-spacer']
    }],
    sizedClass: [{
      type: HostBinding,
      args: ['class.k-spacer-sized']
    }],
    flexBasisStyle: [{
      type: HostBinding,
      args: ['style.flexBasis']
    }],
    width: [{
      type: Input
    }]
  });
})();

/**
 * Utility array that contains all `@progress/kendo-angular-pager` related components and directives
 */
const KENDO_PAGER = [CustomMessagesComponent, PagerFocusableDirective, PagerInfoComponent, PagerInputComponent, PagerNextButtonsComponent, PagerNumericButtonsComponent, PagerPageSizesComponent, PagerPrevButtonsComponent, PagerTemplateDirective, PagerComponent, PagerSpacerComponent];

//IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Pager component. Imports `PagerModule` into the
 * [root module](link:site.data.urls.angular['ngmodules']#angular-modularity)
 * of your application or into any other sub-module that will use the Pager component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { PagerModule } from '@progress/kendo-angular-pager';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, PagerModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
class PagerModule {
  static ɵfac = function PagerModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PagerModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: PagerModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [IconsService, PopupService, ResizeBatchService],
    imports: [PagerInputComponent, PagerNextButtonsComponent, PagerNumericButtonsComponent, PagerPageSizesComponent, PagerPrevButtonsComponent, PagerComponent]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_PAGER],
      imports: [...KENDO_PAGER],
      providers: [IconsService, PopupService, ResizeBatchService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CustomMessagesComponent, KENDO_PAGER, LocalizedMessagesDirective, PageSizeChangeEvent, PagerComponent, PagerContextService, PagerFocusableDirective, PagerInfoComponent, PagerInputComponent, PagerModule, PagerNavigationService, PagerNextButtonsComponent, PagerNumericButtonsComponent, PagerPageSizesComponent, PagerPrevButtonsComponent, PagerSpacerComponent, PagerTemplateDirective };