import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ElementRef, Pipe, PipeTransform, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  AccountInfo,
  AuthenticationResult,
  AuthorizationUrlRequest,
  EndSessionRequest,
  EventMessage,
  IPublicClientApplication,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { ListItem } from '@progress/kendo-angular-dateinputs/timepicker/models/list-item.interface';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { FileInfo } from '@progress/kendo-angular-upload';
import { Dictionary } from 'lodash';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { OrderBundleFileType, UIOrderBundle } from 'src/app/components/int-payer-order/interfaces/int-payer-order.interface';
import { OrderBundle } from 'src/app/components/int-payer-order/openapi';
import {
  CodeLookupDtl,
  HcpcCategory,
  HcpcCode,
  ListItem as MdmListItem,
  ListItemDtl,
  NPIv2,
  Product
} from 'src/app/components/mdm/openapi';
import { MemberFullInformation, MemberGrouped } from 'src/app/components/members/openapi';
import {
  CancelDtls,
  Change,
  NeedsAttentionDtls,
  OrdAuthWindowDt,
  Order,
  OrderAttach,
  OrderCall,
  OrderChangesByStatusReq,
  OrderListEntry,
  OrderNote,
  OrderProviderLookupAddr,
  OrderProviderLookupProduct,
  OrderStatusChg,
  OrderStep,
  OrderSummEntry,
  SummaryReq,
  Transition
} from 'src/app/components/orders/openapi/model/models';
import { ProviderFacilites } from 'src/app/components/orders/order-shared-components/interfaces/order-provider-facilities.interface';
import { OrderSummaryEntry } from 'src/app/components/orders/order-shared-components/interfaces/order-summary.interface';
import {
  BulkAddressImport,
  PayerAccount,
  PayerAccountList,
  PayerFamily,
  PayerFamilyList,
  PayerLob,
  PayerLobList,
  PayerPlan,
  PayerPlanDetails,
  PayerPlanList
} from 'src/app/components/payers/openapi';
import { ReportStepId } from 'src/app/components/reports/enums/reports.enum';
import { ReportValues, ReportViewMode } from 'src/app/components/reports/interfaces/reports.interfaces';
import { ReportDataProviderMethod } from 'src/app/components/reports/services/data-providers/data-provider.interface';
import { JobGridListDtl, JobGridListResponse } from 'src/app/components/scheduler/interfaces/job-grid-list';
import { Job, JobListItem, Schedule, ScheduleListItem, Task, TaskParam } from 'src/app/components/ss-sched/openapi';
import { EDIBatch, EDIBatchChunk, EDIBatchChunkList, TxnType } from '../../components/edi/openapi';
import {
  ClaimDtl,
  ClaimTimelyCount,
  CompleteClaims,
  IntakeRuleFields,
  Notes,
  Team,
  TeamTimelyCount,
  TeamUser
} from '../../components/mcq/openapi';
import { FacilityDto as OrderFacilityDto } from '../../components/orders/openapi';
import {
  Address,
  ComplainceDocumentsViewModel,
  FacilityAdminViewModel,
  FacilityDto,
  FacilityRankingDtoViewModel,
  FacilityViewModel,
  LicenseListViewModel,
  MalpracticeClaimViewModel,
  OrganizationLiteModel,
  OrganizationViewModel,
  OrgContractViewModelResponseModelByType,
  OrgDto,
  OrgFeeScheduleViewModel,
  StaffProfileViewModel,
  StaffResponse,
  StaffRolesFacilityViewModel,
  StaffRolesViewModel,
  StringLookupTypeTemplate
} from '../../components/providers/openapi';
import { DashWidget, UserDash } from '../../components/settings/openapi';
import { LmsDept, LmsDeptMapping } from '../../components/workforce/openapi';
import { attribCanBePrimary } from '../constants';
import {
  AccessInfo,
  Activity,
  PayerAcctId,
  RoleItem,
  RoleItemList,
  User,
  UserRoleType,
  UserWithRolesAccts
} from '../shared-access-module/openapi';
import { PermissibleItem } from '../shared-access-module/services/access-control.service';
import { FormFieldConfig } from '../shared-components/form-field/form-field.interface';
import { PrismFileInfo } from '../shared-components/upload/upload.component';
import {
  AccessResolveRequest,
  ApiFilterRequest,
  BreadCrumbItemI,
  ButtonArray,
  ClaimsListResponse,
  DeliveredFormValues,
  DropDownButtonData,
  EntryItemResponse,
  FacilityForOrder,
  FutureDosFormValue,
  GridData,
  GridSortDescriptor,
  IntakeRuleResponse,
  IntegraSearchConfigColumn,
  IntegraSearchConfigRow,
  IntegraSearchOperators,
  ListItemLookup,
  MbrOrderReference,
  MenuItem,
  OrderGridList,
  OrderState,
  OrganizationContractResponseModel,
  ParsedPayer,
  PayerAcctsFormVals,
  PayersFormValues,
  ResponseModelGeneric,
  SearchDataList,
  ShippedFormValues,
  TeamsListResponse,
  TreeGridItem,
  TreeGridSelections,
  UITransition,
  UserMenuItem,
} from '../shared-interfaces/shared-interfaces';
import {
  MapGeoLocation,
  MapquestMarker,
  MapquestMarkerOptions,
  MapquestOptions,
  MapquestSdk,
  MapquestStyle,
  MapquestTextMarkerOptions
} from '../shared-modules/map/interfaces';
import {
  ActivityModel,
  ChangeLogFilter,
  ChangeLogRequestConfigI,
  ChangeLogServiceI,
  ChangeModel,
  UIActivityAction
} from '../shared-services/activity-log';
import { UserProfileRequest } from '../shared-services/my-profile/my-profile.service';


const advancedSearchColConfigMock = [
  {
    type: 'combobox', label: 'Search by Payer Name', value: null, property: 'name', key: 'Payer', callback: null,
    operator: IntegraSearchOperators.in
  },
  {
    type: 'combobox', label: 'Search by Provider Name', value: null, property: 'name', key: 'Provider', callback: null,
    operator: IntegraSearchOperators.in, pagingConfig: { pageSize: 20, usePageNumberInCallback: true, onPageCallback: null }
  }
];

export const advancedSearchConfigMock: IntegraSearchConfigRow[] = [
  {
    columns: [{
      type: 'text', label: 'First or Last Name or Insurance ID#', value: null, property: 'wildSearch', key: 'WildSearch',
      operator: IntegraSearchOperators.equals
    }]
  },
  {
    columns: [
      { type: 'date', label: 'Date of Birth', value: null, property: 'dob', key: 'Dob', operator: IntegraSearchOperators.equals },
      {
        type: 'text', label: 'Phone', value: null, property: 'primaryPhone', key: 'Phone', mask: '(000) 000-0000',
        operator: IntegraSearchOperators.equals
      }
    ]
  },
  {
    columns: [
      {
        type: 'checkbox',
        label: 'Status',
        value: null,
        key: 'Status',
        property: 'status',
        operator: IntegraSearchOperators.equals,
        options: [
          { key: 'ACTIVE', label: 'Active' },
          { key: 'INACTIVE', label: 'Inactive' }
        ]
      }
    ]
  },
  {
    columns: advancedSearchColConfigMock
  }
];

export const advancedSearchConfigValuesMock: IntegraSearchConfigRow[] = [
  {
    columns: [{ type: 'text', label: 'First or Last Name or Insurance ID#', value: 'Wilmer', property: 'wildSearch', key: 'WildSearch' }]
  },
  {
    columns: [
      { type: 'date', label: 'Date of Birth', value: '01/01/2020', property: 'dob', key: 'Dob' },
      { type: 'text', label: 'Phone', value: '1234567', property: 'primaryPhone', key: 'Phone', mask: '(000) 000-0000' }
    ]
  },
  {
    columns: [
      {
        type: 'checkbox',
        label: 'Status',
        value: 'ACTIVE',
        key: 'Status',
        property: 'status',
        options: [
          { key: 'ACTIVE', label: 'Active' },
          { key: 'INACTIVE', label: 'Inactive' }
        ]
      }
    ]
  },
  {
    columns: advancedSearchColConfigMock
  }
];

export const orderCreationStepsMock = [
  {
    isStepEnabled: true,
    orderStepIcon: 'user',
    orderStepId: 11,
    orderStepName: 'Member & Delivery',
    orderStepPath: 'member-delivery',
    orderStepSeq: 1
  },
  {
    isStepEnabled: true,
    orderStepIcon: 'cart',
    orderStepId: 12,
    orderStepName: 'Product Details',
    orderStepPath: 'product-details',
    orderStepSeq: 2
  },
  {
    isStepEnabled: true,
    orderStepIcon: 'share',
    orderStepId: 13,
    orderStepName: 'Provider Information',
    orderStepPath: 'provider-information',
    orderStepSeq: 3
  },
  {
    isStepEnabled: true,
    orderStepIcon: 'list-unordered',
    orderStepId: 14,
    orderStepName: 'Order Instructions',
    orderStepPath: 'order-instructions',
    orderStepSeq: 4
  },
  {
    isStepEnabled: true,
    orderStepIcon: 'check',
    orderStepId: 15,
    orderStepName: 'Review & Submit',
    orderStepPath: 'review-submit',
    orderStepSeq: 5
  }
];

export const lobListMock = [
  {
    payerLobId: 1,
    payerFamId: 1,
    payerAcctId: 1,
    payerLobName: 'LOB A',
    lobStatusCode: 'active',
    activeFromDt: '2020-01-01',
    lobCode: 'MEDICARE'
  },
  {
    payerLobId: 2,
    payerFamId: 1,
    payerAcctId: 1,
    payerLobName: 'LOB B',
    lobStatusCode: 'active',
    activeFromDt: '2020-01-01',
    lobCode: 'MEDICAID'
  },
];

export const orderDataMock: Order = {
  addtnlCmnt: 'some comment',
  authCode: 'some code',
  authWinFromDt: '2001-01-01',
  authWinToDt: '2001-01-02',
  isMedicarePrimOrd: false,
  mbrHasRef: true,
  mbrId: 1,
  expOrderDeliveryTS: '2010-01-02',
  orderSysType: 'INSTANCE',
  orderDiagCodes: [{
    icd10Code: 'ICDCODE'
  }],
  orderDlvInstr: 'some instr',
  orderId: 10,
  orderItems: [{
    orderItemComment: 'comment',
    productQty: 10,
    productId: 111,
    lineNum: 1,
  }],
  orderNum: 'AA-BB-01',
  orderProvider: { providerFacilityId: 0, providerOrgId: 0, selectionMode: 'LOOKUP' },
  orderRefs: [{
    npiNum: '11111111',
  }],
  orderStatus: {
    code: 'PENDING_ACCEPTANCE',
    assignedTS: '',
    assignedBy: ''
  },
  payerLobId: 1,
  staCity: 'city',
  staIndvAddressID: 123,
  staLine1: 'line1',
  staLine2: 'line2',
  staState: 'CA',
  staTypeCode: 'SHIPPING',
  staZip: '12341',
  staRelToIndv: 'MEMBER',
  isSigRequired: false,
  isTrans: false,
  lastServiceDt: '2010-01-02'
};

export const orderStatusPendingAcceptance: OrderStatusChg = {
  orderId: 1,
  orderStatus: 'PENDING_ACCEPTANCE',
};

export const orderStatusInProgress: OrderStatusChg = {
  orderId: 1,
  orderStatus: 'IN_PROGRESSS',
};


export const orderStatusRejected: OrderStatusChg = {
  orderId: 1,
  orderStatus: 'REJECTED',
  rejectDtls: {
    reasonCode: 'DLV_DEADLINE_CANNOT_MET',
    notes: 'Rejected Reason Note'
  }
};

export const orderStatusClosed: OrderStatusChg = {
  orderId: 1,
  orderStatus: 'CLOSED',
  closedDtls: {
    closedReason: 'PAST_DELIVERY_DEADLINE',
    comments: 'New Child order created'
  }
};

export const orderStatusFutureDos: OrderStatusChg = {
  orderId: 10,
  orderStatus: 'FUTURE_DOS',
  futureDOS: {
    reasonCode: 'Custom Order',
    comments: '',
    futureDT: '2021-01-01'
  }
};

export const orderStatusDelivered: OrderStatusChg = {
  orderId: 1,
  orderStatus: 'DELIVERED',
  deliveredDtls: {
    deliveredTS: '2021-02-10',
    lateDeliveredReason: '',
    isSigRequired: true,
    dlvMethodCode: 'Test'
  }
};

export const orderStatusShipped: OrderStatusChg = {
  orderId: 1,
  orderStatus: 'SHIPPED',
  shippedDtls: {
    carrierCode: 'UPS',
    shippedTS: '2021-02-10',
    lateShippedReason: '',
    trackingNumber: 'abc123',
    carrierName: '',
    isSigRequired: true,
    dlvMethodCode: 'Test'
  }
};

export const categoryRAMock: Array<SearchDataList> = [
  {
    id: 'CIS',
    displayName: 'Contract Issues',
  },
  {
    id: 'MIFP',
    displayName: 'Missing Information From Plan',
  },
  {
    id: 'NEGB',
    displayName: 'Negative Balance',
  },
  {
    id: 'PAR',
    displayName: 'PADR Review',
  },
  {
    id: 'SET',
    displayName: 'Settlement',
  },
];

export const subCatRAMock: Array<SearchDataList> = [
  {
    id: 'BOQK',
    displayName: 'Billed Outside Que - Known Provider',
  },
  {
    id: 'BOQU',
    displayName: 'Billed Outside Que - Unknown Provider',
  },
  {
    id: 'MRA',
    displayName: 'Missing ERA',

  }
];

export const sourceCodeRAMock: Array<SearchDataList> = [
  {
    id: 'EFT',
    displayName: 'EFT',
  },
  {
    id: 'ERA',
    displayName: 'ERA',
  },
  {
    id: 'MAN',
    displayName: 'Manual',
  }
];

export const paymentMethRAMock: Array<SearchDataList> = [
  {
    id: 'ACH',
    displayName: 'ACH',
  },
  {
    id: 'CHK',
    displayName: 'Check',
  }
];


export const statusRAMock: Array<SearchDataList> = [
  {
    id: 'CANCELLED',
    displayName: 'Cancelled',
  },
  {
    id: 'COMPLETE',
    displayName: 'Complete',
  },
  {
    id: 'IN_PROCESS',
    displayName: 'In Process',
  },
  {
    id: 'NEW',
    displayName: 'New',
  }
];

export const billingRAMock: Array<SearchDataList> = [
  {
    id: 5,
    displayName: 'Bill Entity 3',
  },
  {
    id: 6,
    displayName: 'Bill EndisplayNameity 4',
  },
  {
    id: 7,
    displayName: 'Bill\'s Entity',
  }
];

export const memberDataMock: MemberFullInformation = {
  memberId: 1,
  payerLobId: 12344,
  payerFamilyId: 1,
  medicaidId: '1234565432',
  medicareId: '',
  lobCode: 'medicaid',
  insuranceNum: 'string',
  mbrStartDt: '2020-09-24',
  mbrEndDt: '2020-09-24',
  isPrimary: true,
  firstName: 'Victoria',
  lastName: 'Tan',
  dob: '2020-09-24',
  ssn: 999999999,
  primaryLangCode: 'ENG',
  secondaryLangCode: 'BEN',
  contacts: [
    {
      indvContactId: 0,
      contactTypeCode: 'phone',
      contactValue: 'string',
      primaryCntct: false,
      contactIndvName: 'string',
      relToIndv: 'string'
    }
  ],
  addresses: [
    {
      indvAddressId: 1,
      relToIndv: 'MEMBER',
      addressTypeCode: 'SHIPPING',
      addressLine1: '100 Wall Street',
      addressLine2: 'Suite 2502 ',
      city: 'New York',
      state: 'NY',
      zip: '10005',
      useAsShipping: true,
      effectiveFromDt: '2020-09-24',
      effectiveToDt: '2020-09-24'
    }
  ]
};

export const mbrOrderRefMock: MbrOrderReference = {
  mbrId: 1,
  payerLobId: 12344,
  payerFamId: 1,
  firstName: 'Victoria',
  lastName: 'Tan',
  dob: '2020-09-24',
  insuranceNum: 'string',
  medicaidId: '1234565432',
  medicareId: '',
  primaryLangCode: 'ENG',
  primaryLangDesc: 'English',
  secondaryLangCode: 'BEN',
  secondaryLangDesc: 'Bengali',
  mbrStartDt: new Date('2020-09-24'),
  mbrEndDt: new Date('2020-09-24'),
  contacts: [
    {
      indvContactId: 0,
      contactTypeCode: 'phone',
      contactTypeDesc: 'phone',
      contactValue: 'string',
      contactIndvName: 'string',
      primaryCntct: false,
      relToIndv: 'string',
    }
  ],
  addresses: [{
    indvAddressId: 1,
    relToIndv: 'MEMBER',
    addresseeName: '',
    addressTypeCode: 'SHIPPING',
    addressTypeDesc: 'Shipping address',
    addressLine1: '100 Wall Street',
    addressLine2: 'Suite 2502 ',
    city: 'New York',
    state: 'NY',
    zip: '10005',
    useAsShipping: true,
    effectiveFromDt: '2020-09-24',
    effectiveToDt: '2020-09-24'
  }],

};

export const orderMock: OrderState = {
  stepsCompleted: [],
  orderSummary: null,
  memberOrderState: {
    address: memberDataMock.addresses[0]
  },
  productOrderState: {
    products: [],
    icdCodes: [''],
    authorizationCode: '',
    authWindowFrom: null,
    authWindowTo: null
  }, providerOrderState: {
    facilityId: null,
    organizationDtls: {
      organizationName: 'name',
      organizationId: null,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      phoneNumber: null
    },
  },
  extraOrderState: {
    expOrderDeliveryDate: null,
    isMedicarePrimOrder: false,
    filesList: [],
    isTrans: true,
    transDesc: 'transition needed',
    lastServiceDt: null,
    isMaskedAttachment: false
  }
};

export const orderStateMock: OrderState = {
  stepsCompleted: [],
  orderSummary: {
    orderId: 1,
    payerLobId: 1,
    memberId: 1,
    orderStatusCode: 'PENDING_ACCEPTANCE',
    payerFamilyName: 'Family Name',
    orderNum: '123',
    orderStatusDesc: 'Pending Acceptance',
    payerLobName: 'Test Lob',
    payerPlanName: 'Test Plan',
    payerAcctName: 'Test Account',
    createdBy: 'abc',
    createdTs: null
  },
  memberOrderState: {
    address: memberDataMock.addresses[0]
  },
  productOrderState: {
    products: [],
    icdCodes: [''],
    authorizationCode: '',
    authWindowFrom: null,
    authWindowTo: null
  },
  providerOrderState: {
    facilityId: null,
    organizationDtls: {
      organizationName: 'name',
      organizationId: null,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      phoneNumber: null,
    },
  },
  extraOrderState: {
    expOrderDeliveryDate: null,
    isMedicarePrimOrder: false,
    filesList: [],
    isTrans: false,
    lastServiceDt: null,
    isMaskedAttachment: false
  }
};

export function createOrderStateFake(): OrderState {
  return {
    stepsCompleted: [],
    orderSummary: {
      orderId: 1,
      payerLobId: 1,
      memberId: 1,
      orderStatusCode: 'PENDING_ACCEPTANCE',
      payerFamilyName: 'Family Name',
      orderNum: '123',
      orderStatusDesc: 'Pending Acceptance',
      payerLobName: 'Test Lob',
      payerAcctName: 'Test Account',
      payerPlanName: 'Test Plan',
      createdBy: 'abc',
      createdTs: null
    },
    memberOrderState: {
      address: memberDataMock.addresses[0]
    },
    productOrderState: {
      products: [],
      icdCodes: [''],
      authorizationCode: '',
      authWindowFrom: null,
      authWindowTo: null
    },
    providerOrderState: {
      facilityId: null,
      organizationDtls: {
        organizationName: 'name',
        organizationId: null,
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: null
      },
    },
    extraOrderState: {
      expOrderDeliveryDate: null,
      isMedicarePrimOrder: false,
      filesList: [],
      isTrans: false,
      lastServiceDt: null,
      isMaskedAttachment: false
    }
  };
}

export const langList = [
  'English',
  'French',
  'Spanish'
];

export class ActivatedRouteMock {
  snapshot = {
    params: {
      id: '',
      payerId: '10'
    },
    queryParams: {
      payer: ''
    },
    routeConfig: {
      data: {
        selected: {
          listTypeName: ''
        },
        payer: '',
      }
    }
  };
}

export class ActivatedRouteOrgMock {
  parent = {
    snapshot: {
      params: {
        organizationId: '5'
      }
    }
  };
}

export class ActivatedRouteFacMock {
  parent = {
    snapshot: {
      params: {
        facilityId: '5'
      }
    }
  };
}

export class ActivatedRouteActionMock {

  snapshot = {
    data: {
      action: 'VIEW'
    }
  };
}

export const ActivatedRouteStaffMock = {

  parent: {
    snapshot: {
      params: {
        organizationId: '5'
      }
    }
  },
  snapshot: {
    params: {
      staffId: '5322'
    },
    data: {
      action: 'VIEW'
    }
  }
};

// this.facilityId = this.activatedRoute.snapshot.params.facilityId ?? '0';

// let currentRoute = this.activatedRoute.snapshot.firstChild?.url[0].path;

export class ActivatedRouteDataMock {
  snapshot = {
    params: {
      id: ''
    },
    queryParams: {
      payer: ''
    },
    data: {
      selected: {
        listTypeName: ''
      },
      payer: '',
    }
  };
}

export class ActivatedRouteFullMock {
  snapshot = {
    params: {
      id: ''
    },
    queryParams: {
      payer: ''
    },
    routeConfig: {
      data: {
        selected: {
          listTypeName: ''
        },
        payer: '',
      }
    },
    data: {
      selected: {
        listTypeName: ''
      },
      payer: '',
    }
  };
}

export const memberFullInformationMock: MemberFullInformation = {
  memberId: 1,
  payerLobId: 3,
  medicaidId: '',
  medicareId: '',
  lobCode: 'test',
  insuranceNum: 'test',
  mbrStartDt: 'test',
  mbrEndDt: 'test',
  isPrimary: true,
  firstName: 'test',
  lastName: 'test',
  dob: 'test',
  ssn: 1,
  contacts: [],
  addresses: []
};

export const facilityForOrderMock: FacilityForOrder[] = [{
  organizationId: 453,
  facilityId: 453,
  organizationLegalName: 'Test Organization',
  organizationDBAName: 'string',
  storeName: 'CVS Pharmacy',
  phoneNumber: '1231231212',
  isHistProv: false,
  languages: 'ENG',
  address: '123 abc street, suite 700',
  address1: '123 abc street',
  address2: 'suite 700',
  city: 'city',
  state: 'state',
  zip: '12345'
}, {
  organizationId: 121,
  facilityId: 555,
  organizationLegalName: 'Another Org',
  organizationDBAName: 'string',
  storeName: 'BDC Pharmacy',
  phoneNumber: '4441231111',
  isHistProv: false,
  languages: 'SPA',
  address: '333 abc street, suite 700',
  address1: '333 abc street',
  address2: 'suite 700',
  city: 'another city',
  state: 'MI',
  zip: '54321'
}];

export const PayerAcctDataMock: PayerAccount = {
  payerAcctName: 'Test Account',
  status: 'active',
  activeFromDt: '2020-01-01',
  payerAcctId: 1,
  payerPlanId: 10,
};

export const PayerFamDataMock: PayerFamily = {
  payerFamName: 'Family A',
  status: 'Active',
  activeFromDt: '2010-01-01',
  domains: []
};

export const PayerPlanDataMock: PayerPlan = {
  payerPlanName: 'Plan A',
  status: 'Active',
  activeFromDt: '2010-01-01',
};

export const PayerPlanDetailsMock: PayerPlanDetails = {
  payerPlanName: 'Plan A',
  status: 'Active',
  activeFromDt: '2010-01-01',
};

export const PayerLobMock: PayerLob = {
  payerLobId: 1,
  payerAcctId: 1,
  payerLobName: 'LOB A',
  status: 'active',
  activeFromDt: '2020-01-01',
  benefitTypeCode: 'MEDICARE',
  contacts: [],
  addresses: [],
  orderReceiptMethodCode: null,
  ormEnabled: true,
  clmEnabled: true
};


export class MemberFormComponentMock {
  submit(): void { }
}

export class OrdersServiceMock {
  configuration = {
    basePath: '',
    accessToken: '',
    withCredentials: true
  };

  getOrderCreationSteps(): Observable<Array<OrderStep>> {
    return of([]);
  }

  getExpectedDeliverDate(): Observable<Date> {
    return of(new Date());
  }

  createOrder(payerLobId: number, memberId: number, order: Order): Observable<Order> {
    return of(orderDataMock);
  }

  public getNotesByOrderId(payerLobId: number, memberId: number, orderId: number): Observable<Array<OrderNote>> {
    return of([orderNotesMock]);
  }

  public createOrderNotes(payerLobId: number, memberId: number, orderId: number, note: OrderNote): Observable<OrderNote> {
    return of(orderNotesMock);
  }

  public updateOrderNotebyNoteId(payerLobId: number, memberId: number, orderId: number, note: OrderNote): Observable<OrderNote> {
    return of(orderNotesMock);
  }

  public updateOrder(payerLobId: number, memberId: number, orderId: number, order: Order): Observable<Order> {
    return of(orderDataMock);
  }

  public getAllOrders(search?: string, pageSize: number = 50, restartRowId: number = 0,
                      sort: GridSortDescriptor[] = []): Observable<HttpResponse<Array<OrderListEntry>>> {
    const response = new HttpResponse({ body: [] });
    return of(response);
  }

  public getOrdersByMbrID(
    payerLobId: number,
    memberId: number,
    sort?: GridSortDescriptor[],
    filter?: string,
    restartRowId?: number,
    pageSize?: number
  ): Observable<HttpResponse<Array<OrderListEntry>>> {
    const response = new HttpResponse({ body: [] });
    return of(response);
  }

  public getOrderById(payerLobId: number, memberId: number, orderId: number): Observable<Order> {
    return of();
  }

  public acceptOrders(orderId: number): Observable<Array<OrderStatusChg>> {
    return of([orderStatusInProgress]);
  }

  public pendingAcceptance(orderIds: Array<number>): Observable<Array<OrderStatusChg>> {
    return of([orderStatusPendingAcceptance]);
  }

  public rejectOrder(orderId: number, reasonCode: string, notes: string = ''): Observable<Array<OrderStatusChg>> {
    return of([orderStatusRejected]);
  }

  public closeOrder(orderId: number, reasonCode: string, notes: string = ''): Observable<Array<OrderStatusChg>> {
    return of([orderStatusClosed]);
  }

  public markOrderAsFutureDos(orderId: number, futureDosFormValue: FutureDosFormValue): Observable<Array<OrderStatusChg>> {
    return of([orderStatusFutureDos]);
  }

  public markOrderAsDelivered(orderId: number, deliveredFormValue: DeliveredFormValues): Observable<Array<OrderStatusChg>> {
    return of([orderStatusDelivered]);
  }

  public markOrderAsShipped(orderId: number, shippedFormValue: ShippedFormValues): Observable<Array<OrderStatusChg>> {
    return of([orderStatusShipped]);
  }

  public addTrackingInformation(orderId: number, shippedFormValue: ShippedFormValues): Observable<Array<OrderStatusChg>> {
    return of([orderStatusShipped]);
  }

  public deleteOrderAttachment(payerLobId: number, memberId: number, orderId: number, orderAttachId: number): Observable<any> {
    return of();
  }

  public createOrderAttachment(payerLobId: number, memberId: number, orderId: number, file: FileInfo): Observable<OrderAttach> {
    return of();
  }

  public createOrderAttachments(
    payerLobId: number, memberId: number, orderId: number, files: PrismFileInfo[]
  ): Observable<{ file: PrismFileInfo, attach?: OrderAttach, error?: Error }[]> {
    return of();
  }

  public getRecurrencesOrderList(payerLobId: number, memberId: number, orderId: number): Observable<Array<OrderListEntry>> {
    return of();
  }

  public markOrderAsPayerNAQ(
    orderId: number, needsAttentionDtls: NeedsAttentionDtls
  ): Observable<Array<OrderStatusChg>> {
    return of();
  }

  public markOrderAsProviderNAQ(
    orderId: number, needsAttentionDtls: NeedsAttentionDtls
  ): Observable<Array<OrderStatusChg>> {
    return of();
  }

  public markAsRequestCancellation(orderId: number, requestedCancelDtls: CancelDtls): Observable<Array<OrderStatusChg>> {
    return of();
  }

  public cancelOrder(orderId: number, cancelDtls: CancelDtls): Observable<Array<OrderStatusChg>> {
    return of();
  }

  public getOrderByIdWithAttachments(
    payerLobId: number, memberId: number, orderId: number
  ): Observable<{ order: Order, files: Array<FileInfo> }> {
    return of({ order: null, files: [] });
  }

  public orderProviderLookup(
    payerLobId: number,
    memberId: number,
    staAddress: OrderProviderLookupAddr,
    asOfDate: string,
    products: Array<OrderProviderLookupProduct> = [],
    ordAuthWindows: Array<OrdAuthWindowDt> = [],
  ): Observable<Array<FacilityDto>> {
    return of([]);
  }

  public saveCall(payerLobId: number, memberId: number, orderId: number, orderCall: OrderCall): Observable<OrderCall> {
    return of();
  }

  public createCallByOrderID(payerLobId: number, memberId: number, orderId: number, orderCall: OrderCall): Observable<OrderCall> {
    return of();
  }

  public updateOrderCallByID(
    payerLobId: number, memberId: number, orderId: number, callId: number, orderCall: OrderCall
  ): Observable<OrderCall> {
    return of();
  }

  public deleteOrderCallByID(payerLobId: number, memberId: number, orderId: number, callId: number): Observable<any> {
    return of();
  }

  public getCallsByOrderID(payerLobId: number, memberId: number, orderId: number): Observable<Array<OrderCall>> {
    return of();
  }

  public getPaginatedTransitions(restartRowId?: number, pageSize?: number, filter?: string): Observable<GridData<UITransition>> {
    return of();
  }
}

export class MemberInformationServiceMock {
  configuration = {
    basePath: '',
    accessToken: '',
    withCredentials: true
  };

  getMemberFullInformation(): Observable<MemberFullInformation> {
    return of(memberFullInformationMock);
  }

  updateMember(): Observable<MemberFullInformation> {
    return of(memberFullInformationMock);
  }

  getMemberInfo(payerId: string, memberId: string): Observable<MemberFullInformation> {
    return of(memberFullInformationMock);
  }

  public getMemberDataForCombobox = (filterStr: string): Observable<Array<SearchDataList>> => {
    return of([]);
  }

  public getMemberDataForComboboxPaging = (filterStr: string, pageSize: number, restartRowNumber: number): Observable<Array<SearchDataList>> => {
    return of([]);
  }
}

export class NotificationServiceMock {
  show(settings): any {
    return null;
  }

  public showErrorNotification(errorMessage): void {
  }

  public showSuccessNotification(successMessage): void {
  }
}

export class ListItemLookupValidationServiceMock {
  public configuration = {};
  validateListItemCode(listTypeName: string, listTypeCode: string, asOfDate: string): Observable<CodeLookupDtl> {
    return of({});
  }
}

export class ListItemManagementServiceMock {
  public configuration = {};
  public getListItems(listId: number, pageSize?: number, restartRowId?: number,
                      listCtxName?: string, activeAsOf?: string, observe?: 'response', reportProgress?: boolean,
                      options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<ListItemDtl>>> {
    const httpResponse = new HttpResponse({
      body: [{
        listDtlCode: 'ABC',
        listDtlDesc: 'ABC DESC',
        listDtlStartDt: '2020-01-01'
      }]
    });
    return of(httpResponse);
  }
  public getListItemsByName(listTypeName: string, sort?: string, filter?: string, restartRowId?: number,
                            pageSize?: number, listCtxName?: string, activeAsOf?: string, observe?: 'response',
                            reportProgress?: boolean,
                            options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<ListItemDtl>>> {
    return of(new HttpResponse(
      {
        body: [
          { listDtlCode: 'code a', listDtlDesc: 'desc a', listDtlStartDt: '2020-01-01' },
          { listDtlCode: 'code b', listDtlDesc: 'desc b', listDtlStartDt: '2020-01-01' }
        ]
      }
    ));
  }
}


export class ListManagementServiceMock {
  public configuration = {};
}

export class ProductManagementServiceMock {
  public configuration = {};

  public getProducts(sort?: string, filter?: string, restartRowId?: number,
                     pageSize?: number, observe?: 'response', reportProgress?: boolean,
                     options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<Product>>> {
    return of(new HttpResponse());
  }

}


export class OrganizationServiceMock {
  getOrganizationContractsByOrgId(
    orgId: number,
    pageSize: number,
    pageNumber: number
  ): Observable<OrganizationContractResponseModel> {
    return of({ list: [], count: '3' });
  }

  getOrganizationFeeScheduleByOrgId(
    orgId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<Array<OrgFeeScheduleViewModel>> {
    return of([{}]);
  }
  getProviderOrgForComboboxPaging(filter: string, pageNumber: number, pageSize: number): Observable<SearchDataList[]> {
    return of([]);
  }

  public getOrganizationLegalName(organizationId: number): Observable<string> {
    return of('abc');
  }

  public getStates(): Observable<Array<SearchDataList>> {
    return of([]);
  }

  updateKeynote(
    orgId: number,
    body: OrganizationLiteModel
  ): Observable<number> {
    return of(1);
  }

  public getOrganizationKeynoteByOrgId(
    orgId: number
  ): Observable<OrganizationLiteModel> {
    return of({});
  }

  public getFeeScheduleById(
    organizationId: string,
    id: number
  ): Observable<OrgFeeScheduleViewModel> {
    return of({});
  }

  public getAllQueFeeSchedules(): Observable<Array<SearchDataList>> {
    return of([]);
  }

  public getDirectContractLobsByOrganizationId(orgId: number): Observable<any> {
    return of();
  }
}

export const addressMock = {
  address1: 'new address 5',
  address2: 'new address 3',
  city: 'new city',
  zip: '13455',
  addressId: 2420,
  state: 'IL'
};

export const statesMock = [

  {
    id: 'IL',
    displayName: 'Illinois'
  },
  {
    id: 'IN',
    displayName: 'Indiana'
  }
];

export class OrganizationSectiontitleServiceMock {
  public title$ = new BehaviorSubject('abc');
  public latestTitile$ = this.title$.asObservable();

  setTitle(name: any) {
    return 'abc';
  }
}

export class FacilitiesServiceMock {
  public getFacilityListByOrganization() {
    return of();
  }

  public getFacilityById(facilityId: number): Observable<FacilityDto> {
    return of();
  }
}

export class StaffServiceMock {
  public GetLicenseByStaffId(orgID: number, staffID: number, pageNumber: number, pageSize: number,
                             filter: string): Observable<Array<LicenseListViewModel>> {
    return of([]);
  }

  public getLicenseByStaffId(orgID: number, staffID: number, pageNumber: number, pageSize: number,
                             filter: string): Observable<ResponseModelGeneric<LicenseListViewModel>> {
    return of({ list: [], count: '0' });
  }
  getStaffForCombobox(organizationId: number, pageNumber: number, pageSize: number, filter: string): Observable<SearchDataList[]> {
    return of([]);
  }

  public getStaffDetailsById(orgId: number, staffId: number): Observable<StaffProfileViewModel> {
    return of({});
  }
  public getStaffList(orgId: string | undefined, skip: number, take: number, filterString: string): Observable<StaffResponse> {
    return of({});
  }
  public saveStaff(orgId: number, body: any): Observable<StaffProfileViewModel> {
    return of(staffMockById);
  }
  public getAllStaffRoles(): Observable<Array<StaffRolesViewModel>> {
    return of([
      {
        desc: 'Order manager',
        code: 'OM',
        isActive: true,
        group: 'PRM'
      }
    ]);
  }
  public getStaffRolesFacilitiesById(orgId: number, staffId: number): Observable<StaffRolesFacilityViewModel> {
    return of({
      staffRoles: [],
      billingFacilies: [],
      omFacilities: []
    });
  }
}

export const organizationsDropDownMock = [
  {
    id: '5',
    displayName: 'ABCDs Legal Pharmacy1',
    displaySelected: 'ABCDs Legal Pharmacy1'
  },
  {
    id: '7',
    displayName: 'Legal Name - 43',
    displaySelected: 'Legal Name - 43'
  },
  {
    id: '11',
    displayName: 'Dont Touch Sachin Integration',
    displaySelected: 'Dont Touch Sachin Integration'
  },
  {
    id: '12',
    displayName: 'Et Commodo Limited',
    displaySelected: 'Et Commodo Limited'
  },
  {
    id: '13',
    displayName: 'Ut LLP12',
    displaySelected: 'Ut LLP12'
  },
  {
    id: '14',
    displayName: 'Est Inc.',
    displaySelected: 'Est Inc.'
  },
  {
    id: '15',
    displayName: 'Malesuada Id Erat LLP',
    displaySelected: 'Malesuada Id Erat LLP'
  },
  {
    id: '16',
    displayName: 'Feugiat Limited',
    displaySelected: 'Feugiat Limited'
  },
  {
    id: '17',
    displayName: 'Commodo Auctor Velit Incorporated',
    displaySelected: 'Commodo Auctor Velit Incorporated'
  },
  {
    id: '18',
    displayName: 'Arcu Ac Orci Inc.',
    displaySelected: 'Arcu Ac Orci Inc.'
  },
  {
    id: '19',
    displayName: 'Ultrices A Auctor Corporation',
    displaySelected: 'Ultrices A Auctor Corporation'
  },
  {
    id: '20',
    displayName: 'Amet Ante Vivamus Associates',
    displaySelected: 'Amet Ante Vivamus Associates'
  },
  {
    id: '21',
    displayName: 'Risus A Ultricie\'s Corporation',
    displaySelected: 'Risus A Ultricie\'s Corporation'
  },
  {
    id: '22',
    displayName: 'Non Ante Bibendum Company',
    displaySelected: 'Non Ante Bibendum Company'
  },
  {
    id: '23',
    displayName: 'Mauris Company',
    displaySelected: 'Mauris Company'
  },
  {
    id: '24',
    displayName: 'Pharetra Sed Ltd',
    displaySelected: 'Pharetra Sed Ltd'
  },
  {
    id: '25',
    displayName: 'Eu Neque Pellentesque Foundation',
    displaySelected: 'Eu Neque Pellentesque Foundation'
  },
  {
    id: '26',
    displayName: 'Aenean Euismod Mauris Foundation Mauris Foundation',
    displaySelected: 'Aenean Euismod Mauris Foundation Mauris Foundation'
  },
  {
    id: '27',
    displayName: 'Porttitor Scelerisque Incorporated',
    displaySelected: 'Porttitor Scelerisque Incorporated'
  },
  {
    id: '28',
    displayName: 'Sed Pede Corp.',
    displaySelected: 'Sed Pede Corp.'
  },
  {
    id: '29',
    displayName: 'Nullam Vitae Diam LLP',
    displaySelected: 'Nullam Vitae Diam LLP'
  },
  {
    id: '30',
    displayName: 'Aenean Euismod Mauris Inc.',
    displaySelected: 'Aenean Euismod Mauris Inc.'
  },
  {
    id: '31',
    displayName: 'Morbi LLC',
    displaySelected: 'Morbi LLC'
  },
  {
    id: '32',
    displayName: 'Ac Sem Ut Consulting',
    displaySelected: 'Ac Sem Ut Consulting'
  },
  {
    id: '33',
    displayName: 'Sociis Natoque Penatibus Company',
    displaySelected: 'Sociis Natoque Penatibus Company'
  }
];

export const staffMockById: StaffProfileViewModel = {
  staffId: 11,
  appUserId: null,
  organizationId: 5,
  organizationLegalName: 'ABCDs Legal Pharmacy1',
  firstName: 'David',
  middleName: 'aaah',
  lastName: 'Sykes',
  phone: '',
  suffix: 'SR',
  extension: null,
  title: 'jaa',
  isActive: false,
  ssn: '123456789',
  email: 'id.enim.Curabitur@Maecenasliberoest.couk',
  isOwner: true,
  isOfficer: null,
  sfRecordId: null,
  queId: null,
  queLoginId: null,
  ownershipPercentage: 1,
  action: null,
  sentEmailInvitation: false,
  providerFlow: false,
  status: null,
  statusName: null,
  legacyOrganizationId: null,
  legacyOrganizationSFRecordId: null,
  fullName: 'David Sykes',
  totalRecords: 0,
  createdDate: '2019-03-12T21:12:29.787',
  createdBy: 1,
  lastModifiedDate: '2023-04-19T14:07:42.03',
  lastModifiedBy: 2,
  isNew: false,
  isDeleted: false,
  isChanged: false,
  buttonName: null,
  viewMode: null,
  reqSource: null,
  appId: 0,
  invokedFrom: 'none',
  newFacilityId: 0
};

export class ProvidersServiceMock {
  getFacilities(matchOrgName: string = null): Observable<Array<FacilityDto>> {
    return of([]);
  }
  getFacilityById(facilityId: number): Observable<FacilityDto> {
    return of();
  }
  public getFacilitiesForCombobox = (filterStr: string): Observable<Array<SearchDataList>> => {
    return of([]);
  }
  public getFacilitiesForComboboxPaging = (filterStr: string, pageNumber: number, pageSize: number): Observable<Array<SearchDataList>> => {
    return of([]);
  }
  public getProviderOrgForCombobox = (filterStr: string): Observable<Array<SearchDataList>> => {
    return of([]);
  }
  public getProviderOrgForComboboxPaging = (filterStr: string, pageNumber: number, pageSize: number): Observable<Array<SearchDataList>> => {
    return of([]);
  }
  public getFacilitiesSearchData(filterStr: string, type: string): Observable<Array<SearchDataList>> {
    return of([]);
  }
  public getProviderOrgByIdForCombobox(organizationId: number): Observable<SearchDataList> {
    return of();
  }
  public getProviderOrgsByIds(orgIds: Array<number>): Observable<Array<SearchDataList>> {
    return of();
  }
  public getProviderFacsByIds(orgIds: Array<number>): Observable<Array<SearchDataList>> {
    return of();
  }
  public getFacilityByIdForCombobox(facilityId: number): Observable<SearchDataList> {
    return of();
  }
} export class GeneralSettingsServiceMock {
  getGeneralSettingByKey(settingKey: string): Observable<any> {
    return of();
  }
}

export class MdmServiceMock {
  getItemList(): Observable<EntryItemResponse> {
    return of({ items: [], restartRowNum: '0' });
  }

  getListItemByCode(listTypeName: string, listTypeCode: string, asOfDate?: Date): Observable<ListItemLookup> {
    return of({
      listTypeName: '',
      listTypeCode: '',
      listTypeDesc: '',
      attributes: new Map<string, string>(),
    });
  }

  getCachedMdmDataList(): Observable<any> {
    return of([
      { listDtlCode: 'ENG', listDtlDesc: 'English' } as ListItemDtl,
      { listDtlCode: 'BEN', listDtlDesc: 'Bengali' } as ListItemDtl,
      { listDtlCode: 'SHIPPING', listDtlDesc: 'Shipping address' } as ListItemDtl,
      { listDtlCode: 'phone', listDtlDesc: 'phone' } as ListItemDtl,
      { listDtlCode: 'HOME_PHONE', listDtlDesc: 'HOME_PHONE', attributes: [{ listAttrName: attribCanBePrimary }] } as ListItemDtl,
      { listDtlCode: 'WORK_PHONE', listDtlDesc: 'WORK_PHONE', attributes: [{ listAttrName: attribCanBePrimary }] } as ListItemDtl
    ]);
  }

  getCachedMdmDataListPaging(): Observable<any> {
    return of([
      { listDtlCode: 'ENG', listDtlDesc: 'English' } as ListItemDtl,
      { listDtlCode: 'BEN', listDtlDesc: 'Bengali' } as ListItemDtl,
      { listDtlCode: 'SHIPPING', listDtlDesc: 'Shipping address' } as ListItemDtl,
      { listDtlCode: 'phone', listDtlDesc: 'phone' } as ListItemDtl,
      { listDtlCode: 'HOME_PHONE', listDtlDesc: 'HOME_PHONE', attributes: [{ listAttrName: attribCanBePrimary }] } as ListItemDtl,
      { listDtlCode: 'WORK_PHONE', listDtlDesc: 'WORK_PHONE', attributes: [{ listAttrName: attribCanBePrimary }] } as ListItemDtl
    ]);
  }

  getMdmDataList(): Observable<EntryItemResponse> {
    return of({ items: [], restartRowNum: '' });
  }

  getProductList(): Observable<HttpResponse<Product[]>> {
    const response = new HttpResponse({ body: [] });
    return of(response);
  }

  codeToDesc(code: string): string {
    return code;
  }

  public lookupNPIv2(npiNumber: string): Observable<NPIv2> {
    return of(null);
  }

  public getHcpcDataForCombobox(filterStr: string): Observable<Array<SearchDataList>> {
    return of(null);
  }

  public getProductListDataForCombobox(filterStr: string): Observable<Array<SearchDataList>> {
    return of(null);
  }

  public getProductListDataForComboboxPaging(filterStr: string, restartRowId: number, pageSize: number): Observable<Array<SearchDataList>> {
    return of(null);
  }

  public getSingleHcpcForCombobox(hcpcCode: string): Observable<SearchDataList> {
    return of(null);
  }

  public getHcpcListDataForComboboxPaging(filterStr: string, restartRowId: number, pageSize: number): Observable<Array<SearchDataList>> {
    return of([]);
  }

  public getHcpcCatgsByType(catgCodes: Array<string>, catgType: string = 'PRISM', restartRowId: number = 0,
                            pageSize: number = 100): Observable<Array<HcpcCategory>> {
    return of([]);
  }

  public getCountiesByStateCodeForCombobox(stateCode: string): Observable<Array<SearchDataList>> {
    return of([]);
  }

  public getProductCategoriesListDataForComboboxPaging(filterStr: string, restartRowId: number, pageSize: number): Observable<Array<SearchDataList>> {
    return of([]);
  }

  public getProductCategoriesListDataForCombobox(filterStr: string): Observable<Array<SearchDataList>> {
    return of([]);
  }

  getLists(pageSize: number = 50, pageNumber: number = 0): Observable<ListItem[]> {
    return of([]);
  }

  getListById(listId: number): Observable<ListItem> {
    return of();
  }
  getHcpcCatgsByTypeAndName(): Observable<Array<HcpcCategory>> {
    return of();
  }

  public getListCodesForCombobox(filterStr: string): Observable<Array<SearchDataList>> {
    return of(null);
  }

  getListCodesForComboboxPaging(listType: string, filterStr: string, restartRowId: number, pageSize: number): Observable<SearchDataList[]> {
    return of();
  }

  getListCodeForCombobox(listType: string, entityId: string): Observable<SearchDataList> {
    return of();
  }

  getSingleProductListDataForCombobox(hcpcCode: string): Observable<SearchDataList> {
    return of();
  }

  saveListItem(listId: number, listItem: ListItemDtl): Observable<any> {
    return of();
  }

  getListItemByID(listId: number, listItemId: number): Observable<ListItemDtl> {
    return of();
  }

  public getCodesByName(listType: string, activeAsOfDate: Date, codeNames?: Array<string>): Observable<ListItemDtl[]> {
    return of();
  }
}

export class PayerServiceMock {

  payerSearch(sort, searchString, skip, take): Observable<any[]> {
    return of([]);
  }

  createNewPayerFamily(): Observable<any> {
    return of({});
  }

  updatePayerFamily(payerFamilyId: number, body: PayerFamily): Observable<any> {
    return of({});
  }

  createNewPayerAccount(payerFamilyId: number, body: PayerAccount): Observable<any> {
    return of({
      payerAcctId: 12
    });
  }

  updatePayerAccount(payerFamilyId: number, payerAccountId: number, body: PayerAccount): Observable<any> {
    return of({
      payerAcctId: 12
    });
  }

  getPayersFilters(): Observable<any[]> {
    return of([]);
  }

  getPayersFamilyList(sort: string, search?: string, skip: number = 0, take: number = 200): Observable<any[]> {
    return of([]);
  }

  getPayerAccountsList(familyId: number, sort: string, pageSize: number = 200): Observable<PayerAccountList[]> {
    return of([
      {
        payerAcctId: 1,
        payerFamId: 1,
        payerAcctName: 'Test Account',
        activeFromDt: '2020-01-01',
        acctStatusCode: 'active',
        integraMarketShare: 10,
        totalMembership: 10,
        revenue: 10,
        accessToPlatform: 1
      },
      {
        payerAcctId: 2,
        payerFamId: 1,
        payerAcctName: 'Test Account 2',
        activeFromDt: '2020-01-01',
        acctStatusCode: 'active',
        integraMarketShare: 10,
        totalMembership: 10,
        revenue: 10,
        accessToPlatform: 1
      }
    ]);
  }

  getPayersLobList(familyId: number, accountId: number, sort: string, pageSize: number = 200): Observable<Array<PayerLobList>> {
    return of([
      {
        payerLobId: 1,
        payerFamId: 1,
        payerAcctId: 1,
        payerLobName: 'LOB A',
        lobStatusCode: 'active',
        activeFromDt: '2020-01-01',
        lobCode: 'MEDICARE'
      },
      {
        payerLobId: 2,
        payerFamId: 1,
        payerAcctId: 1,
        payerLobName: 'LOB B',
        lobStatusCode: 'active',
        activeFromDt: '2020-01-01',
        lobCode: 'MEDICAID'
      }
    ]);
  }

  getPayerLobById(payerLobId: number): Observable<PayerLob> {
    return of(null);
  }

  getPayerFamilyById(payerFamilyId: number): Observable<any> {
    return of({});
  }

  getPayerAccountById(payerFamId: number, payerAcctId: number): Observable<any> {
    return of({});
  }

  payerFamilyDtoToForm(payerFamily: PayerFamily): PayersFormValues {
    return null;
  }

  parsePayerAcctsFormVals(values: PayerAcctsFormVals): PayerAccount {
    return null;
  }

  parsePayerAcctDtoToForm(payerAcct: PayerAccount): PayerAcctsFormVals {
    return null;
  }

  public createPayerLob(payerFamId: number, payerAcctId: number, body: PayerLob): Observable<PayerLob> {
    return of({
      ...body
    });
  }

  public updatePayerLob(payerFamId: number, payerAcctId: number, body: PayerLob): Observable<PayerLob> {
    return of({
      ...body
    });
  }

  public getPayerFamilyChangesByID(payerFamId: number): Observable<Array<Change>> {
    return of([]);
  }

  public getPayerAccountChangesByID(payerFamId: number, payerAcctId: number): Observable<Array<Change>> {
    return of([]);
  }

  public getPayerLobChangesByID(payerLobId: number, payerFamId: number, payerAcctId: number): Observable<Array<Change>> {
    return of([]);
  }

  public getPayerPlanByPlanID(payerPlanId: number): Observable<PayerPlanDetails> {
    return of();
  }

  public getPayerPlans(
    payerFamId: number, sort?: string, filter?: string, restartRowId?: number, pageSize?: number
  ): Observable<Array<PayerPlanList>> {
    return of();
  }
}

export class PayerLOBServiceMock {
  configuration = {
    basePath: '',
    accessToken: '',
    withCredentials: true
  };

  getPayerLobs(a, b, c): Observable<any> {
    return of(lobListMock);
  }

  getPayerLobByID(payerLobId: number, payerFamId: number, payerAcctId: number): Observable<any> {
    return of([]);
  }

  getPayerLobByLobID(payerFamId: number): Observable<any> {
    return of([]);
  }
}

export class EdiBatchServiceMock {
  public getBatchById(id): Observable<EDIBatch> {
    return of();
  }
  public getListOfBatches(): Observable<HttpResponse<Array<EDIBatch>>> {
    const response = new HttpResponse({ body: [] });
    return of(response);
  }
}

export class EdiBatchChunkServiceMock {
  public getListOfChunks(batchId: string, pageSize: number = 50,
                         restartRowId: number = 0): Observable<HttpResponse<Array<EDIBatchChunkList>>> {
    const response = new HttpResponse({ body: [] });
    return of(response);
  }

  public getChunkById(batchId: string, chunkId: string): Observable<EDIBatchChunk> {
    return of();
  }

  public getChunkFile(batchId: string, chunkId: string, chunkFileType: 'raw' | 'report'): Observable<any> {
    return of();
  }
}

export class TxnTypeServiceMock {
  getTxnTypes(sort?: string, restartRowId: number = 0, filter?: ApiFilterRequest[], pageSize: number = 20): Observable<Array<TxnType>> {
    return of([]);
  }
}

export class EdiBreadcrumbServiceMock {
  getItems(ediBatchId: string = null, ediBatchChunkId: string = null): BreadCrumbItemI[] {
    return [];
  }
}

export class EDIBatchesServiceMock {
  configuration = {
    basePath: '',
    accessToken: '',
    withCredentials: true
  };

  getListOfBatches(sort?: string, restartRowId?: number, pageSize?: number,
                   filter?: string, observe?: 'response', reportProgress?: boolean,
                   options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<EDIBatch>>> {
    const response = new HttpResponse({ body: [] });
    return of(response);
  }
}

export class TransactionTypesServiceMock {
  configuration = {
    basePath: '',
    accessToken: '',
    withCredentials: true
  };

  getTxnTypes(sort?: string, restartRowId?: number, pageSize?: number,
              filter?: string, observe?: 'body', reportProgress?: boolean,
              options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<TxnType>> {
    return of([]);
  }
}

export class EDIBatchChunksServiceMock {
  configuration = {
    basePath: '',
    accessToken: '',
    withCredentials: true
  };

  getListOfBatchChunks(ediBatchId: string, sort?: string, restartRowId?: number, pageSize?: number,
                       filter?: string, observe?: 'response', reportProgress?: boolean,
                       options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<EDIBatchChunkList>>> {
    const response = new HttpResponse({ body: [] });
    return of(response);
  }

  getBatchChunkByID(ediBatchId: string, ediBatchChunkId: string, observe?: 'body', reportProgress?: boolean,
                    options?: { httpHeaderAccept?: 'application/json' }): Observable<EDIBatchChunk> {
    return of();
  }
  getBatchChunkFileByType(ediBatchId: string, ediBatchChunkId: string, chunkFileType: 'raw' | 'report',
                          observe?: 'body', reportProgress?: boolean,
                          options?: { httpHeaderAccept?: 'text/html' | 'text/plain' | 'application/json' }): Observable<any> {
    return of();
  }
}

export class PayerAccountsServiceMock {
  configuration = {
    basePath: '',
    accessToken: '',
    withCredentials: true
  };

  getPayerAccounts(payerFamId: number, pageSize?: number, restartRowId?: number): Observable<Array<PayerAccountList>> {
    return of([]);
  }

  getPayerFamilyAccounts(payerFamId: number, restartRowId?: number, pageSize?: number): Observable<PayerAccountList[]> {
    return of([]);
  }

  getPayerFamilyById(payerFamilyId: number): Observable<PayerFamily> {
    return of();
  }

  getPayerLobById(payerLobId: number): Observable<PayerLob> {
    return of();
  }

  getPayerAccountById(payerFamilyId: number, payerAcctId: number): Observable<PayerAccount> {
    return of();
  }
}

export class PayerFamilyServiceMock {
  configuration = {
    basePath: '',
    accessToken: '',
    withCredentials: true
  };

  createPayerFamily(): Observable<PayerFamily> {
    return of();
  }

  getPayerFamilies(): Observable<Array<PayerFamilyList>> {
    return of([]);
  }
}

export class ListPayerMembersServiceMock {
  configuration = {
    basePath: '',
    accessToken: '',
    withCredentials: true
  };
  getMembersList(): Observable<HttpResponse<MemberGrouped[]>> {
    const response = new HttpResponse({ body: [] });
    return of(response);
  }

  getPayerMembers(): Observable<any> {
    return of([]);
  }

  getMemberInfo(): Observable<any> {
    return of([]);
  }

  createNewMember(): Observable<any> {
    return of([]);
  }

  editMember(): Observable<any> {
    return of({});
  }
}

export class MembersServiceMock {
  public getMemberDataForComboboxPaging(filterStr: string, pageSize: number, restartRowNum: number): Observable<Array<SearchDataList>> {
    return of([]);
  }
}

export class RouterMock {
  navigateByUrl(): void { }
  navigate(route: string[]): void { }
}

export class SettingServiceMock {
  public basePath = '';

  public getVersionRefreshInterval(): number {
    return 60;
  }
}

export class RolesServiceMock {
  public configuration = {};

  public getRoles(): Observable<RoleItemList[]> {
    return of([]);
  }

  public getRolesByUser(payerFamId: number, userId: number): Observable<Array<RoleItem>> {
    return of([]);
  }

  public updateUserRoles(payerFamId: number, userId: number, roleItem: Array<RoleItem>): Observable<Array<RoleItem>> {
    return of([]);
  }

  public updateRolesByUser(userRoleType: UserRoleType): Observable<RoleItemList[]> {
    return of([]);
  }

  public mapUserRoleType(apiConfig: any): UserRoleType {
    return {
      userTypeCode: 'PAYER',
      userId: apiConfig.userId
    } as UserRoleType;
  }

  getDataList(listTypeName: string): Observable<ListItemDtl[]> {
    return of([]);
  }
}

export class UserPayerAccountsServiceMock {
  getPayerAcctsByUser(payerFamId: number, userId: number): Observable<Array<PayerAcctId>> {
    return of([]);
  }
}

export const menuItemListMock: MenuItem[] = [{
  text: 'Administration',
  icon: 'k-i-gear',
  expanded: false,
  children: true,
  selected: false,
  level: 0,
  items: [
    {
      text: 'Master Data Management',
      path: '/admin/mdm/',
      children: false,
      selected: false,
      level: 1
    }
  ]
},
{
  text: 'Members',
  icon: 'k-i-user',
  expanded: false,
  children: true,
  selected: false,
  level: 0,
  items: [
    {
      text: 'View Members',
      path: '/payers/members/list',
      children: false,
      selected: false,
      level: 1
    }
  ]
}];

export const mdmListsMock = [
  {
    listTypeId: 0,
    listTypeName: 'APIType',
    listTypeDesc: 'Moon Gazing',
    listTypeStartDt: '2010-08-24T00:00:00Z',
    isSysList: true,
    isRoList: false
  },
  {
    listTypeId: 1,
    listTypeName: 'APIType',
    listTypeDesc: 'Moon Gazing',
    listTypeStartDt: '2010-08-24T00:00:00Z',
    isSysList: true,
    isRoList: false
  },
  {
    listTypeId: 2,
    listTypeName: 'APIType',
    listTypeDesc: 'Moon Gazing',
    listTypeStartDt: '2010-08-24T00:00:00Z',
    isSysList: true,
    isRoList: false
  },
  {
    listTypeId: 3,
    listTypeName: 'Something',
    listTypeDesc: 'Moon Gazing',
    listTypeStartDt: '2010-08-24T00:00:00Z',
    isSysList: true,
    isRoList: false
  },
  {
    listTypeId: 4,
    listTypeName: 'otherthing',
    listTypeDesc: 'Moon Gazing',
    listTypeStartDt: '2010-08-24T00:00:00Z',
    isSysList: true,
    isRoList: false
  },
  {
    listTypeId: 5,
    listTypeName: 'Anotherthing',
    listTypeDesc: 'Moon Gazing',
    listTypeStartDt: '2010-08-24T00:00:00Z',
    isSysList: true,
    isRoList: false
  },
  {
    listTypeId: 6,
    listTypeName: 'Therefore',
    listTypeDesc: 'Moon Gazing',
    listTypeStartDt: '2010-08-24T00:00:00Z',
    isSysList: true,
    isRoList: false
  }
];

export const membersFiltersMock = [
  {
    type: 'all',
    text: 'All',
    value: true,
    items: [
      {
        type: 'category',
        text: 'Wellcare',
        payerFamilyId: 1,
        value: true,
        items: [
          {
            type: 'sub',
            text: 'Ohana Plan',
            payerFamilyId: 1,
            payerId: 59,
            value: true,
            items: [
              {
                type: 'checkbox',
                text: 'Medicaid',
                payerFamilyId: 1,
                payerId: 60,
                value: false
              },
              {
                type: 'checkbox',
                text: 'Medicare',
                payerFamilyId: 1,
                payerId: 61,
                value: false
              }
            ]
          },
          {
            type: 'sub',
            text: 'Wellcare NJ',
            payerFamilyId: 1,
            payerId: 62,
            value: true,
            items: [
              {
                type: 'checkbox',
                text: 'Medicaid',
                payerFamilyId: 1,
                payerId: 63,
                value: false
              },
              {
                type: 'checkbox',
                text: 'Medicare',
                payerFamilyId: 1,
                payerId: 64,
                value: false
              }
            ]
          },
          {
            type: 'sub',
            text: 'Wellcare NJ',
            value: true,
            payerFamilyId: 1,
            payerId: 65,
            items: [
              {
                type: 'checkbox',
                text: 'Medicaid',
                payerFamilyId: 1,
                payerId: 66,
                value: false
              },
              {
                type: 'checkbox',
                text: 'Medicare',
                payerFamilyId: 1,
                payerId: 67,
                value: false
              }
            ]
          }
        ]
      }
    ]
  },
  {
    type: 'category',
    text: 'Oscar',
    payerFamilyId: 1,
    payerId: 68,
    value: true,
    items: [
      {
        type: 'checkbox',
        text: 'Oscar Commercial LOB',
        payerFamilyId: 1,
        payerId: 69,
        value: false
      }
    ]
  }
];

export const membersListMock = [
  { medicaidId: '1234413', firstName: 'Jhon', lastName: 'Doe', dob: '2020-08-24T00:00:00Z', primaryContact: '202-555-0149' },
  { medicaidId: '1234413', firstName: 'Jhon', lastName: 'Doe', dob: '2020-08-24T00:00:00Z', primaryContact: '202-555-0149' },
  { medicaidId: '1234413', firstName: 'Jhon', lastName: 'Doe', dob: '2020-08-24T00:00:00Z', primaryContact: '202-555-0149' },
  { medicaidId: '1234413', firstName: 'Jhon', lastName: 'Doe', dob: '2020-08-24T00:00:00Z', primaryContact: '202-555-0149' },
  { medicaidId: '1234413', firstName: 'Jhon', lastName: 'Doe', dob: '2020-08-24T00:00:00Z', primaryContact: '202-555-0149' },
  { medicaidId: '1234413', firstName: 'Jhon', lastName: 'Doe', dob: '2020-08-24T00:00:00Z', primaryContact: '202-555-0149' },
];

export const membersColumnsMocksNew = [
  { field: 'insuranceNum', title: 'INSURANCE ID', widthStyle: { width: '13%' }, type: 'text' },
  { field: 'payerFamilyName', title: 'PAYER FAMILY', widthStyle: { width: '8%' }, type: 'text' },
  { field: 'payerPlanName', title: 'PAYER PLAN', widthStyle: { width: '8%' }, type: 'text' },
  { field: 'payerAccountName', title: 'PAYER ACCOUNT', widthStyle: { width: '8%' }, type: 'text' },
  { field: 'payerLobName', title: 'LOB NAME', widthStyle: { width: '8%' }, type: 'text' },
  { field: 'firstName', title: 'FIRST NAME', widthStyle: { width: '13%' }, type: 'text' },
  { field: 'lastName', title: 'LAST NAME', widthStyle: { width: '13%' }, type: 'text' },
  { field: 'dob', title: 'DATE OF BIRTH', widthStyle: { width: '150px' }, type: 'text' },
  { field: 'primaryContact', title: 'PRIMARY PHONE', widthStyle: { width: '150px' }, type: 'phone' },
  { field: 'status', title: 'STATUS', widthStyle: { width: '115px' }, type: 'pill' }
];

export const membersColumnsMocks = [
  { field: 'insuranceNum', title: 'INSURANCE ID', widthStyle: { width: '13%' }, type: 'text' },
  { field: 'payerLobName', title: 'LOB NAME', widthStyle: { width: '17%' }, type: 'text' },
  { field: 'firstName', title: 'FIRST NAME', widthStyle: { width: '15%' }, type: 'text' },
  { field: 'lastName', title: 'LAST NAME', widthStyle: { width: '15%' }, type: 'text' },
  { field: 'dob', title: 'DATE OF BIRTH', widthStyle: { width: '12%' }, type: 'text' },
  { field: 'primaryContact', title: 'PRIMARY PHONE', widthStyle: { width: '15%' }, type: 'phone' },
];

export const orderNotesMock: OrderNote = {
  noteId: 1,
  orderNote: '<p>This is a test note</p>',
  createdBy: 'ABC',
  createdTs: '2021-01-14T03:08:54.354089Z',
  modifiedBy: 'ABC',
  modifiedTs: '2021-01-14T03:08:54.354089Z',
  orderId: 1,
  memberId: 1,
  orderProviderId: 1,
  payerLobId: 1,
  isInternal: false
};

// noinspection AngularMissingOrInvalidDeclarationInModule
@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlMockPipe implements PipeTransform {
  public transform(html: string): string {
    return html;
  }
}

export const orderListEntryMock: OrderListEntry = {
  orderId: 1,
  payerLobId: 1,
  mbrId: 1,
  payerLobName: 'Test Lob',
  authCodes: [
    'auth123'
  ],
  addtnlCmnt: '',
  orderProvider: {
    assignedTs: '2020-12-22',
    orderId: 195,
    orderProviderId: 512,
    providerFacilityId: 1203,
    providerFacilityName: 'fac last',
    providerOrgDBAName: '1003 orga DBA',
    providerOrgId: 512,
    providerOrgLegalName: '1003 orga legal2',
    selectionMode: 'LOOKUP',
  },
  authWinFromDt: '2020-12-21',
  authWinToDt: '2023-12-21',
  createdBy: '50a52de5-6b12-4bd4-86a6-ced028575907',
  createdTs: '2020-12-22T14:09:18.112574Z',
  isMedicarePrimOrd: false,
  isTransitionOrder: false,
  mbrHasRef: true,
  expOrderDeliveryTS: '2020-12-22T14:09:17.859412Z',
  orderDlvInstr: 'DLV INST 108',
  orderNum: '1608664157',
  orderStatusCode: 'RECURRING',
  staCity: 'Troy',
  staIndvAddressID: 247,
  staLine1: '3221 W. Big Beaver Rd.',
  staLine2: 'Ste. 211',
  staState: 'MI',
  staZip: '48084'
};

export const orderStatusMockData: ListItemDtl[] = [{
  listDtlCode: 'PENDING_ACCEPTANCE',
  listDtlDesc: 'Pending Acceptance',
  listDtlStartDt: '',
  listTypeId: 1,
  listDtlId: 1,
  attributes: [
    {
      listAttrId: 1,
      listDtlAttrId: 1,
      listAttrName: 'abc',
      listDtlAttrValue: 'ABC'
    },
    {
      listAttrId: 77,
      listDtlAttrId: 1,
      listAttrName: 'btn_label_group',
      listDtlAttrValue: 'Pending Acceptance'
    },
    {
      listAttrId: 78,
      listDtlAttrId: 1,
      listAttrName: 'btn_icon_class',
      listDtlAttrValue: 'clock'
    },
    {
      listAttrId: 79,
      listDtlAttrId: 1,
      listDtlAttrValue: '1',
      listAttrName: 'btn_display_order'
    }
  ]
}, {
  listDtlCode: 'PENDING_INTEGRA_REVIEW',
  listDtlDesc: 'Pending Integra Review',
  listDtlStartDt: '',
  listTypeId: 1,
  listDtlId: 1,
  attributes: [
    {
      listAttrId: 1,
      listDtlAttrId: 1,
      listAttrName: 'abc',
      listDtlAttrValue: 'ABC'
    },
    {
      listAttrId: 77,
      listDtlAttrId: 1,
      listAttrName: 'btn_label_group',
      listDtlAttrValue: 'Pending Acceptance'
    },
    {
      listAttrId: 78,
      listDtlAttrId: 1,
      listAttrName: 'btn_icon_class',
      listDtlAttrValue: 'clock'
    },
    {
      listAttrId: 79,
      listDtlAttrId: 1,
      listDtlAttrValue: '1',
      listAttrName: 'btn_display_order'
    }
  ]
}];

export class PayersServiceMock {
  getPayerFamilyById(payerFamilyId: number): Observable<PayerFamily> {
    return of(PayerFamDataMock);
  }

  public getPayerFamilyDataForCombobox = (filterStr: string): Observable<Array<SearchDataList>> => {
    return of([]);
  }

  public getPayerDataForComboboxPaging = (filterStr: string, restartRowNum: number,
                                          pageSize: number): Observable<Array<SearchDataList>> => {
    return of([]);
  }

  public getPayerFamilyDataForPagingCombobox = (filterStr: string): Observable<Array<SearchDataList>> => {
    return of([]);
  }

  public getPayerAccountDataForCombobox = (filterStr: string): Observable<Array<SearchDataList>> => {
    return of([]);
  }

  public getPayerDataForCombobox = (filterStr: string): Observable<Array<SearchDataList>> => {
    return of([]);
  }

  payerSearch(sort?: string, filter?: string, restartRowId: number = 0, pageSize: number = 200): Observable<Array<any>> {
    return of([]);
  }

  getDataListForLookup(listTypeName: string, apiConfig: any, filter: string): Observable<ListItemDtl[]> {
    return of([]);
  }

  getPayerFamilyAccountsList(familyId: number, pageSize: number = 200): Observable<PayerAccountList[]> {
    return of([]);
  }

  getPayerAccountsList(familyId: number, sort: string, pageSize: number = 200): Observable<PayerAccountList[]> {
    return of([]);
  }

  getPayerFamilyByIdForCombobox(payerFamilyId: number): Observable<SearchDataList> {
    return of();
  }

  getPayerAccountByIdForCombobox(payerAccountId: number): Observable<SearchDataList> {
    return of();
  }

  getPayerLobByIdForCombobox(payerLobId: number): Observable<SearchDataList> {
    return of();
  }

  getAllPayerPlanDataForCombobox(payerPlanIDs: string[]): Observable<Array<SearchDataList>> {
    return of();
  }

  getAllPayerLobDataForCombobox(payerLobIDs: string[]): Observable<Array<SearchDataList>> {
    return of();
  }

  getPayerPlanDataForComboboxPaging(filterStr: string, restartRowNum: number, pageSize: number,
                                    familyId: number): Observable<Array<SearchDataList>> {
    return of([]);
  }

  getPayerAccountDataForComboboxPaging(filterStr: string, restartRowNum: number, pageSize: number,
                                       familyId: number, planId: number): Observable<Array<SearchDataList>> {
    return of([]);
  }

  getPayerLobDataForComboboxPaging(filterStr: string, restartRowNum: number, pageSize: number, familyId: number,
                                   planId: number, accountId: number): Observable<Array<SearchDataList>> {
    return of([]);
  }

  getPayerPlanByIdForCombobox(entityId: string): Observable<SearchDataList> {
    return of();
  }

  getPayersFamilyList(
    sort: string,
    filter?: string,
    restartRowNum: number = 0,
    pageSize: number = 200
  ): Observable<PayerFamilyList[]> {
    return of([]);
  }

  getPayersPlanList(
    payerFamId: number,
    sort: string,
    searchString: string = null,
    restartRowNum: number = 0,
    pageSize: number = 200
  ): Observable<PayerPlanList[]> {
    return of([]);
  }

  getPayersLobList(
    familyId: number, payerPlanId: number, accountId: number, sort?: string, pageSize: number = 200
  ): Observable<Array<PayerLobList>> {
    return of([]);
  }

  getPayerLobById(payerLobId: number): Observable<PayerLob> {
    return of();
  }

  getPayerPlanByPlanID(planId: number): Observable<PayerPlanDetails> {
    return of();
  }

  getPayerAccountById(payerFamilyId: number, payerPlanId: number, payerAcctId: number): Observable<PayerAccount> {
    return of();
  }

  importAddress(fileToUpload: File): Observable<BulkAddressImport> {
    return of();
  }

  private getPayerLobForComboboxPaging(filter: string, restartRowNumber: number,
                                       pageSize: number): Observable<Array<SearchDataList>> {
    return of([]);
  }

  public getPayerLobByFamIdForComboboxPaging(payerFamId: number, filterStr: string, restartRowId: number,
                                             pageSize: number): Observable<Array<SearchDataList>> {
    return of([]);
  }
}

export const orderGridListMock: OrderGridList = {
  orderId: 1,
  payerLobId: 5,
  memberId: 10,
  orderNum: '190',
  statusDesc: '',
  orderedOn: '',
  payerLobName: '',
  orderZipCode: '',
  expected: '',
  hcpcCodes: '',
  patientName: '',
  isTransitionOrder: ''
};

export const npiPhysMock: NPIv2 = {
  npiNum: '1083617021',
  enumType: 'NPI-1',
  isActive: false,
  physFirstName: 'physFirst',
  physLastName: 'physLast',
  phoneNumber: '1234567890',
  faxNumber: '0123456789'
};

export const npiOrgMock: NPIv2 = {
  npiNum: '1083801385',
  enumType: 'NPI-2',
  isActive: false,
  orgName: 'orgName',
  phoneNumber: '2345678901',
  faxNumber: '3456789012'
};

export class AccessControlServiceMock {

  getUserByID(userId: number): Observable<User> {
    return of(null);
  }

  public addPermission = (items: Array<PermissibleItem>, isRoute: boolean, origin?: string): void => {
    items.forEach(item => {
      item.actions = [
        AccessInfo.ActionsEnum.Visible,
        AccessInfo.ActionsEnum.Enabled
      ];
    });
  }

  public getAccessForRoute = (route: string): Array<AccessInfo.ActionsEnum> => {
    return [AccessInfo.ActionsEnum.Enabled];
  }

  public loadAccess = (accessRequest: AccessResolveRequest): Observable<boolean> => {
    return of(true);
  }

  public getAccessForWidget = (widgetId: string, origin: string): Array<AccessInfo.ActionsEnum> => {
    return [AccessInfo.ActionsEnum.Visible];
  }

  public getWidgetsAndRoutes = (serviceName: string, accessType: string, statusCode: string): Observable<Array<AccessInfo>> => {
    return of([{ item: 'item', actions: [AccessInfo.ActionsEnum.Enabled] }]);
  }
}

export class UsersServiceMock {

  public configuration = {};

  getUserByID(userId: number): Observable<User> {
    return of(null);
  }

  getUsers(sort?: string, filter?: string, restartRowId?: number, pageSize?: number): Observable<Array<User>> {
    return of([]);
  }

  updateUserPassword(payerFamId: number, userId: number, existingPassword: string, newPassword: string): Observable<any> {
    return of(null);
  }

  createUser(payerFamId: number, user: User): Observable<UserWithRolesAccts> {
    return of(null);
  }

  updateUser(payerFamId: number, userId: number, user: User): Observable<User> {
    return of(null);
  }

  getFamilyUserByID(payerFamId: number, userId: number): Observable<User> {
    return of(null);
  }

  getInternalUsers(filterStr?: string, sort?: string, restartRowId?: number, pageSize?: number): Observable<Array<User>> {
    return of();
  }

  getUsersForCombobox(filterStr: string): Observable<Array<SearchDataList>> {
    return of();
  }

  getCrpUsersForCombobox(filterStr: string): Observable<Array<SearchDataList>> {
    return of();
  }
}

export class UsersApiServiceMock {

  public configuration = {};

  getUserByID(userId: number): Observable<User> {
    return of(null);
  }

  getPayerUsers(sort?: string, filter?: string, restartRowId?: number, pageSize?: number): Observable<Array<User>> {
    return of([]);
  }

  resetUserPassword(payerFamId: number, userId: number, password: object): Observable<any> {
    return of(null);
  }

  createUser(payerFamId: number, user: User): Observable<UserWithRolesAccts> {
    return of(null);
  }

  updateUser(payerFamId: number, userId: number, user: User): Observable<User> {
    return of(null);
  }

  getFamilyUserByID(payerFamId: number, userId: number): Observable<User> {
    return of(null);
  }
}

export class UserActivityServiceMock {
  public configuration = {};
  getUserActivityByID(payerFamId: any, userId: any): Observable<Array<Activity>> {
    return of(null);
  }
}

export class UserProfileServiceMock {
  getUserByID(userId: number): Observable<UserWithRolesAccts> {
    return of(null);
  }
}

export class MyProfileSerivceMock {
  getMyProfile(): Observable<UserWithRolesAccts> {
    return of({
      isTermsAccepted: true,
      isPwdResetRequired: false
    } as UserWithRolesAccts);
  }

  patchMyProfile(userProfile: UserProfileRequest): Observable<UserWithRolesAccts> {
    return of(null);
  }
}

export class MenuServiceMock {
  public getMenuItems(): MenuItem[] {
    return [];
  }

  public getUserMenuItems(): UserMenuItem[] {
    return [];
  }
}

export class MockRenderer2 extends Renderer2 {
  addClass(el: any, name: string): void {
  }

  appendChild(parent: any, newChild: any): void {
  }

  createComment(value: string): any {
  }

  createElement(name: string, namespace?: string | null): any {
  }

  createText(value: string): any {
  }

  get data(): { [p: string]: any } {
    return {};
  }

  destroy(): void {
  }

  insertBefore(parent: any, newChild: any, refChild: any, isMove?: boolean): void {
  }

  listen(target: any, eventName: string, callback: (event: any) => (boolean | void)): () => void {
    return () => {
    };
  }

  nextSibling(node: any): any {
  }

  parentNode(node: any): any {
  }

  removeAttribute(el: any, name: string, namespace?: string | null): void {
  }

  removeChild(parent: any, oldChild: any, isHostElement?: boolean): void {
  }

  removeClass(el: any, name: string): void {
  }

  removeStyle(el: any, style: string, flags?: RendererStyleFlags2): void {
  }

  selectRootElement(selectorOrNode: any, preserveContent?: boolean): any {
  }

  setAttribute(el: any, name: string, value: string, namespace?: string | null): void {
  }

  setProperty(el: any, name: string, value: any): void {
  }

  setStyle(el: any, style: string, value: any, flags?: RendererStyleFlags2): void {
  }

  setValue(node: any, value: string): void {
  }
}

export class MockElementRef extends ElementRef {
}

export const mockCellClickEvent: CellClickEvent = {
  column: null,
  columnIndex: 0,
  dataItem: null,
  isEdited: false,
  originalEvent: null,
  rowIndex: 0,
  sender: null,
  type: 'click'
};

export const userMock: UserWithRolesAccts = {
  userId: 1,
  firstName: 'John',
  lastName: 'Doe',
  userName: 'johndoe',
  emailAddress: 'johndoe@example.com',
  jobTitle: 'jobTitle',
  userStatusCode: 'CODE',
  userTypeCode: 'TYPE_CODE',
  roles: [],
  accounts: []
};

export const userRolesMock: RoleItem[] = [{
  roleName: 'ROLE_1'
}, {
  roleName: 'ROLE_2'
}];

export const rolesMock: RoleItemList[] = [{
  roleName: 'ROLE_1',
  roleDesc: 'Role 1'
}, {
  roleName: 'ROLE_2',
  roleDesc: 'Role 2'
}];

export class AccessManagementServiceMock {
  public configuration = {};

  public getAccessDtls(serviceName: string, accessType: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<AccessInfo>> {
    return of([]);
  }
}

export const cancelReasonsMock: ListItemDtl[] = [
  {
    attributes: [],
    listDtlCode: 'COORD_DELIV',
    listDtlDesc: 'Unable to contact member to coordinate delivery',
    listDtlEndDt: null,
    listDtlId: 72826,
    listDtlStartDt: '2020-01-01',
    listTypeId: 19
  },
  {
    attributes: [
      {
        listAttrId: 11,
        listAttrName: 'is_sub_reason_req',
        listDtlAttrId: 81,
        listDtlAttrValue: 'true'
      }
    ],
    listDtlCode: 'DUP_ORDER',
    listDtlDesc: 'Duplicate order',
    listDtlEndDt: null,
    listDtlId: 156,
    listDtlStartDt: '2020-01-01',
    listTypeId: 19
  }
];

export class OrdersSummaryServiceMock {

  getOrdSummary(payerSummaryReq: SummaryReq): Observable<Array<OrderSummaryEntry>> {
    return of();
  }

}

export class ClaimCountServiceMock {

  getClaimsTimelyCount(): Observable<Array<ClaimTimelyCount>> {
    return of();
  }
}

export class ClaimTimelyByTeamServiceMock {

  getClaimsTimelyByTeamCount(): Observable<Array<TeamTimelyCount>> {
    return of();
  }
}


export const mdmOrderStatusMock = [
  { listDtlCode: 'IN_PROGRESS', listDtlDesc: 'In Progress', listDtlStartDt: '2020-01-01' },
  { listDtlCode: 'PENDING', listDtlDesc: 'Pending', listDtlStartDt: '2020-01-01' },
  { listDtlCode: 'SHIPPED', listDtlDesc: 'Shipped', listDtlStartDt: '2020-01-01' },
];

export const ordersSummEntriesMock: OrderSummEntry[] = [
  { ordStatusCode: 'PENDING', numOrders: 10 },
  { ordStatusCode: 'IN_PROGRESS', numOrders: 10 }
];

export const ordersSummaryEntriesMock: OrderSummaryEntry[] = [
  { ordStatusDesc: 'Pending', color: '#ffffff', ordStatusCode: 'PENDING', numOrders: 10 },
  { ordStatusDesc: 'In Progress', color: '#000000', ordStatusCode: 'IN_PROGRESS', numOrders: 10 },
];

export class MsalBroadcastServiceMock {
  msalSubject$ = new Observable<EventMessage>();
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6',
      redirectUri: 'http://localhost:4200'
    }
  });
}

export const authenticationResultMock: AuthenticationResult = {
  correlationId: '',
  authority: 'https://prismb2cdev.b2clogin.com/prismb2cdev.onmicrosoft.com/b2c_1a_signup_signin/',
  uniqueId: 'c3c07af3-0d13-4fa3-b645-3c663c35bccf',
  tenantId: '5822026f-1bd0-4fb9-a909-4a008263fe2b',
  scopes: [
    'https://prismb2cdev.onmicrosoft.com/prism/Read.All'
  ],
  account: {
    homeAccountId: 'c3c07af3-0d13-4fa3-b645-3c663c35bccf-b2c_1a_signup_signin.5822026f-1bd0-4fb9-a909-4a008263fe2b',
    environment: 'prismb2cdev.b2clogin.com',
    tenantId: '5822026f-1bd0-4fb9-a909-4a008263fe2b',
    username: '',
    localAccountId: 'c3c07af3-0d13-4fa3-b645-3c663c35bccf',
    name: 'Kevin Payer',
    idTokenClaims: {
      exp: 1622146134,
      nbf: 1622142534,
      ver: '1.0',
      iss: 'https://prismb2cdev.b2clogin.com/tfp/5822026f-1bd0-4fb9-a909-4a008263fe2b/b2c_1a_signup_signin/v2.0/',
      sub: 'c3c07af3-0d13-4fa3-b645-3c663c35bccf',
      aud: '7601fa1e-5c1e-4f10-b60e-ec5c5fcb0ecf',
      nonce: '2cd3614a-da5b-41c6-8ebd-42feeb14985c',
      iat: 1622142534,
      auth_time: 1622132691,
      oid: 'c3c07af3-0d13-4fa3-b645-3c663c35bccf',
      name: 'Kevin Payer',
      given_name: 'Kevin',
      family_name: 'Payer',
      roles: ['PAM'],
      sid: 'd2ef9e79-059c-49a3-9b38-076e5aa6daa6',
      uid: 59,
      utc: 'PAYER',
      lpf: '17',
      lpa: '78',
      lpro: '',
      lprf: '',
      tid: '5822026f-1bd0-4fb9-a909-4a008263fe2b',
      tfp: 'B2C_1A_signup_signin',
      at_hash: 'dgcgv6vZ31JKHLJKR8zHxw'
    }
  },
  idToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjYwNGREczVhRE5GZkJEV0lqcEpMaXJZZ2xXNnF1UF9KQi1pcWNpRExSNjAifQ.eyJleHAiOjE2MjIxNDYxMzQsIm5iZiI6MTYyMjE0MjUzNCwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9wcmlzbWIyY2Rldi5iMmNsb2dpbi5jb20vdGZwLzU4MjIwMjZmLTFiZDAtNGZiOS1hOTA5LTRhMDA4MjYzZmUyYi9iMmNfMWFfc2lnbnVwX3NpZ25pbi92Mi4wLyIsInN1YiI6ImMzYzA3YWYzLTBkMTMtNGZhMy1iNjQ1LTNjNjYzYzM1YmNjZiIsImF1ZCI6Ijc2MDFmYTFlLTVjMWUtNGYxMC1iNjBlLWVjNWM1ZmNiMGVjZiIsIm5vbmNlIjoiMmNkMzYxNGEtZGE1Yi00MWM2LThlYmQtNDJmZWViMTQ5ODVjIiwiaWF0IjoxNjIyMTQyNTM0LCJhdXRoX3RpbWUiOjE2MjIxMzI2OTEsIm9pZCI6ImMzYzA3YWYzLTBkMTMtNGZhMy1iNjQ1LTNjNjYzYzM1YmNjZiIsIm5hbWUiOiJLZXZpbiBQYXllciIsImdpdmVuX25hbWUiOiJLZXZpbiIsImZhbWlseV9uYW1lIjoiUGF5ZXIiLCJyb2xlcyI6IlBBTSIsInNpZCI6ImQyZWY5ZTc5LTA1OWMtNDlhMy05YjM4LTA3NmU1YWE2ZGFhNiIsInVpZCI6NTksInV0YyI6IlBBWUVSIiwibHBmIjoiMTciLCJscGEiOiI3OCIsImxwcm8iOiIiLCJscHJmIjoiIiwidGlkIjoiNTgyMjAyNmYtMWJkMC00ZmI5LWE5MDktNGEwMDgyNjNmZTJiIiwidGZwIjoiQjJDXzFBX3NpZ251cF9zaWduaW4iLCJhdF9oYXNoIjoiZGdjZ3Y2dlozMUpLSExKS1I4ekh4dyJ9.MFZKsUJ5hQIzZJvE2dnW6APhumWeW-gjXaYbykCvPDWHfQ_AD2loR7aVKHlKolCsxtoAaYXBjlx-lbEidHT_lzNUnNy7PALzpMWRcbZZ-dC2Oo_kHp8olUeJrtuf5dzoziuMSyjT0SzY9rn9yHfKO6TJkzsRXOXgHj2amTzj82zU8bPWd68VEtx2Reqvoii2fGLe94wmDSsKdBjkMBTskCQrptK7ZMMdjrpmmH0XJh6yvSSHrpJlfqkikoWaWjDeWqeNemOh7bxAYBRFovOVGoU6wA-r3IDm6ltn7IUMPeCtHz0e3YSoDLqQRloz5c9PgiFTIXF8sixfhthSmDRyCA',
  idTokenClaims: {
    exp: 1622146134,
    nbf: 1622142534,
    ver: '1.0',
    iss: 'https://prismb2cdev.b2clogin.com/tfp/5822026f-1bd0-4fb9-a909-4a008263fe2b/b2c_1a_signup_signin/v2.0/',
    sub: 'c3c07af3-0d13-4fa3-b645-3c663c35bccf',
    aud: '7601fa1e-5c1e-4f10-b60e-ec5c5fcb0ecf',
    nonce: '2cd3614a-da5b-41c6-8ebd-42feeb14985c',
    iat: 1622142534,
    auth_time: 1622132691,
    oid: 'c3c07af3-0d13-4fa3-b645-3c663c35bccf',
    name: 'Kevin Payer',
    given_name: 'Kevin',
    family_name: 'Payer',
    roles: 'PAM',
    sid: 'd2ef9e79-059c-49a3-9b38-076e5aa6daa6',
    uid: 59,
    utc: 'PAYER',
    lpf: '17',
    lpa: '78',
    lpro: '',
    lprf: '',
    tid: '5822026f-1bd0-4fb9-a909-4a008263fe2b',
    tfp: 'B2C_1A_signup_signin',
    at_hash: 'dgcgv6vZ31JKHLJKR8zHxw'
  },
  accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjYwNGREczVhRE5GZkJEV0lqcEpMaXJZZ2xXNnF1UF9KQi1pcWNpRExSNjAifQ.eyJpc3MiOiJodHRwczovL3ByaXNtYjJjZGV2LmIyY2xvZ2luLmNvbS90ZnAvNTgyMjAyNmYtMWJkMC00ZmI5LWE5MDktNGEwMDgyNjNmZTJiL2IyY18xYV9zaWdudXBfc2lnbmluL3YyLjAvIiwiZXhwIjoxNjIyMTQ2MTM0LCJuYmYiOjE2MjIxNDI1MzQsImF1ZCI6Ijc2MDFmYTFlLTVjMWUtNGYxMC1iNjBlLWVjNWM1ZmNiMGVjZiIsInN1YiI6ImMzYzA3YWYzLTBkMTMtNGZhMy1iNjQ1LTNjNjYzYzM1YmNjZiIsIm9pZCI6ImMzYzA3YWYzLTBkMTMtNGZhMy1iNjQ1LTNjNjYzYzM1YmNjZiIsIm5hbWUiOiJLZXZpbiBQYXllciIsImdpdmVuX25hbWUiOiJLZXZpbiIsImZhbWlseV9uYW1lIjoiUGF5ZXIiLCJyb2xlcyI6IlBBTSIsInNpZCI6ImQyZWY5ZTc5LTA1OWMtNDlhMy05YjM4LTA3NmU1YWE2ZGFhNiIsInVpZCI6NTksInV0YyI6IlBBWUVSIiwibHBmIjoiMTciLCJscGEiOiI3OCIsImxwcm8iOiIiLCJscHJmIjoiIiwidGlkIjoiNTgyMjAyNmYtMWJkMC00ZmI5LWE5MDktNGEwMDgyNjNmZTJiIiwidGZwIjoiQjJDXzFBX3NpZ251cF9zaWduaW4iLCJub25jZSI6IjJjZDM2MTRhLWRhNWItNDFjNi04ZWJkLTQyZmVlYjE0OTg1YyIsInNjcCI6IlJlYWQuQWxsIiwiYXpwIjoiNzYwMWZhMWUtNWMxZS00ZjEwLWI2MGUtZWM1YzVmY2IwZWNmIiwidmVyIjoiMS4wIiwiaWF0IjoxNjIyMTQyNTM0fQ.VeRMgkOQtjnWI5yLdwwTalo7A4SXrShNUG1gl1XOyo344FfH3az_ck_3CLASvSf-VWM1g1DqLb7js-Is-Y1dHLip6LO31SzxTiZoLyfG1c760DiqzorWWRKBUfPpIWRRUp9qufq5LQI3Os3EcBZG65J2sUjcL5HUF8f_8HWtwDn4G89YDV5KzZpYKsOjpgum2AB27sFkdIiJlDeh8ZZPfqkojo87QSa1PTDGY6zdIaSabV_zdCc_TzDVSWerbBx_IPktjrtHA9PevrFoL-82ReucRk1ZmO3O6l09NllNViTD08wpPubaCurcaWWMUAxttesGSs-peS_5sZ9-j0tAUA',
  fromCache: false,
  expiresOn: new Date('2021-05-27T20:08:54.000Z'),
  extExpiresOn: new Date('2021-05-27T20:08:54.000Z'),
  familyId: '',
  tokenType: 'Bearer',
  state: '',
  cloudGraphHostName: '',
  msGraphHost: ''
};

export class ActivityLogServiceMock {
  public fetchLogs(service: ChangeLogServiceI, config: ChangeLogRequestConfigI, entity: string): Observable<Array<UIActivityAction>> {
    return of();
  }

  public fetchActivity(service: ChangeLogServiceI, config: ChangeLogRequestConfigI): Observable<Array<UIActivityAction>> {
    return of();
  }

  public filterActivityChange(filter: ChangeLogFilter, logs: Array<UIActivityAction>): Array<UIActivityAction> {
    return [];
  }
}

export class ChangeLogsServiceApiMock {
  public configuration = {};

  public getChangesByOrderID(payerLobId: number, memberId: number, orderId: number): Observable<Array<Change>> {
    return of();
  }
}

export class ChangeLogServiceMock {
  fetchLogs(config: ChangeLogRequestConfigI): Observable<Array<ChangeModel>> {
    return of();
  }

  fetchActivity(config: ChangeLogRequestConfigI): Observable<Array<ActivityModel>> {
    return of();
  }

  pathToFieldName(key: string): string {
    return key;
  }

  public getChangesByOrderIDAndStatus(
    payerLobId: number, memberId: number, orderId: number, status: OrderChangesByStatusReq.ByStatusEnum
  ): Observable<Array<Change>> {
    return of([]);
  }

  public shouldInclude(fieldName: string): boolean {
    return false;
  }
  public getExcludeFields(): Array<string> {
    return [];
  }
}

export const callLogsMock = [
  {
    callId: 33,
    callTs: '2021-07-25T23:07:39.192Z',
    comments: '\u003cp\u003esfsfsfs\u003c/p\u003e',
    createdBy: 'Chris Rock',
    createdTs: '2021-07-25T23:07:46.749105Z',
    modifiedBy: 'Chris Rock',
    modifiedTs: '2021-07-25T23:07:46.749105Z',
    orderId: 32610,
    orderProviderId: 33297
  },
  {
    callId: 39,
    callTs: '2021-07-04T14:28:29.648Z',
    comments: '\u003cp\u003ecomment aaa\u003c/p\u003e',
    createdBy: 'Chris Rock',
    createdTs: '2021-07-26T14:28:35.667596Z',
    modifiedBy: 'Chris Rock',
    modifiedTs: '2021-07-26T14:29:57.211979Z',
    orderId: 32610,
    orderProviderId: 33297
  }
];

export class TasksServiceMock {
  configuration = {};

  public getListOfTasks(pageSize?: number, restartRowId?: number, filter?: string): Observable<Array<Task>> {
    return of();
  }

  public getTaskByName(taskName: string): Observable<Task> {
    return of();
  }
}

export class JobsServiceMock {
  configuration = {};

  public getListOfJobs(viewAll?: 'REPORT' | 'JOB', pageSize?: number, restartRowId?: number, filter?: string, observe?: any): Observable<any> {
    return of();
  }

  public getJobByID(jobId: string): Observable<Job> {
    return of();
  }

  public createJob(jobRequest: Job): Observable<Job> {
    return of();
  }

  public updateJob(jobId: string, jobRequest: Job): Observable<Job> {
    return of();
  }

  public deleteJob(jobId: string): Observable<any> {
    return of();
  }

  public downloadJobDoc(jobId: string, docId: string, observe?: 'body', reportProgress?: boolean, options?: any): Observable<any> {
    return of();
  }
}

export class SchedJobsServiceMock {
  public getListOfJobs(pageSize?: number, restartRowId?: number, filter?: string): Observable<Array<JobListItem>> {
    return of();
  }

  public getJobByID(jobId: number): Observable<Job> {
    return of();
  }

  public createJob(jobRequest: Job): Observable<Job> {
    return of();
  }

  public downloadJobDoc(jobId: string, docId: string): Observable<HttpResponse<Blob>> {
    return of();
  }
}

export class ReportsServiceMock {

  public getListOfTasks(pageSize?: number, restartRowId?: number, filters?: any[]): Observable<Array<Task>> {
    return of();
  }

  public getTaskFilter(taskName: string): Observable<Array<FormFieldConfig>> {
    return of();
  }

  public getListOfJobs(pageSize?: number, restartRowId?: number, filters?: any[]): Observable<Array<JobListItem>> {
    return of();
  }

  public getTaskByName(taskName: string): Observable<Task> {
    return of();
  }

  public getReportByID(jobId: string): Observable<Job> {
    return of();
  }

  public getReportValues(jobId: string): Observable<ReportValues> {
    return of(null);
  }

  public generateReport(jobRequest: Job): Observable<Job> {
    return of();
  }

  public getReports(pageSize?: number, restartRowId?: number, filterStr: string = ''): Observable<JobListItem[]> {
    return of([]);
  }

  public getTemplates(pageSize?: number, restartRowId?: number, filterStr = ''): Observable<Array<ScheduleListItem>> {
    return of([]);
  }

  public saveTemplate(schedule: Schedule): Observable<Schedule> {
    return of();
  }

  public saveReport(jobRequest: Job): Observable<Job> {
    return of();
  }

  public deleteReport(jobId: string): Observable<any> {
    return of();
  }

  public deleteTemplate(scheduleId: string): Observable<any> {
    return of();
  }

}

export class ReportCreationStateServiceMock {

  stepperObs = of([]);

  headerActionsObs = of([]);

  headerInfoObs = of({});

  onNext = new Subject();

  onBack = new Subject();

  public getCurrentStepValues(stepId: ReportStepId): { [key: string]: any } {
    return {};
  }

  public setCurrentStep(stepId: ReportStepId, form: AbstractControl): void { }

  public setStepValues(stepId: ReportStepId, values: { [key: string]: any }): void { }

  public toggleDisable(stepId: ReportStepId, disabled: boolean = null): void { }

  public setStepConfig(stepId: ReportStepId, config: FormFieldConfig[]): void { }

  public getStepConfig(stepId: ReportStepId): FormFieldConfig[] {
    return null;
  }

  public initSteps(viewMode: ReportViewMode): void { }

  public hasUnsavedChanges(): boolean { return false; }

  public resetAll(): void { }
}

export const jobDetailItemMock = {
  createdBy: 'System Process',
  createdTS: '2021-08-02T17:29:54.736Z',
  execEndTs: '2021-08-02T17:30:25.941Z',
  execResults: {
    'rpt.csv.doc.catg': 'CSV_EXPORT',
    'rpt.csv.doc.id': '08a7a14e-53b4-4f9a-813e-bdb8c40a46b0',
    'rpt.preview.doc.catg': 'REPORT_HTML_PREVIEW',
    'rpt.preview.doc.id': 'dd12e05b-bc6c-4a44-8435-650f85b9dba7'
  },
  execStartTs: '2021-08-02T17:29:54.878Z',
  execStatus: 'failed',
  jobId: 'vs1zlrmynbdb59paxf6me',
  jobParams: {
    orderStatusCodes: [
      'PENDING_ACCEPTANCE'
    ]
  },
  taskDesc: 'Get list of orders',
  taskName: 'rptOrderSummary',
  taskType: 'report'
};

export class DocumentServiceMock {
  public getReportFile(categoryCode: string, docId: string, type: string): Observable<any> {
    return of('File');
  }
}

export class OrderReportFilterServiceMock {
  public getFiltersConfig(filterComponent: string, param: TaskParam): FormFieldConfig {
    return null;
  }

  public getCallback(name: string): ReportDataProviderMethod {
    return (str) => of([]);
  }
}

export const mockTaskDtl: Task = {
  taskName: 'Test Report',
  taskAttrs: {
    'rpt.column.list': {
      report: {
        orderNum: {
          default: true,
          label: 'Order Number',
          width: '200px',
          position: 1
        },
        firstName: {
          default: false,
          label: 'First Name',
          width: '200px',
          position: 2
        },
        lastName: {
          default: false,
          label: 'Last Name',
          width: '200px',
          position: 3
        }
      }
    },
    'rpt.sort.list': {
      report: {
        orderNum: {
          label: 'Order Number',
          default: 'ASC'
        },
        assignedTS: {
          label: 'Assigned Time Stamp',
          default: 'ASC'
        }
      }
    }
  },
  taskResults: []
};

export const mockJob: Job = {
  createdBy: 'Unknown User',
  createdTS: '2021-08-19T15:57:33.291Z',
  execEndTs: '2021-08-19T15:57:37.746Z',
  execResults: {
    'rpt.csv.doc.catg': 'CSV_EXPORT',
    'rpt.csv.doc.id': '53fa9f33-0d58-448d-8ada-02c97b68ec3a',
    'rpt.preview.doc.catg': 'REPORT_HTML_PREVIEW',
    'rpt.preview.doc.id': '0c362d14-0825-47be-ba78-9411e5ee9a9f'
  },
  execStartTs: '2021-08-19T15:57:33.391Z',
  execStatus: 'success',
  jobId: '6j4hijcg2tqwbr7n3dj6e',
  jobParams: {
    orderStatusCodes: ['CANCELLED', 'FUTURE_DOS'],
    payerFamID: 144,
    'rpt.inc.columns': {
      report: ['orderNum', 'firstName', 'lastName']
    },
    'rpt.sort': {
      report: [{ dir: 'ASC', property: 'orderStatusCode' }]
    }
  },
  modifiedBy: 'System Process',
  modifiedTS: '2021-08-19T15:57:37.746Z',
  taskAttrs: {
    'rpt.column.list': {
      report: {
        firstName: { default: false, label: 'First Name', position: 2, width: '200px' },
        lastName: { default: false, label: 'Last Name', position: 3, width: '100px' },
        orderNum: { default: true, label: 'Order Number', position: 1, width: '100px' },
        orderStatusCode: { default: false, label: 'Order Status Code', position: 17, width: '100px' }
      }
    },
    'rpt.filter.component': 'orderReportFilter',
    'rpt.sort.list': {
      report: {
        orderStatusCode: { default: 'ASC', label: 'Order Status Code' },
        payerName: { default: 'ASC', label: 'Payer Name' },
        provOrgName: { default: 'ASC', label: 'Provider Facility Name' }
      }
    }
  },
  taskDesc: 'Get list of order items',
  taskName: 'rptOrderItems',
  taskType: 'report'
};

export const reportValuesMock: ReportValues = {
  details: { taskName: 'testReport', jobDesc: 'This is a test report' },
  filters: {
    orderStatusCode: ['DELIVERED', 'SHIPPED'],
    payerFamID: 144
  },
  columns: [
    { group: 'report.orderNum', key: 'orderNum', label: 'Order Number', isDefault: true, position: 1 },
    { group: 'report.firstName', key: 'firstName', label: 'First Name', isDefault: false, position: 2 }
  ],
  sorts: [
    {
      group: 'report.orderNum',
      key: 'orderNum',
      label: 'Order Number',
      direction: 'ASC',
      switchDirection: true,
      enableToggleDirection: true
    }
  ]
};

export class ReportsMappingServiceMock {

  public buildReportRequest(taskDtls: any, filters: any, attributes: any): Job {
    return null;
  }

  public buildTemplateRequest(taskDtls: any, filters: any, attributes: any): Schedule {
    return null;
  }
}

export const filterButtonsMock: Array<ButtonArray> = [
  {
    text: 'All',
    type: '',
    icon: 'folder',
    dispOrder: 0,
    dropdown: false,
    code: ''
  },
  {
    text: 'Pending Acceptance',
    icon: 'clock',
    dispOrder: 1,
    type: 'transparent',
    dropdown: true,
    data: [
      {
        text: 'All',
        parentBtnId: 1,
        code: 'PENDING_ACCEPTANCE,PENDING_RE_ACCEPTANCE',
        dropdown: true,
        click: () => { }
      },
      {
        text: 'Pending Acceptance',
        parentBtnId: 1,
        code: 'PENDING_ACCEPTANCE',
        dropdown: true,
        click: () => { }
      },
      {
        text: 'Pending Re-Acceptance',
        parentBtnId: 1,
        code: 'PENDING_RE_ACCEPTANCE',
        dropdown: true,
        click: () => { }
      }
    ]
  },
  {
    text: 'In Progress',
    type: 'secondary',
    icon: 'track-changes-accept',
    dispOrder: 2,
    dropdown: false,
    code: 'IN_PROGRESS'
  }
];

export const filterButtonsFlatArrayMock = [
  {
    text: 'All',
    type: '',
    icon: 'folder',
    dispOrder: 0,
    dropdown: false,
    code: '',
    click: () => { }
  },
  {
    text: 'Pending Acceptance',
    parentBtnId: 1,
    code: 'PENDING_ACCEPTANCE',
    dropdown: true,
    click: () => { }
  },
  {
    text: 'Pending Re-Acceptance',
    parentBtnId: 1,
    code: 'PENDING_RE_ACCEPTANCE',
    dropdown: true,
    click: () => { }
  }
];

export const orderGridFilterButtonsMock = {
  filterButtons: filterButtonsMock,
  filterButtonsFlatArray: filterButtonsFlatArrayMock,
  events: (new Subject()).asObservable()
};

export class OrderListGridUtilitiesServiceMock {
  public getFilterButtons(): Observable<{
    filterButtons: Array<ButtonArray>,
    filterButtonsFlatArray: Array<DropDownButtonData>,
    events: Observable<any>
  }> {
    return of(orderGridFilterButtonsMock);
  }
}

export const acmOrderWorkflowMock: AccessInfo[] = [
  { actions: ['view.in.grid'], item: 'order.in.status.pending_acceptance' },
  { actions: ['view.in.grid'], item: 'order.in.status.pending_integra_review' },
  { actions: ['view.in.grid'], item: 'order.in.status.rejected' }
];


export const mockMap = {
  options: { center: [1, 2] as MapGeoLocation, zoom: 3, layers: null },
  addControl: () => { },
  addLayer: () => { },
  fitBounds: () => { },
  setZoom: () => { }
};

export const MapQuestSdkMock: MapquestSdk = {
  mapquest: {
    key: '',
    icons: {
      marker: () => {
        return null;
      }
    },
    map: (reference: string | HTMLElement, options: MapquestOptions) => {
      return mockMap;
    },
    tileLayer: (styleId: MapquestStyle) => {
      return null;
    },
    textMarker: (geolocation: MapGeoLocation, options: MapquestTextMarkerOptions) => {
      return null;
    },
    navigationControl: () => {
      return null;
    }
  },
  marker: (geoLocation: MapGeoLocation, options: MapquestMarkerOptions) => {
    return null;
  },
  featureGroup: (markers: MapquestMarker[]) => {
    return null;
  },
  latLng: (latitude: number, longitude: number) => {
    return null;
  }
};

export class MapQuestServiceMock {
  mapQuestReady(): Promise<MapquestSdk> {
    return Promise.resolve(MapQuestSdkMock);
  }
}

export const hcpcListMock: Array<HcpcCode> = [{
  hcpcCode: 'A4206',
  hcpcLvl: 0,
  equipType: '',
  hcpcLongDesc: 'Syringe with needle, sterile, 1 cc or less, each',
  hcpcShortDesc: '1 cc sterile syringe&needle',
  isMiscCode: false,
  isLaborRepairCode: false
}, {
  hcpcCode: 'A4207',
  hcpcLvl: 0,
  equipType: '',
  hcpcLongDesc: 'Syringe with needle, sterile 2 cc, each',
  hcpcShortDesc: '2 cc sterile syringe&needle',
  isMiscCode: false,
  isLaborRepairCode: false
}];

export class HCPCListServiceMock {
  public configuration = {};

  public getHcpcCode(hcpcCode: string, withCatgInfo?: boolean): Observable<HcpcCode> {
    return of();
  }

  public getHcpcCodes(
    sort?: string, restartRowId?: number, pageSize?: number, categoryCode?: string, categoryType?: string
  ): Observable<Array<HcpcCode>> {
    return of();
  }
}

export const productsListMock: Product[] = [{
  description: 'Adhesive bandage, first-aid',
  hcpcCatg: 'MEDS',
  hcpcCode: 'A6413',
  isCommentReq: false,
  isEnabled: true,
  isMiscCode: false,
  productId: 399,
  isLaborRepairCode: false
}, {
  description: 'Adhesive remover per ounce',
  hcpcCatg: 'OS',
  hcpcCode: 'A4455',
  isCommentReq: false,
  isEnabled: true,
  isMiscCode: false,
  productId: 210,
  isLaborRepairCode: false
}];

export const providerFacility: FacilityDto = {
  organizationId: 513,
  organizationLegalName: '1009 orga legal - DO NOT TOUCH',
  organizationDBAName: '1009 orga DBA - DO NOT TOUCH',
  organizationStatus: 'ACT',
  facilityId: 1133,
  storeName: '1009 orga DBA Facility2',
  status: 'Active',
  distanceFromMbr: 0,
  phoneNumber: '1111111111',
  faxNumber: '1234567899',
  orderKeyContacts: [
    {
      name: 'test string',
      email: 'string@abc.com'
    },
    {
      name: 'string testing',
      email: 'string@abc.com'
    },
    {
      name: 'test string',
      email: 'string@abc.com'
    }
  ],
  businessHrs: [
    {
      day: 'Friday',
      openTime: '09:00:00',
      closeTime: '23:59:00',
      isByAppointment: true,
      isClose: false,
      isOpen24Hours: false
    },
    {
      day: 'Monday',
      openTime: '00:00:00',
      closeTime: '23:59:00',
      isByAppointment: true,
      isClose: false,
      isOpen24Hours: true
    },
    {
      day: 'Saturday',
      openTime: '00:00:00',
      closeTime: '23:59:00',
      isByAppointment: true,
      isClose: false,
      isOpen24Hours: true
    },
    {
      day: 'Sunday',
      openTime: '00:00:00',
      closeTime: '23:59:00',
      isByAppointment: true,
      isClose: false,
      isOpen24Hours: true
    },
    {
      day: 'Thursday',
      openTime: '00:00:00',
      closeTime: '23:59:00',
      isByAppointment: true,
      isClose: false,
      isOpen24Hours: true
    },
    {
      day: 'Tuesday',
      openTime: '00:00:00',
      closeTime: '23:59:00',
      isByAppointment: true,
      isClose: false,
      isOpen24Hours: true
    },
    {
      day: 'Wednesday',
      openTime: '00:00:00',
      closeTime: '23:59:00',
      isByAppointment: true,
      isClose: false,
      isOpen24Hours: true
    }
  ],
  languages: [
    {
      languageCode: 'OTH',
      languageName: 'test languages'
    }
  ],
  deliveryMode: [
    'Delivery', 'Pickup'
  ],
  productsServiced: [
    {
      deliveryMode: ['Delivery'],
      hcpcCatgCode: 'INC'
    },
    {
      deliveryMode: ['Delivery', 'Pickup'],
      hcpcCatgCode: 'DME'
    },
    {
      deliveryMode: ['Delivery', 'Pickup'],
      hcpcCatgCode: 'US'
    },
    {
      hcpcCatgCode: 'LCC'
    },
  ],
  keyNote: 'Get facility/location and related data by facilityId',
  address1: '123 address street',
  address2: null,
  city: 'test',
  state: 'AK',
  zip: '95505',
  county: 'Aleutians East'
};

export const orderFacility: OrderFacilityDto = {
  address1: '400 Bedford Ave',
  address2: null,
  businessHrs: [
    { closeTime: '17:00:00', day: 'Sunday', isByAppointment: false, isClose: true, isOpen24Hours: false, openTime: '09:00:00' }
  ],
  city: 'New York',
  county: 'Kings',
  distanceFromMbr: 2.37,
  facilityId: 164,
  faxNumber: null,
  isHistProv: false,
  languages: [{ languageCode: 'SPN', languageName: 'Spanish' }, { languageCode: 'ENG', languageName: 'English' }],
  organizationDBAName: 'Alyssa Medical Supplies',
  organizationId: 221,
  organizationLegalName: 'Alyssa Medical Suppliesa',
  organizationStatus: 'Active',
  phoneNumber: null,
  providerPosition: { latitude: 40.710703, longitude: -73.963894 },
  rank: [ OrderFacilityDto.RankEnum.AvoidPi ],
  state: 'NY',
  status: 'Active',
  storeName: 'Grey Sloan Medical',
  zip: '11249'
};

export class SchedulesServiceMock {
  public configuration = {};

  public createSchedule(schedule: Schedule): Observable<Schedule> {
    return of();
  }

  public getListOfSchedules(pageSize?: number, restartRowId?: number, filter?: string): Observable<Array<ScheduleListItem>> {
    return of();
  }

  public updateSchedule(scheduleID: string, schedule: Schedule): Observable<Schedule> {
    return of();
  }
}

export const memberFormValues = {
  firstName: 'Jhon',
  lastName: 'Doe',
  dob: new Date(),
  primaryLangCode: 'EN',
  secondaryLangCode: 'ES',
  mbrStartDt: new Date(),
  mbrEndDt: new Date(),
  insuranceNum: '12354596',
  lobCode: 'DUAL',
  medicareId: 'A1234B543',
  medicaidId: '1234567534',
  isPrimary: true,
  genderCode: 'M',
  contacts: [{
    indvContactId: 1,
    contactTypeCode: 'phone',
    contactValue: 'string',
    primaryCntct: true,
    relToIndv: 'string'
  }],
  addresses: [{
    indvAddressId: 1,
    relToIndv: 'MEMBER',
    addressTypeCode: 'HOME',
    addressLine1: '100 Wall Street',
    addressLine2: 'Suite 2502 ',
    city: 'New York',
    state: 'NY',
    zip: '10005',
    useAsShipping: true,
    effectiveFromDt: '2020-09-24',
    effectiveToDt: '2020-09-24'
  }]
};

export const reportsMock = [{
  createdBy: 'John Smith',
  createdTS: '2021-09-29T13:39:10.113Z',
  execEndTs: '2021-09-29T13:39:15.576Z',
  execStartTs: '2021-09-29T13:39:10.235Z',
  execStatus: 'success',
  jobDesc: 'Get list of orders',
  jobId: 'jz0kzdhtnpbnbp6lfqa5x',
  modifiedBy: 'System Process',
  modifiedTS: '2021-09-29T13:39:15.576Z',
  taskName: 'rptOrderSummary',
  taskType: 'report'
}, {
  createdBy: 'John Smith',
  createdTS: '2021-09-28T15:27:17.392Z',
  execEndTs: '2021-09-28T15:27:22.547Z',
  execStartTs: '2021-09-28T15:27:17.464Z',
  execStatus: 'success',
  jobDesc: 'Get list of orders S & G test III',
  jobId: '0gbhvci5f6zyhgxjkw1tz',
  modifiedBy: 'System Process',
  modifiedTS: '2021-09-28T15:27:22.547Z',
  taskName: 'rptOrderSummary',
  taskType: 'report'
}];

export const templatesMock = [{
  createdBy: 'John Smith',
  createdTS: '2021-09-29T13:38:55.171Z',
  modifiedBy: 'John Smith',
  modifiedTS: '2021-09-29T13:38:55.171Z',
  scheduleCron: '',
  scheduleDesc: 'Get list of orders',
  scheduleId: '5ap7kqcgstyg22zxc643t',
  scheduleParams: { notCompleted: 'NO', orderStatusCodes: ['PENDING_ACCEPTANCE'] },
  taskName: 'rptOrderSummary'
}, {
  createdBy: 'John Smith',
  createdTS: '2021-09-28T13:58:24.59Z',
  modifiedBy: 'John Smith',
  modifiedTS: '2021-09-28T13:58:24.59Z',
  scheduleCron: '',
  scheduleDesc: 'Get list of orders S & G test III',
  scheduleId: '9l1ybc3y4o2mwefh0yatb',
  scheduleParams: { orderStatusCodes: ['DELIVERED', 'CANCELLED'] },
  taskName: 'rptOrderSummary'
}];

export class OrderProviderServiceMock {
  orderProviderLookup(orderState: OrderState, orderMemberInfo: MbrOrderReference): Observable<Array<ProviderFacilites>> {
    return of([]);
  }

  public getFacilities(apiFilters: ApiFilterRequest[], sort: GridSortDescriptor[]): Observable<Array<ProviderFacilites>> {
    return of([]);
  }
}

export const changeLogResponse = [
  {
    action: 'create',
    changeSeq: 1,
    changeTS: '2021-06-30T15:33:13.649Z',
    changedBy: 'System Process',
    changes: [],
    domainKey: 144,
    domainName: 'payerFamily',
    id: '60dc8eb93410ee1f4b021134',
    receivedTS: '2021-06-30T15:33:13.649Z'
  },
  {
    action: 'update',
    changeSeq: 2,
    changeTS: '2021-06-30T15:33:26.117Z',
    changedBy: 'System Process',
    changes: [
      {
        from: 'https://healthfirst.org/',
        path: [
          'webSite'
        ],
        to: 'https://healthfirst.org',
        type: 'update'
      }
    ],
    domainKey: 144,
    domainName: 'payerFamily',
    id: '60dc8ec63410ee1f4b021138',
    receivedTS: '2021-06-30T15:33:26.117Z'
  },
  {
    action: 'update',
    changeSeq: 3,
    changeTS: '2021-07-03T02:24:44.04Z',
    changedBy: 'System Process',
    changes: [
      {
        from: '2021-06-30',
        path: [
          'addresses',
          '0',
          'effectiveFromDt'
        ],
        to: '2021-07-02',
        type: 'update'
      }
    ],
    domainKey: 144,
    domainName: 'payerFamily',
    id: '60dfca6cac2822c1cb2da899',
    receivedTS: '2021-07-03T02:24:44.04Z'
  },
  {
    action: 'update',
    changeSeq: 4,
    changeTS: '2021-08-20T21:13:05.168Z',
    changedBy: 'Ashwin Sarma',
    changes: [
      {
        from: '2021-05-02',
        path: [
          'activeFromDt'
        ],
        to: '2021-05-03',
        type: 'update'
      },
      {
        from: '2021-07-02',
        path: [
          'addresses',
          '0',
          'effectiveFromDt'
        ],
        to: '2021-08-20',
        type: 'update'
      }
    ],
    domainKey: 144,
    domainName: 'payerFamily',
    id: '61201ae1ebb230d5cdb9fa33',
    receivedTS: '2021-08-20T21:13:05.512Z'
  }
];

export class PayerOrderServiceMock {
  public downloadBundleFile(bundleNumber: string, fileType: OrderBundleFileType, fileName: string): Observable<any> {
    return of();
  }

  public getIntakeOrdBundles(restartRowId?: number, pageSize?: number): Observable<Array<UIOrderBundle>> {
    return of([]);
  }

  public uploadIntakeOrdBundle(receivedTs: Date, payerFamilyId: any, fileList: Array<PrismFileInfo>): Observable<OrderBundle> {
    return of();
  }

  public getPaginatedIntakeOrdBundles(restartRowId?: number, pageSize?: number): Observable<GridData<UIOrderBundle>> {
    return of();
  }
}

export class OrderBundlesServiceMock {

  public configuration = {};

  public getIntakeOrdBundleFile(bundleNumber: string, fileType: 'RECEIVED_BUNDLE' | 'ERROR_REPORT' | 'SUCCESS_REPORT'): Observable<any> {
    return of();
  }

  public getIntakeOrdBundles(restartRowId?: number, pageSize?: number): Observable<Array<OrderBundle>> {
    return of();
  }


  public uploadIntakeOrdBundle(receivedTs?: string, payerFamilyId?: number, fileToUpload?: Blob): Observable<OrderBundle> {
    return of();
  }
}

export const orderBundleMock = [
  {
    bundleNumber: 'kxxgkmidg4c6ap76vurzf',
    bundleStatus: 'PREPROCESSED',
    createdBy: 'SYS',
    createdTs: '2021-10-22T04:19:31.739Z',
    failedPayerOrders: 0,
    modifiedBy: 'SYS',
    modifiedTs: '2021-10-22T04:57:48.207Z',
    payerFamId: 144,
    receivedDocId: '9fb7e477-b7a6-4caa-8899-b39023db3c68',
    receivedTs: '2014-07-16T20:55:46Z',
    resultDocIds: [
      {
        docId: '9fb7e477-b7a6-4caa-8899-b39023db3c68',
        resultType: 'SUCCESS_REPORT'
      },
      {
        docId: '9fb7e477-b7a6-4caa-8899-b39023db3c68',
        resultType: 'ERROR_REPORT'
      }
    ],
    successPayerOrders: 3,
    totalPayerOrders: 3
  },
  {
    bundleNumber: '7s5709gm6uaba23tg6jay',
    bundleStatus: 'PREPROCESSED',
    createdBy: '1',
    createdTs: '2021-10-28T14:07:45.984Z',
    failedPayerOrders: 1,
    modifiedBy: 'SYS',
    modifiedTs: '2021-10-28T14:30:07.795Z',
    payerFamId: 17,
    receivedDocId: 'ad0478f8-ca0b-4bcc-89ed-9da182e8bcb0',
    receivedTs: '2021-10-14T03:00:00Z',
    resultDocIds: [],
    successPayerOrders: 0,
    totalPayerOrders: 1
  }
];

export class PayersLookupServiceMock {
  getPayerFamilyDataForCombobox(filterStr: string): Observable<Array<SearchDataList>> {
    return of();
  }
}

export class TransitionsLookupServiceMock {
  public getTransitionsForCombobox(filter?: string): Observable<SearchDataList[]> {
    return of();
  }
}

export const transitionsMock: Transition[] = [{
  createdBy: 'Aliaks Tarasevich',
  createdTs: '2021-11-18T22:45:47.887324Z',
  isActive: false,
  modifiedBy: 'Aliaks Tarasevich',
  modifiedTs: '2021-11-18T22:45:47.887324Z',
  ownerId: 34,
  ownerName: 'John Bresanovich',
  startDate: '2021-11-01',
  statusCode: 'COMPLETE',
  transitionDesc: 'My Cool Transition',
  transitionId: 16
},
{
  createdBy: 'Ashwin Sarma',
  createdTs: '2021-11-18T19:46:46.831538Z',
  isActive: true,
  modifiedBy: 'John Smith',
  modifiedTs: '2021-11-18T19:46:46.831538Z',
  ownerId: 61,
  ownerName: 'Ashwin Sarma',
  startDate: '2021-11-01',
  statusCode: 'PLANNING',
  transitionDesc: 'New Transition Test',
  transitionId: 15
}];
export const mdmListItem: MdmListItem = {
  attributes: [
    {
      isRequired: false,
      listAttrDataType: 'S',
      listAttrId: 15,
      listAttrLabel: 'Attribute String',
      listAttrName: 'attr_string'
    },
    {
      isRequired: false,
      listAttrDataType: 'B',
      listAttrId: 16,
      listAttrLabel: 'Attribute Boolean',
      listAttrName: 'attr_bool'
    },
    {
      isRequired: false,
      listAttrDataType: 'DT',
      listAttrId: 17,
      listAttrLabel: 'Attribute Date',
      listAttrName: 'attr_date'
    },
    {
      isRequired: false,
      listAttrDataType: 'I',
      listAttrId: 18,
      listAttrLabel: 'Attribute Integer',
      listAttrName: 'attr_int'
    }
  ],
  contexts: [
    {
      listCtxDesc: 'Payer Detail',
      listCtxId: 21,
      listCtxName: 'PAYER_DTLS'
    },
    {
      listCtxDesc: 'Member Detail',
      listCtxId: 22,
      listCtxName: 'MEMBER_DTLS'
    }
  ],
  isRoList: false,
  isSysList: false,
  listTypeDesc: 'List of test types',
  listTypeId: 45,
  listTypeName: 'TEST_LIST',
  listTypeStartDt: '2020-01-01T00:00:00Z'
};


export const mdmListItemDtl: ListItemDtl = {
  attributes: [
    {
      listAttrId: 15,
      listAttrName: 'attr_string',
      listDtlAttrId: 113,
      listDtlAttrValue: 'str value'
    },
    {
      listAttrId: 16,
      listAttrName: 'attr_bool',
      listDtlAttrId: 114,
      listDtlAttrValue: 'true'
    },
    {
      listAttrId: 17,
      listAttrName: 'attr_date',
      listDtlAttrId: 116,
      listDtlAttrValue: '2021-11-23'
    },
    {
      listAttrId: 18,
      listAttrName: 'attr_int',
      listDtlAttrId: 115,
      listDtlAttrValue: '15'
    }
  ],
  contexts: [
    {
      listCtxId: 21,
      listDtlCtxId: 85
    }
  ],
  listDtlCode: 'TEST2',
  listDtlDesc: 'Test Two',
  listDtlEndDt: '2021-11-25',
  listDtlId: 72888,
  listDtlStartDt: '2021-11-21',
  listTypeId: 45
};

export class MatDialogRefMock {
  close(value = true): void { }
}

export class PayersTreeGridServiceMock {
  public getPayersTree(lobIds: number[]): Observable<TreeGridItem[]> {
    return of([]);
  }

  public getSelectedPayers(lobIds: number[]): Observable<TreeGridSelections> {
    return of();
  }

  public getPayerFamilies(
    sort: string,
    filter?: ApiFilterRequest[],
    restartRowNum: number = 0,
    pageSize: number = 200
  ): Observable<TreeGridItem[]> {
    return of([]);
  }

  public getPayerPlansForFamily(
    famId: number,
    sort?: string,
    filter?: string,
    restartRowId?: number,
    pageSize?: number
  ): Observable<TreeGridItem[]> {
    return of([]);
  }

  public getPayerAccountsForPlan(
    planId: number,
    sort?: string,
    pageSize?: number
  ): Observable<TreeGridItem[]> {
    return of([]);
  }

  public getPayerLobsForAcct(acctId: number): Observable<TreeGridItem[]> {
    return of([]);
  }

  public searchPayers(
    sort?: string,
    filter?: ApiFilterRequest[],
    restartRowId: number = 0,
    pageSize: number = 200
  ): Observable<TreeGridItem[]> {
    return of([]);
  }
}

export class PayersGridServiceMock {

  public lazyLoadPlanList(
    payer: ParsedPayer, sort?: string, filter?: string, restartRowId?: number, pageSize?: number
  ): Observable<ParsedPayer[]> {
    return of();
  }

  public lazyLoadAccountsList(
    payer: ParsedPayer, sort?: string, filter?: string, restartRowId?: number, pageSize?: number
  ): Observable<ParsedPayer[]> {
    return of();
  }

  public lazyLoadLobList(payer: ParsedPayer, sort?: string): Observable<ParsedPayer[]> {
    return of();
  }

  public payerSearch(sort?: string, filter?: string, restartRowId: number = 0, pageSize: number = 200): Observable<Array<ParsedPayer>> {
    return of();
  }

  public getPayersFamilyList(
    sort: string,
    searchString: string = null,
    restartRowNum: number = 0,
    pageSize: number = 200,
  ): Observable<ParsedPayer[]> {
    return of();
  }

  public getPayerPlans(
    payerFamId: number, sort?: string, filter?: string, restartRowId?: number, pageSize?: number
  ): Observable<Array<ParsedPayer>> {
    return of();
  }

  public getPayerAccountsList(familyId: number, payerPlanId: number, sort: string, pageSize: number = 200): Observable<Array<ParsedPayer>> {
    return of();
  }

  public getPayersLobList(
    familyId: number, payerPlanId: number, accountId: number, sort?: string, pageSize: number = 200
  ): Observable<Array<ParsedPayer>> {
    const sortStr = sort === null ? JSON.stringify([{ property: 'name', dir: 'asc' }]) : null;

    return of();
  }

  public navigateToPayerProfile(selectedPayer: ParsedPayer): void { }

  public validateAccessAndNavigate(accessRoute: string, route: string, extras: any = {}): void { }
}

export class BreadcrumbsServiceMock {

  public items$: Observable<BreadCrumbItemI[]> = of([]);

  setItems(items: BreadCrumbItemI[]): void { }

  selectItem(item: BreadCrumbItemI): void { }

  reset(): void { }
}

export class MalPracticeClaimServiceMock {
  public getMalpracticeClaimByIdOpenApi(organizationId: number, ClaimId: number) {
    return of({});
  }
  public getMalpracticeClaimsByOrganizationIdOpenApi(organizationId: number) {
    return of({});
  }
  public getMalpracticeClaimsByOrganizationId(organizationId: number): Observable<Array<MalpracticeClaimViewModel>> {
    return of([]);
  }
}
export class DownloadServiceMock {
  public download(obj: any, name: string, options?: BlobPropertyBag): void { }
}

export class SchedulerServiceMock {
  getJobs(pageSize?: number, restartRowId?: number, filter: ApiFilterRequest[] = [], viewAll: boolean = false): Observable<JobGridListResponse> {
    return of();
  }

  getJobDetailsById(jobId: string): Observable<JobGridListDtl> {
    return of();
  }
}

export class MsalServiceMock {

  acquireTokenPopup(request: AuthorizationUrlRequest): Observable<AuthenticationResult> {
    return of();
  }
  acquireTokenRedirect(request: RedirectRequest): Observable<void> {
    return of();
  }
  acquireTokenSilent(silentRequest: SilentRequest): Observable<AuthenticationResult> {
    return of();
  }
  getAccountByUsername(userName: string): AccountInfo {
    return null;
  }
  getAllAccounts(): AccountInfo[] {
    return [];
  }
  getActiveAccount(): AccountInfo | null {
    return null;
  }
  setActiveAccount(account: AccountInfo | null): void { }
  getLogger() { }
  handleRedirectObservable(): Observable<AuthenticationResult> {
    return of();
  }
  loginPopup(request?: AuthorizationUrlRequest): Observable<AuthenticationResult> {
    return of();
  }
  loginRedirect(request?: RedirectRequest): Observable<void> {
    return of();
  }
  logout(logoutRequest?: EndSessionRequest): Observable<void> {
    return of();
  }
  logoutRedirect(logoutRequest?: EndSessionRequest): Observable<void> {
    return of();
  }
  ssoSilent(request: AuthorizationUrlRequest): Observable<AuthenticationResult> {
    return of();
  }
}

export class UtilitiesServiceMock {
  public getProp(object: unknown, keys, defaultVal?: any): any { }
  public navigateToRoute(url: string): void { }
  public getTimeAMPMFromDateString(dateTimeString: string): any { }
}

export class WidgetSharedServiceMock {
  public timelyCatCode: string[] = ['PAST_TIMELY_FILING', 'URGENT_TIMELY_FILING', 'TIMELY_FILING', 'NO_ATTENTION_NEEDED'];
}
export class McqServiceMock {
  getTeamsList(restartRowNum: number = 0, pageSize: number = 50, filter: string = ''): Observable<TeamsListResponse> {
    return of();
  }

  getTeamById(teamId: number): Observable<Team> {
    return of();
  }

  getIntakeRuleByID(teamId: number): Observable<any> {
    return of();
  }

  updateTeam(teamId: number, body: Team): Observable<Team> {
    return of();
  }

  createTeam(body: Team): Observable<Team> {
    return of();
  }

  getClaimsList(restartRowNum: number = 0, pageSize: number = 50, filter: string = ''): Observable<ClaimsListResponse> {
    return of();
  }

  getTeamUser(teamId: number, teamUserId: number): Observable<TeamUser> {
    return of();
  }

  getTeamUserList(teamId: number, restartRowId: number, pageSize: number, searchString: string): Observable<Array<TeamUser>> {
    return of();
  }

  public getTeamsListForCombobox(restartRowNum: number = 0, pageSize: number = 50, filter: string = ''): Observable<Array<SearchDataList>> {
    return of(null);
  }
  getTeamsByIdForCombobox(entityId: number): Observable<SearchDataList> {
    return of();
  }

  public getRuleIntakeListForCombobox(restartRowNum: number = 0, pageSize: number = 50,
                                      filterStr: string = ''): Observable<Array<SearchDataList>> {
    return of();
  }

  getClaimIntakeFields(restartRowNum: number, pageNumber: number): Observable<IntakeRuleFields[]> {
    return of();
  }

  getClaimIntakeFieldsForFieldId(fieldId: number): Observable<IntakeRuleFields[]> {
    return of();
  }

  getClaimByID(claimId: number): Observable<ClaimDtl> {
    return of({
      claimID: claimId,
      claimNum: '100',
      claimDoS: '2021-01-14T03:08:54.354089Z',
      payerFamID: 1,
      providerOrgID: 2,
      providerFacID: 3,
      payerLobID: 4,
      chargeAmnt: 200,
      balanceAmnt: 300,
      claimDueDT: '2021-01-14T03:08:54.354089Z',
      createdDT: '2021-01-14T03:08:54.354089Z',
      mcqDecisionCode: '',
      claimOwnerCode: '',
      createdBy: 'ABC',
      createdTs: '2021-01-14T03:08:54.354089Z',
      modifiedBy: 'ABC',
      modifiedTs: '2021-01-14T03:08:54.354089Z',
      workBasketName: 'All Catch',
      claimRuleSetName: 'Balance Amount',
      appealSubmissionLevel: 'second',
      canExcludeManualRemark: true,
      isBot: false,
      isIpr: true,
      isPaidExclusion: true,
      isSettlementClaim: false,
      isPendingAdjustment: false,
      lastModifiedTS: '2022-01-02'
    });
  }

  getClaimNotesByID(claimId: number): Observable<Array<Notes>> {
    return of([{
      note: 'this is a note',
      noteType: 'type',
      claimID: claimId,
      noteID: 5,
      createdBy: 'ABC',
      createdTs: '2021-01-14T03:08:54.354089Z',
      modifiedBy: 'ABC',
      modifiedTs: '2021-01-14T03:08:54.354089Z',
    }]);
  }

  getRuleIntakeList(restartRowNum: number = 0, pageSize: number = 50, filter: string = ''): Observable<IntakeRuleResponse> {
    return of();
  }

  getCurrentUser(): Observable<TeamUser> {
    return of();
  }

  getClaimsCount(): Observable<any> {
    return of();
  }

  public getTeamChanges(teamId: number): Observable<Array<Change>> {
    return of([]);
  }

  public getRuleSetChanges(ruleSetId: number): Observable<Array<Change>> {
    return of([]);
  }

  public completeClaims(request: CompleteClaims): Observable<any> {
    return of();
  }
  public getClaimsTimelyByHourCount(): Observable<any> {
    return of();
  }

  public getClaimsTimelyByTeamCount(teamIds: Array<number>): Observable<any> {
    return of();
  }
  public getClaimsTimelyCount(): Observable<any> {
    return of();
  }
  public getClaimsFifoTimelyCount(): Observable<any> {
    return of();
  }
  public getClaimsFifoTimelyByTeamCount(): Observable<any> {
    return of();
  }
  public getUserClaimWorkProductivity(userId: number): Observable<any> {
    return of();
  }
  public getTeamClaimWorkProductivity(teamId: number): Observable<any> {
    return of();
  }



}

export class McqListFilterServiceMock {
  getColumns(): Array<IntegraSearchConfigColumn> {
    return [];
  }
}

export const organizationsMock: ResponseModelGeneric<OrgDto> = {
  count: '753',
  list: [
    {
      organizationId: 5,
      organizationLegalName: 'ABCDs Legal Pharmacy1',
      organizationDBAName: 'ABCD Legal Pharmacy 1',
      organizationStatus: 'terminated',
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      nextReCredDate: '2021-10-02T00:00:00',
      phone: '(456) 456-5461',
      taxId: null,
      daysTillNextCred: -18,
      contractTypeDescription: 'DMEnsion,Integra Partners,THPP - MA',
      contractTypeCode: 'CON,DME,THP',
      verificationStatus: null,
      orderManagementStatus: null,
      facilities: [],
      totalFacilities: 25,
      fax: null,
      email: 'govind.thakwani@datatusk.com',
      createdBy: null,
      createdDate: '2019-03-11T14:33:14.88',
      lastModifiedBy: 'Michael Cutrer',
      lastModifiedDate: '2023-04-24T03:23:41.597'
    },
    {
      organizationId: 7,
      organizationLegalName: 'Legal Name - 43',
      organizationDBAName: 'South Wood Rita Pharmacy',
      organizationStatus: 'active',
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      nextReCredDate: null,
      phone: '(908) 862-4444',
      taxId: '12-3777777',
      daysTillNextCred: 0,
      contractTypeDescription: 'Integra Partners',
      contractTypeCode: 'CON',
      verificationStatus: null,
      orderManagementStatus: 'Enabled',
      facilities: [],
      totalFacilities: 0,
      fax: '2382311574',
      email: 'govind.thakwani@datatusk.com',
      createdBy: 'System Admin',
      createdDate: '2019-04-11T00:00:00',
      lastModifiedBy: 'Michael Cutrer',
      lastModifiedDate: '2023-04-19T20:09:40.837'
    }
  ]
};

export const staffRoleMock: Array<StringLookupTypeTemplate> = [
  {
    description: 'Credentialing',
    code: 'SA',
    category: 'Staff'
  },
  {
    description: 'Billing System Provider Admin',
    code: 'SPP',
    category: 'Billing'
  },
  {
    description: 'Customer Service',
    code: 'SD',
    category: 'Staff'
  },
  {
    description: 'Driver',
    code: 'SCC',
    category: 'Staff'
  },
  {
    description: 'In-House Technician',
    code: 'IHT',
    category: 'Staff'
  },
  {
    description: 'Order Management Admin',
    code: 'OMA',
    category: 'Order'
  },
  {
    description: 'Provider Order Manager',
    code: 'SOM',
    category: 'Order'
  },
  {
    description: 'Provider Order Viewer',
    code: 'SOV',
    category: 'Order'
  },
  {
    description: 'Designated Signatory',
    code: 'DS',
    category: 'Staff'
  },
  {
    description: 'Billing System Office Admin',
    code: 'BOA',
    category: 'Billing'
  },
  {
    description: 'Billing System Provider Staff',
    code: 'BPS',
    category: 'Billing'
  }
];

export const organizationDetailsMock: OrganizationViewModel = {
  organizationId: 5,
  legalName: 'Legal',
  dbaName: 'South Wood Rita Pharmacy',
  account: null,
  accountId: 47,
  providerType: 'ONP',
  billingTypeCd: null,
  taxId: '123777777',
  email: 'govind.thakwani@datatusk.com',
  sfRecordId: '123456789012345',
  queId: null,
  sfAccountNumberIP: null,
  phone: '9088624444',
  fax: '2382311574',
  initalCredDate: null,
  nextReCredDate: null,
  lastCredentialDate: null,
  lastReCredDate: null,
  isActive: true,
  inActiveDate: null,
  inActiveReason: null,
  status: 'ACT',
  statusList: null,
  isOwnerOnPCertified: false,
  hasAccreditation: false,
  hasParent: false,
  hasSubcontractor: false,
  hasPharmacy: false,
  hasMalpracticeClaim: false,
  hasDisclosedSubcontractors: null,
  medicarePercentage: 10,
  medicaidPercentage: 20,
  cashPercentage: 10,
  hmoPercentage: 30,
  contractTypeCd: null,
  feeSchduleId: null,
  timestamp: 'AAAAAAAvirE=',
  hasPractitioner: false,
  formattedAddress: null,
  isAnnualFeeWaived: false,
  appMasterValue: null,
  address: [],
  allAddress: [],
  directInsuranceContract: [],
  directInsuranceContractArr: [],
  billingSoftware: [],
  billingSoftwareArr: [],
  billingType: [],
  billingTypeArr: [],
  contractType: [
    {
      orgContractId: 1063,
      organizationId: 7,
      contractTypeCd: 'CON',
      createdBy: 9,
      createdDate: '2021-07-08T17:26:16.993',
      lastModifiedBy: 741,
      lastModifiedDate: '2022-05-31T13:16:58.267',
      timestamp: 'AAAAAAAd3YI=',
      effectiveDate: null,
      expirationDate: '2023-08-31T00:00:00',
      isActive: true,
      sfrecordId: null,
      ny: true,
      multiState: null,
      organization: undefined,
      providerContractSignOffRequired: false,
      entityState: 'Detached'
    }
  ],
  orgContract: [
    {
      orgContractId: 1063,
      organizationId: 7,
      contractTypeCd: 'CON',
      createdBy: 9,
      createdDate: '2021-07-08T17:26:16.993',
      lastModifiedBy: 741,
      lastModifiedDate: '2022-05-31T13:16:58.267',
      timestamp: null,
      effectiveDate: null,
      expirationDate: '2023-08-31T00:00:00',
      isActive: true,
      sfrecordId: null,
      ny: true,
      multiState: null,
      organization: undefined,
      providerContractSignOffRequired: false,
      entityState: 'Detached'
    }
  ],
  accountOrg: [
    {
      accountOrgId: 252,
      accountId: 47,
      organizationId: 7,
      createdBy: 9,
      createdDate: '2020-06-18T16:15:08.463',
      lastModifiedBy: 9,
      lastModifiedDate: '2020-06-18T16:15:08.463',
      timestamp: 'AAAAAAADVZo=',
      isActive: true,
      account: undefined,
      organization: undefined,
      entityState: 'Detached'
    }
  ],
  contractTypeArr: [
    'CON'
  ],
  entity: [],
  license: [],
  accreditation: [],
  state: {
    stateCd: '-1',
    stateName: null,
    isActive: null,
    totalRecords: 0,
    createdDate: null,
    createdBy: null,
    lastModifiedDate: null,
    lastModifiedBy: null,
    isNew: false,
    isDeleted: false,
    isChanged: false,
    buttonName: null,
    viewMode: null,
    reqSource: null,
    appId: 0,
    invokedFrom: 'none',
    newFacilityId: 0
  },
  attachment: {
    attachmentId: 0,
    tableName: null,
    tableKey: 0,
    attachedFile: null,
    attachedDate: null,
    fileType: null,
    isDeleted: null,
    deletedBy: null,
    deletedDate: null,
    createdBy: 0,
    createdDate: '0001-01-01T00:00:00',
    lastModifiedBy: 0,
    lastModifiedDate: '0001-01-01T00:00:00',
    timestamp: null,
    attachedTypeCd: null,
    orignalFileName: null,
    entityState: 'Detached'
  },
  isStaffBgchecked: null,
  attachment2: {
    attachmentId: 0,
    tableName: null,
    tableKey: 0,
    attachedFile: null,
    attachedDate: null,
    fileType: null,
    isDeleted: null,
    deletedBy: null,
    deletedDate: null,
    createdBy: 0,
    createdDate: '0001-01-01T00:00:00',
    lastModifiedBy: 0,
    lastModifiedDate: '0001-01-01T00:00:00',
    timestamp: null,
    attachedTypeCd: null,
    orignalFileName: null,
    entityState: 'Detached'
  },
  statusColor: null,
  showFacilityTaxId: true,
  orgFeeSchedulesViewModel: [],
  appTags: null,
  appTagsArr: [],
  orgTags: [],
  tagName: null,
  isOrgStatusLocked: true,
  isIPCon: null,
  overrideSuspendOrder: false,
  overrideSuspendClaim: false,
  verificationStatus: 'ACT',
  isOrderManagement: null,
  orderManagementStatusCd: 'SEN',
  orderManagementStatusName: 'Enabled',
  ormFacilities: [
    {
      facilityId: 1134,
      organizationId: 7,
      countyId: 1846,
      storeName: 'test facility 1234 - NPI: 1234567091',
      startDate: '2020-06-24T00:00:00',
      endDate: '2023-04-19T00:00:00',
      phone: '5326233467',
      fax: '5555555555',
      npi: '1234567091',
      medicareId: '363463464',
      isHandicapAccessible: true,
      isVerified: true,
      isMainLocation: true,
      isPickup: false,
      isDelivery: false,
      isDropShip: false,
      isAllStatesServe: false,
      hasMalpracticeClaim: null,
      isActive: true,
      sfRecordId: '1234456678890',
      queId: null,
      latestAddress: null,
      organizationName: null,
      legalName: null,
      dbaName: null,
      organization: undefined,
      address: null,
      facilityHrs: [],
      facilityLanguage: [],
      facilityLanguageArr: null,
      facilitySuretyBond: [],
      facilityMedicaid: [],
      facilityCoi: [],
      license: null,
      accreditation: [],
      facilityDetail: undefined,
      taxId: '327373737',
      associatedOrganizationDBAName: '1003 orga DBA',
      associatedOrganizationLegalName: '1003 orga legal2',
      orgStatus: null,
      orgStatusDesc: null,
      facilityMedicaidVM: null,
      isOrderManagement: true,
      ranking: null,
      status: null,
      incompleteSteps: 0,
      ipContract: 0,
      signedOffApp: 0,
      verStatus: false,
      applicationSteps: 0,
      totalRecords: 0,
      createdDate: '2020-06-03T15:28:11.46',
      createdBy: 249,
      lastModifiedDate: '2023-04-21T13:54:34.373',
      lastModifiedBy: 674,
      isNew: false,
      isDeleted: false,
      isChanged: false,
      buttonName: null,
      viewMode: null,
      reqSource: null,
      appId: 0,
      invokedFrom: 'none',
      newFacilityId: 0
    }
  ],
  ormFacilityArr: null,
  isBase: null,
  isAvoidProvider: null,
  isAvoidSLA: null,
  avoidProviderFacilityArr: null,
  avoidSLAFacilityArr: null,
  ediStatusCd: null,
  ediStatusName: null,
  ediFacilityArr: null,
  keyNotes: null,
  baseFacilityArr: null,
  verificationId: 0,
  suspensionDefferedDate: null,
  isMerged: null,
  createdByName: 'System Admin',
  lastModifiedByName: 'Michael Cutrer',
  totalRecords: 0,
  createdDate: '2019-04-11T00:00:00',
  createdBy: 1,
  lastModifiedDate: '2023-04-19T20:09:40.837',
  lastModifiedBy: 941,
  isNew: false,
  isDeleted: false,
  isChanged: false,
  buttonName: null,
  viewMode: 'readonly',
  reqSource: null,
  appId: 0,
  invokedFrom: 'none',
  newFacilityId: 0
};

export const facilitiesMock: OrgDto[] = [
  {
    organizationId: 804,
    organizationLegalName: 'Sprint 54 Legal/1',
    organizationDBAName: 'Sprint 54 DBA/1',
    organizationStatus: 'active',
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    nextReCredDate: '2025-11-07T00:00:00',
    phone: '(033) 656-6465',
    taxId: '52-5252352',
    daysTillNextCred: 34,
    contractTypeDescription: 'Integra Partners',
    contractTypeCode: 'CON',
    verificationStatus: 'Verified',
    orderManagementStatus: 'Enabled',
    facilities: [
      {
        keyNote: null,
        orderKeyContacts: [],
        productsServiced: [],
        npi: '1220997211',
        address: 'Address  TC, CA 23232',
        mainLocation: 'Yes',
        organizationId: 804,
        organizationLegalName: '',
        organizationDBAName: '',
        organizationStatus: '',
        facilityId: 1605,
        storeName: 'Sprint 54/1 fac',
        status: 'Verified',
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        county: null,
        distanceFromMbr: 0,
        phoneNumber: null,
        faxNumber: null,
        rank: [],
        businessHrs: [],
        languages: [],
        deliveryMode: [],
        ormEnabled: null
      },

    ],
    totalFacilities: 1,
    fax: null,
    email: null
  }
];

export const organizationContractMockedResponse: OrganizationContractResponseModel = {
  list: [
    {
      contractId: 898,
      contractTypeCd: 'DME',
      contractTypeDescription: 'DMEnsion',
      startDate: null,
      endDate: '2020-12-15T00:00:00',
      activeState: false
    },
    {
      contractId: 897,
      contractTypeCd: 'DME',
      contractTypeDescription: 'DMEnsion',
      startDate: null,
      endDate: '2020-12-15T00:00:00',
      activeState: false
    },
    {
      contractId: 896,
      contractTypeCd: 'DME',
      contractTypeDescription: 'DMEnsion',
      startDate: null,
      endDate: '2020-12-15T00:00:00',
      activeState: false
    },
    {
      contractId: 894,
      contractTypeCd: 'DME',
      contractTypeDescription: 'DMEnsion',
      startDate: null,
      endDate: '2020-12-15T00:00:00',
      activeState: false
    },
    {
      contractId: 893,
      contractTypeCd: 'DME',
      contractTypeDescription: 'DMEnsion',
      startDate: null,
      endDate: '2020-12-15T00:00:00',
      activeState: false
    },
    {
      contractId: 892,
      contractTypeCd: 'DME',
      contractTypeDescription: 'DMEnsion',
      startDate: null,
      endDate: '2020-12-15T00:00:00',
      activeState: false
    },
    {
      contractId: 891,
      contractTypeCd: 'DME',
      contractTypeDescription: 'DMEnsion',
      startDate: null,
      endDate: '2020-12-15T00:00:00',
      activeState: false
    },
    {
      contractId: 890,
      contractTypeCd: 'DME',
      contractTypeDescription: 'DMEnsion',
      startDate: null,
      endDate: '2020-12-15T00:00:00',
      activeState: false
    },
    {
      contractId: 889,
      contractTypeCd: 'DME',
      contractTypeDescription: 'DMEnsion',
      startDate: null,
      endDate: '2020-12-15T00:00:00',
      activeState: false
    },
    {
      contractId: 629,
      contractTypeCd: 'CON',
      contractTypeDescription: 'Integra Partners',
      startDate: '2020-08-04T00:00:00',
      endDate: '2020-10-22T00:00:00',
      activeState: false
    },
    {
      contractId: 543,
      contractTypeCd: 'THP',
      contractTypeDescription: 'THPP - MA',
      startDate: '2020-04-06T00:00:00',
      endDate: '2020-04-28T00:00:00',
      activeState: false
    },
    {
      contractId: 542,
      contractTypeCd: 'DME',
      contractTypeDescription: 'DMEnsion',
      startDate: '2020-04-15T00:00:00',
      endDate: '2020-04-23T00:00:00',
      activeState: false
    }
  ],
  count: '12'
};

export const orgContractsByOrg: OrgContractViewModelResponseModelByType =
{
  listOfItems:
    [
      {
        orgContractId: 542,
        organizationId: 5,
        contractTypeCd: 'DME',
        createdBy: 10,
        createdDate: '2020-04-16T17:34:02.123',
        lastModifiedBy: 886,
        lastModifiedDate: '2023-05-03T16:34:15.05',
        effectiveDate: '2020-04-15T00:00:00',
        expirationDate: '2020-04-23T00:00:00',
        isActive: false,
        sfrecordId: null,
        ny: null,
        multiState: null,
        providerContractSignOffRequired: null,
      },
      {
        orgContractId: 543,
        organizationId: 5,
        contractTypeCd: 'THP',
        createdBy: 10,
        createdDate: '2020-04-16T17:34:25.79',
        lastModifiedBy: 886,
        lastModifiedDate: '2023-05-03T16:34:15.05',
        effectiveDate: '2020-04-06T00:00:00',
        expirationDate: '2020-04-28T00:00:00',
        isActive: false,
        sfrecordId: null,
        ny: null,
        multiState: null,
        providerContractSignOffRequired: null
      },
      {
        orgContractId: 629,
        organizationId: 5,
        contractTypeCd: 'CON',
        createdBy: 10,
        createdDate: '2020-08-05T19:35:47.047',
        lastModifiedBy: 886,
        lastModifiedDate: '2023-05-03T16:34:15.05',
        effectiveDate: '2020-08-04T00:00:00',
        expirationDate: '2020-10-22T00:00:00',
        isActive: false,
        sfrecordId: null,
        ny: null,
        multiState: true,
        providerContractSignOffRequired: null
      }
    ],
  count: 3
};

export const contractTypes: Dictionary<StringLookupTypeTemplate> = {
  CON: {
    code: 'CON',
    description: 'Integra Partners',
    category: null
  },
  DME: {
    code: 'DME',
    description: 'DMEnsion',
    category: null
  },
  THP: {
    code: 'THP',
    description: 'THPP - MA',
    category: null
  }
};

export const addressTypes: Dictionary<StringLookupTypeTemplate> =
{
  CA: {
    code: 'CA ',
    description: 'Corporate Address',
    category: null
  },
  BA: {
    code: 'BA ',
    description: 'Billing Address',
    category: null
  },
  PA: {
    code: 'PA ',
    description: 'Payment Address',
    category: null
  }
};

export const addressByOrganizationIdMock: Array<Address> = [{
  addressId: 845,
  tableName: 'Organization',
  tableKey: 5,
  addressTypeCd: 'CA ',
  address1: 'ANC',
  address2: 'ANJ',
  city: 'AS',
  state: 'IN',
  zip: '10011',
  latitude: null,
  longitude: null,
  startDate: '2023-05-03T00:00:00',
  endDate: '2019-11-19T00:00:00',
  createdBy: 0,
  createdDate: '2019-11-19T15:53:24.63',
  lastModifiedBy: 0,
  lastModifiedDate: '2023-05-03T16:34:12.1',
  timestamp: 'AAAAAAAwKbE=',
  addressLatLngLog: [],
  entityState: 'Detached'
},
{
  addressId: 846,
  tableName: 'Organization',
  tableKey: 5,
  addressTypeCd: 'BA ',
  address1: 'ANC',
  address2: 'ANJ',
  city: 'AS',
  state: 'IN',
  zip: '10011',
  latitude: null,
  longitude: null,
  startDate: '2023-05-03T00:00:00',
  endDate: '2019-11-19T00:00:00',
  createdBy: 0,
  createdDate: '2019-11-19T15:53:24.63',
  lastModifiedBy: 0,
  lastModifiedDate: '2023-05-03T16:34:12.1',
  timestamp: 'AAAAAAAwKbI=',
  addressLatLngLog: [],
  entityState: 'Detached'
}];

export const organizationFeeScheduleResponse: Array<OrgFeeScheduleViewModel> = [
  {
    orgFeeScheduleId: 8,
    organizationId: 5,
    queFsid: 1224,
    feeScheduleName: 'Respiratory Services of Western New York,',
    carrierId: 0,
    carrierName: null,
    effectiveDate: '2007-01-01T00:00:00',
    expireDate: '2020-10-22T00:00:00',
    included: true,
    sortBy: null,
    queId: null,
    totalRecords: 3,
    createdDate: '2020-08-06T23:49:07.917',
    createdBy: 10,
    lastModifiedDate: '2023-04-18T17:52:37.773',
    lastModifiedBy: 10,
    isNew: false,
    isDeleted: false,
    isChanged: false,
    buttonName: null,
    viewMode: null,
    reqSource: null,
    appId: 0,
    invokedFrom: 'none',
    newFacilityId: 0
  },
  {
    orgFeeScheduleId: 9,
    organizationId: 5,
    queFsid: 1220,
    feeScheduleName: 'Gaga Pharmacy',
    carrierId: 0,
    carrierName: null,
    effectiveDate: '2015-11-16T00:00:00',
    expireDate: '2019-11-16T00:00:00',
    included: true,
    sortBy: null,
    queId: null,
    totalRecords: 3,
    createdDate: '2020-08-07T13:43:50.97',
    createdBy: 10,
    lastModifiedDate: '2020-08-10T22:57:44.37',
    lastModifiedBy: 10,
    isNew: false,
    isDeleted: false,
    isChanged: false,
    buttonName: null,
    viewMode: null,
    reqSource: null,
    appId: 0,
    invokedFrom: 'none',
    newFacilityId: 0
  },
  {
    orgFeeScheduleId: 10,
    organizationId: 5,
    queFsid: 1186,
    feeScheduleName: 'POS 5%',
    carrierId: 0,
    carrierName: null,
    effectiveDate: '2007-01-01T00:00:00',
    expireDate: '2020-10-22T00:00:00',
    included: true,
    sortBy: null,
    queId: null,
    totalRecords: 3,
    createdDate: '2020-08-07T15:49:30.013',
    createdBy: 10,
    lastModifiedDate: '2020-08-10T22:57:44.37',
    lastModifiedBy: 10,
    isNew: false,
    isDeleted: false,
    isChanged: false,
    buttonName: null,
    viewMode: null,
    reqSource: null,
    appId: 0,
    invokedFrom: 'none',
    newFacilityId: 0
  }
];

export const addressesMock = [{
  addressId: 2388,
  address: 'new address new address new city IL 13455',
  effectiveDate: '2023-05-06T00:00:00',
  expirationDate: null,
  addressType: 'Corporate Address',
  addressTypeCd: 'CA ',
  addressLine1: 'new address',
  addressLine2: 'new address',
  addressLine3: 'new city, IL 13455',
  zip: '13455',
  state: 'IL',
  city: 'new city'
},
{
  addressId: 2412,
  address: 'address 1 address 2 city DC 33221',
  effectiveDate: '2023-05-06T00:00:00',
  expirationDate: null,
  addressType: 'Billing Address',
  addressTypeCd: 'BA ',
  addressLine1: 'address 1',
  addressLine2: 'address 2',
  addressLine3: 'city, DC 33221',
  zip: '33221',
  state: 'DC',
  city: 'city'
},
{
  addressId: 2419,
  address: '232323 3232323 amyland CA 56565',
  effectiveDate: '2023-05-03T00:00:00',
  expirationDate: null,
  addressType: 'Payment Address',
  addressTypeCd: 'PA ',
  addressLine1: '232323',
  addressLine2: '3232323',
  addressLine3: 'amyland, CA 56565',
  zip: '56565',
  state: 'CA',
  city: 'amyland'
}];

export const organizationsMockWithHttpResp: HttpResponse<Array<OrgDto>> = new HttpResponse({
  headers: new HttpHeaders(),
  status: 200,
  statusText: 'OK',
  url: 'https://api.dev.accessintegra.com//dori/provider/v1/organizations',
  body: [
    {
      organizationId: 5,
      organizationLegalName: 'ABCDs Legal Pharmacy12',
      organizationDBAName: 'ABCD Legal Pharmacy 1',
      organizationStatus: 'terminated',
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      nextReCredDate: '2021-09-30T00:00:00',
      phone: '(456) 456-5461',
      taxId: null,
      daysTillNextCred: -20,
      contractTypeDescription: 'DMEnsion,Integra Partners,THPP - MA',
      contractTypeCode: 'CON,DME,THP',
      verificationStatus: null,
      orderManagementStatus: null,
      facilities: null,
      totalFacilities: 0,
      fax: null,
      email: 'govind.thakwani@datatusk.com',
      createdBy: null,
      createdDate: '2019-03-11T14:33:14.88',
      lastModifiedBy: 'Sritam Sarkar',
      lastModifiedDate: '2023-05-03T16:34:12.093'
    }]
});
export const staffsMock: StaffResponse = {
  staff: [{
    organizationName: 'Sprint 54 DBA/1', roles: 'Billing System Office Admin, Credentialing, In-House Technician, Order Management Admin', hasPractitioner: false, verificationStatus: 'verified', doriStatus: true, isOfficer: true, isOwner: true, ownershipPercentage: 100, roleCodes: 'BOA, IHT, OMA, SA ', staffRoleCodesArray: ['BOA', 'IHT', 'OMA', 'SA'], staffRoleArray: ['Billing System Office Admin', 'Credentialing', 'In-House Technician', 'Order Management Admin'], staffId: 4193, organizationId: 804, firstName: 'Arijit', lastName: 'Mohanty1', middleName: null, fullName: 'Arijit Mohanty1', title: 'PA admin', phone: '(332) 632-6332',
    email: 'semic49389@lenfly.com', suffix: null, extension: null, queId: 0, isActive: false
  }], totalCount: 1
};
export class ProviderServiceMocks {
  getProviderById(organizationId: number): Observable<Array<OrgDto>> {
    return of();
  }

  getStaffDetails(): Observable<OrgDto> {
    return of();
  }

  getOrganizations(): Observable<Array<OrgDto>> {
    return of();
  }

}

export class FacilitesServiceMock {
  public getFacilityRankingsById(
    orgId: number,
    facilityId: number
  ): Observable<FacilityRankingDtoViewModel> {
    return of({});
  }
  getFacilityListByOrganization(
    orgID: number,
    pageNumber: number,
    pageSize: number,
    filter: string
  ): Observable<Array<FacilityViewModel>> {
    return of([{ totalRecords: 8 }]);
  }

  public getFacilities() {
    return of([]);
  }
  getFacilityById(facilityId): Observable<FacilityDto> {
    return of();
  }

  public getAllOrganizationsFacilityList(
    pageNumber: number,
    pageSize: number,
    filter: string
  ): Observable<FacilityViewModel[]> {
    return of([{ totalRecords: 8 }]);
  }

  public getAllComplianceDocuments(
    organizationId: number,
    pageNumber: number,
    pageSize: number,
    filter?: string
  ): Observable<ResponseModelGeneric<ComplainceDocumentsViewModel>> {
    return of({ list: [], count: '0' });
  }

  public getFacilityBillingStaffs(
    organizationId: number,
    facilityId: number
  ): Observable<FacilityAdminViewModel[]> {
    return of([]);
  }

  public getFacilityOrderStaffs(
    organizationId: number,
    facilityId: number
  ): Observable<FacilityAdminViewModel[]> {
    return of([]);
  }

  getFacilitiesForComboboxPaging(
    filter: string,
    pageNumber: number,
    pageSize: number
  ): Observable<SearchDataList[]> {
    return of([]);
  }
  switchEditMode = (data: boolean, itemName?: string) => { };
}

export class ProvidersServiceMocks {
  GetOrganizationsWithProfileInfo(): Observable<OrgDto[]> {
    return of();
  }

  getStaffDetails(): Observable<OrgDto> {
    return of();
  }

  public getFacilitiesByOrgIdForComboboxPaging (provOrgId: number, filterStr: string, pageNumber: number, pageSize: number): Observable<Array<SearchDataList>> {
    return of();
  }
}

export class DashboardServiceMock {
  configuration = {};

  public getUserDashboard(): Observable<UserDash> {
    return of();
  }

  public getDashboardWidgets(): Observable<DashWidget[]> {
    return of();
  }

  public saveUserDashboard(dash: UserDash): Observable<UserDash> {
    return of(dash);
  }
}

export const contractsLookup: Dictionary<StringLookupTypeTemplate> = {
  CON: {
    code: 'CON',
    description: 'Integra Partners',
    category: null
  },
  DME: {
    code: 'DME',
    description: 'DMEnsion',
    category: null
  },
  THP: {
    code: 'THP',
    description: 'THPP - MA',
    category: null
  }
};

export const accountsMock: Array<SearchDataList> = [
  {
    id: '72',
    displayName: '1003 orga dba-1003 orga DBA'
  },
  {
    id: '73',
    displayName: '1007 orga DBA-1007 orga legal'
  },
  {
    id: '75',
    displayName: '934 Account-934 Account'
  }
];


export const searchDataListMock: Array<SearchDataList> = [
  {
    id: '1',
    displayName: 'sample display 1',
    displaySelected: 'sample display 1'
  },
  {
    id: '2',
    displayName: 'sample display 2',
    displaySelected: 'sample display 2'
  }
];

export const organizationStatusMock: Array<SearchDataList> = [
  {
    id: 'ACT',
    displayName: 'ACTIVE',
    displaySelected: 'ACTIVE'
  }
];

export class WorkforceServiceMock {
  getListOfDepartments(pageSize?: number, restartRowId?: number, sort: GridSortDescriptor[] = [],
  ): Observable<HttpResponse<Array<LmsDept>>> {
    return of();
  }

  getDeptMapping(extSysName: string, lmsDeptCode: string, pageSize?: number, restartRowId?: number, filter?: Array<ApiFilterRequest>,
                 sort?: Array<GridSortDescriptor>): Observable<HttpResponse<Array<LmsDeptMapping>>> {
    return of();
  }

  uploadLMSDept(
    extSysName: string, lmsDeptCode: string, fileList: Array<FileInfo>
  ): Observable<LmsDept> {
    return of();
  }
}

export class LMSMappingServiceMock {
  configuration = {};

  public getListOfMappingByDeptID(extSysName: string, lmsDeptID: string, sort?: string, restartRowId?: number, pageSize?: number,
                                  filter?: string, observe?: 'response', reportProgress?: boolean,
                                  options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<LmsDeptMapping>>> {
    return of();
  }

  public uploadLmsDeptMapping(lmsDeptCode?: string, extSysName?: string, filesToUpload?: Blob, observe?: 'body',
                              reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<LmsDept> {
    return of();
  }
}

export class DepartmentsServiceMock {
  configuration = {};

  public getListOfLmsDept(sort?: string, restartRowId?: number, pageSize?: number, filter?: string, observe?: 'response',
                          reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }):
    Observable<HttpResponse<Array<LmsDept>>> {
    return of();
  }
}

export const operationQuestionsMock: any = {
  model: {
    organizationId: 5,
    hasPractitioner: true,
    isOwnerOnPCertified: null,
    hasAccreditation: false,
    hasParent: false,
    hasSubcontractor: true,
    hasPharmacy: true,
    hasMalpracticeClaim: true,
    hasDisclosedSubcontractors: true,
    medicarePercentage: 34,
    medicaidPercentage: 23,
    cashPercentage: 32,
    hmoPercentage: 11,
    pharmacyLetterAttachment: { orignalFileName: 'sample.docx' },
    billingType: [
      {
        billingTypeId: 3164,
        organizationId: 5,
        billingTypeCd: 'BIN',
        totalRecords: 0,
        createdDate: '2023-06-16T19:14:03.32Z',
        createdBy: 0,
        lastModifiedDate: '2023-06-16T19:14:03.32Z',
        lastModifiedBy: 0,
        isNew: false,
        isDeleted: false,
        isChanged: false,
        buttonName: null,
        viewMode: null,
        reqSource: null,
        appId: 0,
        invokedFrom: 'none',
        newFacilityId: 0
      }
    ],
    billingSoftware: [
      {
        billingSoftwareId: 5203,
        organizationId: 5,
        billingSoftwareTypeCd: 'LY ',
        otherBillingSoftwareName: null,
        totalRecords: 0,
        createdDate: '2023-06-16T19:14:03.003Z',
        createdBy: 0,
        lastModifiedDate: '2023-06-16T19:14:03.003Z',
        lastModifiedBy: 0,
        isNew: false,
        isDeleted: false,
        isChanged: false,
        buttonName: null,
        viewMode: null,
        reqSource: null,
        appId: 0,
        invokedFrom: 'none',
        newFacilityId: 0
      },
      {
        billingSoftwareId: 5204,
        organizationId: 5,
        billingSoftwareTypeCd: 'OP ',
        otherBillingSoftwareName: null,
        totalRecords: 0,
        createdDate: '2023-06-16T19:14:03.003Z',
        createdBy: 0,
        lastModifiedDate: '2023-06-16T19:14:03.003Z',
        lastModifiedBy: 0,
        isNew: false,
        isDeleted: false,
        isChanged: false,
        buttonName: null,
        viewMode: null,
        reqSource: null,
        appId: 0,
        invokedFrom: 'none',
        newFacilityId: 0
      },
      {
        billingSoftwareId: 5205,
        organizationId: 5,
        billingSoftwareTypeCd: 'FT ',
        otherBillingSoftwareName: null,
        totalRecords: 0,
        createdDate: '2023-06-16T19:14:03.003Z',
        createdBy: 0,
        lastModifiedDate: '2023-06-16T19:14:03.003Z',
        lastModifiedBy: 0,
        isNew: false,
        isDeleted: false,
        isChanged: false,
        buttonName: null,
        viewMode: null,
        reqSource: null,
        appId: 0,
        invokedFrom: 'none',
        newFacilityId: 0
      }
    ],
    totalRecords: 0,
    createdDate: '2019-03-11T14:33:14.88Z',
    createdBy: 0,
    lastModifiedDate: '2023-06-16T19:13:54.543Z',
    lastModifiedBy: 0,
    isNew: false,
    isDeleted: false,
    isChanged: false,
    buttonName: null,
    viewMode: null,
    reqSource: null,
    appId: 0,
    invokedFrom: 'none',
    newFacilityId: 0
  },
  billingAgency: [
    {
      id: 'BIN',
      displayName: 'In House',
      displaySelected: 'In House'
    },
    {
      id: 'BA ',
      displayName: 'Billing Agency',
      displaySelected: 'Billing Agency'
    }
  ],
  billingSoftwareType: [
    {
      id: 'LY ',
      displayName: 'Lytec',
      displaySelected: 'Lytec'
    },
    {
      id: 'OP ',
      displayName: 'Opie',
      displaySelected: 'Opie'
    },
    {
      id: 'FT ',
      displayName: 'Fastrack',
      displaySelected: 'Fastrack'
    }
  ],
  organizationLegalName: 'New organization'
};
export const $eventFileUploadMock = [
  {
    extension: '.docx',
    name: 'New Microsoft Word Document.docx',
    rawFile: {},
    size: 12043,
    state: 2,
    uid: 'a9387a9c-2d33-4134-946a-e6386d8cbbe0'
  }];
export class ActivatedRouteSnapshotMock {
  private _data: any;
  get data(): any {
    return this._data;
  }
}

export const responseMock = {
  fileType: 'docx',
  fileName: 'New Microsoft Word Document.docx',
  blobUrl: 'https://credentialingfileblobs.blob.core.windows.net/authtest/org-5/New_Microsoft_Word_Document_docx-06-17-2023-13-47-14',
  fileNotAllowedError: ''
};
