import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import { PagingConfig, SearchDataList } from '../../shared-interfaces';
import {FormFieldConfig} from '../form-field/form-field.interface';
import {finalize} from 'rxjs/operators';
import { DropDownPagingUtil } from '../dropdown-paging-utils';

export const MULTISELECT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiselectComponent),
  multi: true
};

@Component({
  selector: 'prism-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [ MULTISELECT_CONTROL_VALUE_ACCESSOR ]
})
export class MultiselectComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() config: FormFieldConfig;
  @Input() disabled = false;
  @Input() data: SearchDataList[] = [];
  @Input() placeholder = '';
  @Input() filterable = true;
  @Input() textField = 'displaySelected';
  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueChange: EventEmitter<void> = new EventEmitter<void>();

  public filteredData: SearchDataList[] = [];
  public isLoading = false;
  private pagingUtils = new DropDownPagingUtil();

  private get paging(): boolean {
    return !!this.config?.pagingConfig;
  }

  protected innerValue: any[] = [];

  protected innerModel;
  private filterStr = '';

  private get pageIndicator(): number {
    return this.config?.pagingConfig?.usePageNumberInCallback ?
      this.pagingUtils.pageNumber :
      this.pagingUtils.restartRowNumber;
  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor() { }

  ngOnInit(): void {
    if (this.paging) {
      this.pagingUtils.pageSize = this.config.pagingConfig.pageSize;
      this.pagingUtils.onScrollCallback = () => this.executePagingCallback(this.filterStr);
      if (!!this.config?.pagingConfig?.onPageCallback) {
        this.executePagingCallback('');
      }
    }
    else {
      this.filteredData = this.data;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.firstChange) {
      this.filteredData = this.data;
      this.innerModel = Array.isArray(this.data) ? this.data.filter(item => this.innerValue?.includes(item.id)) : [];
    }
  }

  get value(): any {
    return this.innerValue;
  }

  set value(obj: any) {
    this.writeValue(obj);
  }

  get model(): any {
    return this.innerModel;
  }

  set model(obj: any) {
    const values = Array.isArray(obj) ? obj.map(item => item.id) : [];
    this.writeValue(values);
  }

  writeValue(obj): void {
    this.innerValue = obj;
    this.innerModel = Array.isArray(this.data) ? this.data.filter(item => this.innerValue?.includes(item.id)) : [];
    this.onChangeCallback(this.innerValue);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  handleComboFilterChange(str: string): void {
    if (this.paging) {
      this.pagingUtils.resetPageNumber();
      this.executePagingCallback(str);
    }
    else {
      this.filteredData = this.filterData(str);
    }
  }

  filterData(filter: string): Array<SearchDataList> {
    const lowerCaseFilter = filter.toLowerCase();
    return lowerCaseFilter.length ? this.data.filter(item => item.displayName.toLowerCase().includes(lowerCaseFilter)) : this.data;
  }

  isItemSelected(item: any): boolean {
    return Array.isArray(this.innerModel) ?
      this.innerModel.filter(val => val.id === item.id).length > 0 :
      this.innerValue === item;
  }

  onMultiSelectClick(): void {
    if (this.data.length === 0) {
      if (this.paging) {
        this.pagingUtils.resetPageNumber();
        this.executePagingCallback('')
      } else {
        this.executeCallback('');
      }
    }
  }

  expandDropdown() {
    if (this.paging) {
      this.pagingUtils.addScrollListener();
    }
  }

  loadMore() {
    this.executePagingCallback(this.filterStr);
  }

  public executePagingCallback(filter: string): void {
    this.config?.pagingConfig?.onPageCallback(filter, this.pageIndicator, this.config.pagingConfig.pageSize)
      .subscribe(results => {
        if (this.filterStr !== filter || this.filteredData.length === 0) {
          this.filteredData = results;
        } else if (this.filteredData[0]?.id !== results[0]?.id) {
          this.filteredData.push(...results);
        }
        const loadedIds = this.data.map(x => x.id);
        const uniqueEntries = results.filter(x => !loadedIds.includes(x.id));
        this.data = this.data.concat(uniqueEntries);
        this.innerModel?.forEach((item, index) => {
          if (!this.filteredData.includes(item) && this.filteredData.some(x => x.id == item.id))
          {
            let newItem = this.filteredData.find(x => x.id == item.id);
            this.innerModel[index] = newItem;
          }
        });

        this.pagingUtils.allowLoadMore = results.length > 0;
        this.filterStr = filter;
      })
  }

  public executeCallback(filterString): void {
    this.isLoading = true;
    this.config?.callback?.(filterString).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe((dataList) => {
      this.data = this.uniqueSearchDataArray(dataList.concat(this.data));
    });
  }

  uniqueSearchDataArray(items: any): Array<SearchDataList> {
    const concatList = items.concat();
    for (let idxI = 0; idxI < concatList.length; ++idxI) {
      for (let idxJ = idxI + 1; idxJ < concatList.length; ++idxJ) {
        if (concatList[idxI].id === concatList[idxJ].id) {
          concatList.splice(idxJ--, 1);
        }
      }
    }

    return concatList;
  }

  public valueHasChanged(): void {
    this.valueChange.emit();
  }

}
