import { listsTypes } from './list-settings.js';

/**
 * The object of the Indent tool settings.
 */
const indentRules = {
  nodes: [{
    node: 'paragraph',
    style: 'margin-left',
    rtlStyle: 'margin-right',
    step: 30,
    unit: 'px'
  }, {
    node: 'heading',
    style: 'margin-left',
    rtlStyle: 'margin-right',
    step: 30,
    unit: 'px'
  }],
  listsTypes: Object.assign({}, listsTypes)
};
/**
 * The object of the Outdent tool settings.
 */
const outdentRules = {
  nodes: [{
    node: 'paragraph',
    style: 'margin-left',
    rtlStyle: 'margin-right',
    step: -30,
    unit: 'px'
  }, {
    node: 'heading',
    style: 'margin-left',
    rtlStyle: 'margin-right',
    step: -30,
    unit: 'px'
  }],
  listsTypes: Object.assign({}, listsTypes)
};
export { indentRules, outdentRules };