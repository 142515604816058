import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MenuStateEvent } from "@progress/kendo-angular-menu/services/actions.service";
import { PillColor } from "../pill-v2/pill-v2.component";

export interface Note {
  content: string;
  id?: number;
  timestamp?: string;
  edited?: boolean;
  author?: string;
  type?: string;
}

export interface MenuItem {
  text: string;
  action: () => void;
}

@Component({
  selector: 'prism-note',
  templateUrl: './note.component.html',
})
export class NoteComponent {
  public readonly pillDisplayCfg = {Edited: PillColor.YELLOW};

  @Input()
  get editingNote(): boolean {
    return this._editingNote;
  }
  set editingNote(editing: boolean) {
    this._editingNote = editing;

    if (editing === true) {
      this.noteForm.setValue({content: this.note.content});
    } else {
      this.noteForm.reset();
    }
  }
  @Input() menuItems: MenuItem[] = [];
  @Input() note: Note;

  @Output() saveNote = new EventEmitter<Note>();
  @Output() cancelEdit = new EventEmitter<Note>();

  public noteForm = new FormGroup({
    content: new FormControl('', Validators.required),
  })
  private _editingNote = false;

  onContextSelect(event: MenuStateEvent) {
    event?.item?.action();
  }

  public onCancelEdit() {
    this.cancelEdit.emit(this.note);
  }

  public submitNote() {
    if (!this.noteForm.valid) return;
    this.saveNote.emit({
      ...this.note,
      content: this.noteForm.value.content,
    });
  }
}
