const style = (name, value) => {
  return {
    name: name,
    value: value
  };
};
const alignLeftRules = [{
  node: 'paragraph',
  style: [style('text-align', 'left')]
}, {
  node: 'heading',
  style: [style('text-align', 'left')]
}, {
  node: 'table_caption_external',
  style: [style('text-align', 'left')]
}];
const alignRightRules = [{
  node: 'paragraph',
  style: [style('text-align', 'right')]
}, {
  node: 'heading',
  style: [style('text-align', 'right')]
}, {
  node: 'table_caption_external',
  style: [style('text-align', 'right')]
}];
const alignCenterRules = [{
  node: 'paragraph',
  style: [style('text-align', 'center')]
}, {
  node: 'heading',
  style: [style('text-align', 'center')]
}, {
  node: 'table_caption_external',
  style: [style('text-align', 'center')]
}];
const alignJustifyRules = [{
  node: 'paragraph',
  style: [style('text-align', 'justify')]
}, {
  node: 'heading',
  style: [style('text-align', 'justify')]
}, {
  node: 'table_caption_external',
  style: [style('text-align', 'justify')]
}];
const alignRemoveRules = [{
  node: 'paragraph',
  style: [style('text-align', '')]
}, {
  node: 'heading',
  style: [style('text-align', '')]
}, {
  node: 'table_caption_external',
  style: [style('text-align', '')]
}];
export { alignCenterRules, alignJustifyRules, alignLeftRules, alignRemoveRules, alignRightRules };