<div class="mt-30 mb-3 row">
  <div class="col-md-4 search-container">
    <integra-search
            [searchConfig]="advSearchConfigs"
            searchViewMode="adv-search-wide"
            (applyFilterEvent)="onFilterChange($event)"
            [placeHolder]="advSearchPlaceHolder"
            [closeButton]="true">
    </integra-search>
  </div>
</div>

<prism-grid [data]="{ data: gridData, total: totalRows }"
            [columns]="gridColumns"
            [sortable]="{ mode: 'single', allowUnsort: false }"
            [sortableColumns]="sortableColumns"
            [sort]="sort"
            pageable="true"
            [pageSize]="take"
            [skip]="skip"
            [loading]="isLoading"
            [gridPadding]="gridPadding"
            [dtlRowTemplate]="dtlRowTemplate"
            [showDtlRow]="showDtlRow"
            (pageChange)="handlePageChange($event)"
            (rowClick)="handleRowClick($event)"
            (sortChange)="handleSortChange($event)">
</prism-grid>
