import { Component, Input } from '@angular/core';

export enum PillColor {
  NONE,
  GREEN,
  YELLOW,
  RED,
  GRAY,
  LIGHT_BLUE,
  DARK_BLUE,
}

export type PillDisplayConfig = { [status: string]: PillColor}

@Component({
  selector: 'prism-pill-v2',
  templateUrl: './pill-v2.component.html',
  styleUrls: ['./pill-v2.component.scss'],
})
export class PillComponentV2 {
  @Input() status: string;
  @Input() statusDisplay: string;
  @Input() displayConfig: PillDisplayConfig

  public get statusDisplayName(): string {
    return this.statusDisplay ? this.statusDisplay : this.status;
  }

  public get statusClass(): string {
    if (!this.displayConfig) return '';
    const color = this.displayConfig[this.status] ?? PillColor.NONE

    switch (color) {
      case PillColor.GREEN:
        return 'status-green';
      case PillColor.YELLOW:
        return 'status-yellow';
      case PillColor.RED:
        return 'status-red';
      case PillColor.GRAY:
        return 'status-gray';
      case PillColor.LIGHT_BLUE:
        return 'status-light-blue';
      case PillColor.DARK_BLUE:
        return 'status-dark-blue';
      default:
        return '';
    }
  }

}
