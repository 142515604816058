<div kendoTooltip>
  <button class="btn-prism {{width}} {{size}} {{type}} {{class}} k-grid-ignore-click"
          kendoButton
          [rounded]='rounded'
          [disabled]="disabled || submitBusy"
          (click)="onButtonClick()"
          [look]="look"
          [icon]="icon"
          [imageUrl]="iconImageUrl"
          [toggleable]="toggleable"
          (selectedChange)="onSelectionChange($event)"
          [type]="nativeType ? nativeType : 'button'"
          [title]="toolTip" >
    <ng-content></ng-content>
  </button>
</div>
