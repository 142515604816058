import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import {
  ApiFilterRequest,
  GridColumn,
  GridPageChangeEvent,
  GridSortDescriptor,
  IntegraSearchConfigRow,
  PrismCellClickEvent
} from '../../shared-interfaces';
import { Subject } from 'rxjs';
import { GridMetaData } from './list-view-interface';
import { AdvSearchFilterService } from '../../shared-services/adv-search-filter/adv-search-filter.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'prism-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit, OnChanges {
  @Input() take: number = 25;
  @Input() skip: number = 0;
  @Input() gridColumns: GridColumn[] = [];
  @Input() sortableColumns: string[] = [];
  @Input() advSearchConfigs: IntegraSearchConfigRow[] = [];
  @Input() showDtlRow: boolean = false;
  @Input() dtlRowTemplate: TemplateRef<any>;
  @Input() gridData: any[] = [];
  @Input() isLoading: boolean = false;
  @Input() totalRows: number = 0;
  @Input() gridPadding: number = 250;
  @Input() advSearchPlaceHolder: string = '';
  @Output() loadGridData: EventEmitter<GridMetaData> = new EventEmitter();
  @Output() rowClick: EventEmitter<any> = new EventEmitter();

  protected filter: ApiFilterRequest[] = [];
  protected sort: GridSortDescriptor[] = [];
  protected searchQueryChanged: Subject<ApiFilterRequest[]> = new Subject<ApiFilterRequest[]>();

  private initializedFilters: boolean = false;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly advSearchFilterService: AdvSearchFilterService,
  ) {
    this.searchQueryChanged.subscribe(filter => {
      this.filter = filter;
      this.skip = 0;
      this.updateSearchQueryParams(filter);
      this.loadData(true);
    });
  }

  ngOnInit(): void {
    this.initializeAdvSearchFilters();
    if (!this.initializedFilters) {
      this.loadData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isLoading?.previousValue !== changes?.isLoading?.currentValue) {
      this.isLoading = changes?.isLoading.currentValue;
    }
    if (changes?.gridData?.previousValue !== changes?.gridData?.currentValue) {
      this.gridData = changes?.gridData.currentValue;
    }
    if (changes?.totalRows?.previousValue !== changes?.totalRows?.currentValue) {
      this.gridData = changes?.gridData.currentValue;
    }
  }

  private loadData(isFilterChanged: boolean = false): void {
    this.loadGridData.emit({
      skip: this.skip,
      take: this.take,
      isFilterChanged: isFilterChanged,
      sort: this.sort,
      filter: this.filter,
    } as GridMetaData);
  }

  public handlePageChange(event: GridPageChangeEvent): void {
    this.take = event.take;
    this.skip = event.skip;
    this.loadData();
  }

  public handleRowClick(clickedRow: PrismCellClickEvent<any>): void {
    this.rowClick.emit(clickedRow.dataItem);
  }

  public handleSortChange(sortEvent: GridSortDescriptor[]): void {
    const sorts = sortEvent.filter((sortCol) =>
      this.sortableColumns.includes(sortCol.field)
    );
    if (!sorts.length) {
      return;
    }

    this.sort = sorts;
    this.loadData();
  }

  public onFilterChange(filters: ApiFilterRequest[]): void {
    this.searchQueryChanged.next(filters);
  }

  private initializeAdvSearchFilters(): void {
    this.activatedRoute.snapshot.queryParamMap?.keys.forEach(key => {
      const column = this.advSearchFilterService.getAdvSearchColumnByKey(key, this.advSearchConfigs);
      if (column) {
        column.value = this.activatedRoute.snapshot.queryParamMap.get(key);
      }
    });
    if (this.activatedRoute.snapshot.queryParamMap?.keys.length > 0) {
      this.initializedFilters = true;
    }
  }

  private updateSearchQueryParams(searchFilters: Array<ApiFilterRequest>): void {
    const queryParams = this.advSearchFilterService.mapSrchQueryParams(searchFilters, this.advSearchConfigs);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams
    }).then();
  }
}
