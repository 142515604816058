import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ButtonRounded } from '@progress/kendo-angular-buttons';
import { Subscription } from 'rxjs';
import { ButtonNativeType } from '../../shared-interfaces';
import { SubmitService } from '../../shared-services/submit/submit.service';

export type ButtonSize = 'small' | 'small-icon' | 'large-icon' | 'full'
export type ButtonType = 'danger' | 'submit' | 'primary' | 'primary-dark' | 'secondary' | 'cancel' | 'flat' | 'fifth' | 'fourth' | 'sixth' | 'seventh' | 'transparent'

@Component({
  selector: 'prism-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnDestroy {
  @Input() width: string;
  @Input() icon?: string;
  @Input() iconImageUrl?: string;
  @Input() size: ButtonSize;
  @Input() type: ButtonType;
  @Input() nativeType: ButtonNativeType;
  @Input() disabled: boolean;
  @Input() look: any = 'flat';
  @Input() rounded: ButtonRounded;
  @Input() class: string;
  @Input() toggleable: boolean = false;
  @Input() toolTip: string = '';
  @Output() clicked: EventEmitter<void> = new EventEmitter();
  @Output() selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public submitBusy = false;

  private subcription: Subscription;

  constructor(
    private submitService: SubmitService
  ) { }

  ngOnInit(): void {
    if (this.nativeType === 'submit') {
      this.subcribeSubmitService();
    }
  }

  ngOnDestroy(): void {
    if (this.subcription) {
      this.subcription.unsubscribe();
    }
  }

  protected subcribeSubmitService(): void {
    this.subcription = this.submitService.busy$.subscribe(busy => {
      this.submitBusy = busy;
    });
  }

  onButtonClick(): void {
    this.clicked.emit();
  }

  protected onSelectionChange(selectionChange: boolean): void {
    this.selectedChange.emit(selectionChange);
  }
}
