import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { membersFiltersMock } from 'src/app/shared/mocks/shared-mocks';
import { ApiFilterRequest, IntegraSearchOperators, PayersFilter, SearchDataList } from 'src/app/shared/shared-interfaces/shared-interfaces';
import { SettingsService } from 'src/app/shared/shared-services/settings/settings.service';
import { ListItemDtl } from '../../mdm/openapi';
import {
  Action,
  BulkAddressImport,
  Change,
  PayerAccount,
  PayerAccountList,
  PayerAccountsService,
  PayerFamily,
  PayerFamilyList,
  PayerFamilyService,
  PayerInfo,
  PayerLob,
  PayerLobList,
  PayerLOBService,
  PayerPlan,
  PayerPlanDetails,
  PayerPlanList,
  PayerPlanService,
  PayerSearch,
  PayerSearchService
} from '../openapi';
import { PayersMappingService } from './payers-mapping.service';

@Injectable({
  providedIn: 'root'
})
export class PayersService {
  constructor(
    private settingsService: SettingsService,
    private payerFamilyService: PayerFamilyService,
    private payerPlanService: PayerPlanService,
    private payerAccountsService: PayerAccountsService,
    private payerLOBService: PayerLOBService,
    private payerSearchService: PayerSearchService,
  ) {
    this.payerFamilyService.configuration.basePath = `${this.settingsService.basePath}/clear/payer`;
    this.payerFamilyService.configuration.withCredentials = true;
    this.payerAccountsService.configuration.basePath = `${this.settingsService.basePath}/clear/payer`;
    this.payerAccountsService.configuration.withCredentials = true;
    this.payerPlanService.configuration.basePath = `${this.settingsService.basePath}/clear/payer`;
    this.payerPlanService.configuration.withCredentials = true;
    this.payerLOBService.configuration.basePath = `${this.settingsService.basePath}/clear/payer`;
    this.payerLOBService.configuration.withCredentials = true;
    this.payerSearchService.configuration.basePath = `${this.settingsService.basePath}/clear/payer`;
    this.payerSearchService.configuration.withCredentials = true;
  }

  getPayersFilters(): Observable<PayersFilter[]> {
    return of(membersFiltersMock);
  }

  createNewPayerFamily(body: PayerFamily): Observable<PayerFamily> {
    return this.payerFamilyService.createPayerFamily(body);
  }

  updatePayerFamily(payerFamilyId: number, body: PayerFamily): Observable<PayerFamily> {
    return this.payerFamilyService.updatePayerFamily(payerFamilyId, body);
  }

  payerSearch(sort?: string, filter?: string, restartRowId: number = 0, pageSize: number = 200): Observable<Array<PayerInfo>> {
    filter = filter > '' ? filter : null;
    return this.payerSearchService.searchPayers(sort, filter, restartRowId, pageSize);
  }

  public payerLobSearch(restartRowId: number = 0, pageSize: number = 20, sort?: string, filter?: string): Observable<Array<PayerSearch>> {
    return this.payerSearchService.searchPayerDtls(sort, filter, restartRowId, pageSize);
  }

  public payerFamSearch(restartRowId: number = 0, pageSize: number = 20, sort?: string, filter?: string): Observable<Array<PayerSearch>> {
    return this.payerFamilyService.getPayerFamilies(sort, filter, restartRowId, pageSize);
  }

  getPayersFamilyList(
    sort: string,
    searchString: string = null,
    restartRowNum: number = 0,
    pageSize: number = 200,
  ): Observable<PayerFamilyList[]> {
    return this.payerFamilyService.getPayerFamilies(sort, searchString, restartRowNum, pageSize);
  }

  getPayerFamilyListWithOffset(
    sort: string,
    searchString: string = null,
    restartRowNum: number = 0,
    pageSize: number = 200,
  ) {
    return this.payerFamilyService.getPayerFamilies(sort, searchString, restartRowNum, pageSize, 'response');
  }

  getPayerFamilyById(payerFamilyId: number): Observable<PayerFamily> {
    return this.payerFamilyService.getPayerFamilyByID(payerFamilyId);
  }

  public getPayersPlanList(
    payerFamId: number,
    sort: string,
    searchString: string = null,
    restartRowNum: number = 0,
    pageSize: number = 200,
  ): Observable<PayerPlanList[]> {
    return this.payerPlanService.getPayerPlans(payerFamId, sort, searchString, restartRowNum, pageSize);
  }

  public getAllPayersPlanList(
    sort: string,
    searchString: string = null,
    restartRowNum: number = 0,
    pageSize: number = 200,
  ): Observable<PayerPlanList[]> {
    return this.payerPlanService.getAllPayerPlans(sort, searchString, restartRowNum, pageSize);
  }

  public getPayerAccounts(
    payerFamId: number, payerPlanId: number, sort?: string, filter?: string, restartRowId?: number, pageSize?: number
  ): Observable<Array<PayerAccountList>> {
    let filterStr;
    if (filter && filter > '') {
      filterStr = `[{ "operator": "ilike", "value": "${filter}%", "property": "name" }]`;
    }
    return this.payerAccountsService.getPayerAccounts(payerFamId, payerPlanId, sort, filterStr, restartRowId, pageSize);
  }

  getPayerFamilyAccountsList(familyId: number, pageSize: number = 200): Observable<PayerAccountList[]> {
    return this.payerAccountsService.getPayerFamilyAccounts(familyId, null, pageSize);
  }

  getPayerAccountsList(familyId: number, payerPlanId: number, sort: string, pageSize: number = 200): Observable<PayerAccountList[]> {
    return this.payerAccountsService.getPayerAccounts(familyId, payerPlanId, sort, null, null, pageSize);
  }

  createNewPayerAccount(payerFamilyId: number, payerPlanId: number, body: PayerAccount): Observable<PayerAccount> {
    return this.payerAccountsService.createPayerAccount(payerFamilyId, payerPlanId, body);
  }

  getPayerAccountById(payerFamilyId: number, payerPlanId: number, payerAcctId: number): Observable<PayerAccount> {
    return this.payerAccountsService.getPayerAccountByID(payerFamilyId, payerPlanId, payerAcctId);
  }

  updatePayerAccount(payerFamilyId: number, payerPlanId: number, payerAcctId: number, payerAcct: PayerAccount): Observable<PayerAccount> {
    return this.payerAccountsService.updatePayerAccount(payerFamilyId, payerPlanId, payerAcctId, payerAcct);
  }

  getPayersLobList(
    familyId: number, payerPlanId: number, accountId: number, sort?: string, pageSize: number = 200
  ): Observable<Array<PayerLobList>> {
    return this.payerLOBService.getPayerLobs(familyId, payerPlanId, accountId, sort, null, null, pageSize);
  }

  private getAllPayersLobsList(
    sort: string,
    searchString: string = null,
    restartRowNum: number = 0,
    pageSize: number = 200,
  ): Observable<PayerLobList[]> {
    return this.payerLOBService.getAllPayerLobs(sort, searchString, restartRowNum, pageSize);
  }

  getPayerLobById(payerLobId: number): Observable<PayerLob> {
    return this.payerLOBService.getPayerLobByLobID(payerLobId);
  }

  getPayerFamilyByIdForCombobox(payerFamilyId: number): Observable<SearchDataList> {
    return this.payerFamilyService.getPayerFamilyByID(payerFamilyId).pipe(
      map((payerFamily) => {
        return {
          id: payerFamily.payerFamId.toString(),
          displayName: payerFamily.payerFamName,
          searchName: payerFamily.payerFamName,
        };
      })
    );
  }

  getPayerAccountByIdForCombobox(payerAccountId: number): Observable<SearchDataList> {
    return this.payerAccountsService.getPayerAccountByAcctID(payerAccountId)
      .pipe(
        map(payerAccount => {
          return {
            id: payerAccount.payerAcctId.toString(),
            displayName: payerAccount.payerAcctName
          };
        })
      );
  }

  getPayerPlanByIdForCombobox(payerPlanId: number): Observable<SearchDataList> {
    return this.payerPlanService.getPayerPlanByPlanID(payerPlanId)
      .pipe(
        map(payerPlan => {
          return {
            id: payerPlan.payerPlanId.toString(),
            displayName: payerPlan.payerPlanName
          };
        })
      );
  }

  getPayerLobByIdForCombobox(payerLobId: number): Observable<SearchDataList> {
    const filterStr = `[{ "operator": "=", "value": "${payerLobId}", "property": "payerLobId" }]`;
    return this.payerLobSearch(0, 10, null, filterStr).pipe(
      flatMap((payerList) => {
        const srchDataList: Array<SearchDataList> = [];
        payerList.forEach((payer) => {
          srchDataList.push({
            id: payer.payerLobId.toString(),
            displayName: payer.payerName,
            searchName: payer.payerName
          });
        });
        return srchDataList;
      })
    );
  }

  getPayerLobByIdForComboboxSimple(payerLobId: number): Observable<SearchDataList> {
    return this.payerLOBService.getPayerLobByLobID(payerLobId).pipe(
      map((payerLob) => {
        return {
          id: payerLob.payerLobId.toString(),
          displayName: payerLob.payerLobName,
        };
      })
    );
  }

  lookupPayerLobsByIdForCombobox(payerLobIds: number[], apiConfig: {longDisplayName: boolean}): Observable<SearchDataList[]> {
    const filterList: ApiFilterRequest[] = [
      {
        operator: IntegraSearchOperators.in,
        value: payerLobIds.toString(),
        property: 'payerLobId',
        group: 1
      },
    ];
    const filterStr = JSON.stringify(filterList)
    return this.payerLobSearch(0, 100, null, filterStr).pipe(map(results => {
      return results.map(result => {
          return {
            id: result.payerLobId.toString(),
            displayName: apiConfig.longDisplayName ? result.payerName : result.payerLobName,
          };
      });
    }));
  }

  lookupPayerFamiliesByIdForCombobox(payerFamilyIds: number[]): Observable<SearchDataList[]> {
    const filter = [{
        operator: IntegraSearchOperators.in,
        value: payerFamilyIds.toString(),
        property: 'payerFamilyID',
        group: 1,
      }];
    return this.getPayersFamilyList(null, JSON.stringify(filter), 0, 20)
      .pipe(
        map(payerList => {
          return payerList.map(family => {
            return {
              id: family.payerFamId.toString(),
              displayName: family.payerFamName
            } as SearchDataList;
          });
        })
      );
  }

  createPayerLob(payerFamId: number, payerPlanId: number, payerAcctId: number, body: PayerLob): Observable<PayerLob> {
    return this.payerLOBService.createPayerLob(payerFamId, payerPlanId, payerAcctId, body);
  }

  updatePayerLob(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number, body: PayerLob): Observable<PayerLob> {
    return this.payerLOBService.updatePayerLob(payerFamId, payerPlanId, payerAcctId, payerLobId, body);
  }


  public getPayerFamilyDataForCombobox = (filterStr: string): Observable<Array<SearchDataList>> => {
    const filter = [];
    if (filterStr) {
      filter.push({ operator: 'ilike', value: `%${filterStr}%`, property: 'name' });
    }
    return this.getPayersFamilyList(null, filter.length ? JSON.stringify(filter) : null, 0, 20)
      .pipe(
        map(payerList => {
          return payerList.map(family => {
            return {
              id: family.payerFamId.toString(),
              displayName: family.payerFamName
            } as SearchDataList;
          });
        })
      );
  }

  public getPayerFamilyDataForPagingCombobox(filterStr: string, restartRowNum: number = 0,
                                             pageSize: number = 20, useIntId: boolean = false): Observable<SearchDataList[]> {
    const filter = [];
    if (filterStr) {
      filter.push({ operator: 'ilike', value: `%${filterStr}%`, property: 'name' });
    }
    return this.getPayersFamilyList(null, filter.length ? JSON.stringify(filter) : null, restartRowNum, pageSize)
      .pipe(
        map(payersList => {
          return payersList.map(family => {
            return {
              id: useIntId ? family.payerFamId : family.payerFamId.toString(),
              displayName: family.payerFamName
            } as SearchDataList;
          });
        })
      );
  }

  public getPayerPlanDataForCombobox = (filterStr: string, familyId: number = null): Observable<Array<SearchDataList>> => {
    const filter = [];
    if (filterStr) {
      filter.push({ operator: 'ilike', value: `%${filterStr}%`, property: 'name' });
    }
    return familyId !== null ?
      this.getPayersPlanList(familyId, null, filter.length ? JSON.stringify(filter) : null, 0, 20)
        .pipe(
          map(payerList => {
            return payerList.map(plan => {
              return {
                id: plan.payerPlanId.toString(),
                displayName: plan.payerPlanName
              } as SearchDataList;
            });
          })) :
      this.payerSearch(null, filterStr, 0, 10)
        .pipe(
          map((payerList) => {
            const srchDataList: Array<SearchDataList> = [];
            payerList.forEach((family) => {
              family.payerPlans?.forEach((plan) => {
                srchDataList.push({
                  id: plan.payerPlanId.toString(),
                  displayName: family.payerFamName + ' - ' + plan.payerPlanName,
                  displaySelected: plan.payerPlanName
                });
              });
            });
            return srchDataList;
          })
        );
  }

  public getPayerPlanDataForComboboxPaging(filterStr: string, restartRowNum: number, pageSize: number,
                                           familyId: number = null): Observable<SearchDataList[]> {
    const filter = filterStr ?
      [{ operator: 'ilike', value: `%${filterStr}%`, property: 'name' }] :
      [];
    return familyId !== null ?
      this.getPayersPlanList(familyId, null, filter.length ? JSON.stringify(filter) : null, restartRowNum, pageSize)
        .pipe(map(payerList => {
          return payerList.map(plan => {
            return {
              id: plan.payerPlanId.toString(),
              displayName: plan.payerPlanName
            } as SearchDataList;
          });
        })) :
      this.payerSearch(null, filterStr, restartRowNum, pageSize)
        .pipe(map(payerList => {
          const srchDataList: Array<SearchDataList> = [];
          payerList.forEach((family) => {
            family.payerPlans.forEach((plan) => {
              srchDataList.push({
                id: plan.payerPlanId.toString(),
                displayName: `${family.payerFamName} - ${plan.payerPlanName}`,
                displaySelected: plan.payerPlanName
              });
            });
          });
          return srchDataList;
        }));
  }

  public getAllPayerLobDataForCombobox(searchString: string, payerLobIDs: string[], restartRowNum: number = 0,
                                       pageSize: number = 20): Observable<Array<SearchDataList>> {
    const filter = [];
    if (searchString != null && searchString > '') {
      filter.push({ operator: 'ilike', value: `${searchString}%`, property: 'name', group: 1 });
    }
    if (payerLobIDs && payerLobIDs.length !== 0) {
      const filterStr = payerLobIDs.join();
      filter.push({ operator: 'in', value: filterStr, property: 'payerLobId', group: 2 });
    }

    return this.getAllPayersLobsList(null, filter.length ? JSON.stringify(filter) : null, restartRowNum, pageSize)
      .pipe(map(lobList =>
        lobList.map(lob => ({
          id: lob.payerLobId.toString(),
          displayName: lob.payerLobName,
          displaySelected: lob.payerLobName
        }))));
  }

  public getAllPayerPlanDataForCombobox(searchString: string, payerPlanIDs: string[], restartRowNum: number = 0,
                                        pageSize: number = 20): Observable<Array<SearchDataList>> {
    const filter = [];
    if (searchString != null && searchString > '') {
      filter.push({ operator: 'ilike', value: `${searchString}%`, property: 'name', group: 1 });
    }
    if (payerPlanIDs && payerPlanIDs.length !== 0) {
      const filterStr = payerPlanIDs.join();
      filter.push({ operator: 'in', value: filterStr, property: 'payerPlanID', group: 2 });
    }


    return this.getAllPayersPlanList(null, filter.length ? JSON.stringify(filter) : null, restartRowNum, pageSize)
      .pipe(
        map(payerList => {
          return payerList.map(plan => {
            return {
              id: plan.payerPlanId.toString(),
              displayName: plan.payerPlanName,
              displaySelected: plan.payerPlanName
            } as SearchDataList;
          });
        }));
  }

  public getPayerAccountDataForCombobox(
    filterStr: string, familyId: number = null, planId: number = null
  ): Observable<Array<SearchDataList>> {
    return familyId && planId ?
      this.getPayerAccounts(familyId, planId, null, filterStr)
        .pipe(map((accounts: Array<PayerAccountList>) => {
          return accounts.map(account => {
            return {
              id: account.payerAcctId.toString(),
              displayName: account.payerAcctName
            } as SearchDataList;
          });
        })) :
      this.payerSearch(null, filterStr, 0, 10)
        .pipe(
          map((payerList) => {
            const srchDataList: Array<SearchDataList> = [];
            payerList.forEach((family) => {
              family.payerPlans?.forEach((plan) => {
                plan.payerAccts?.forEach((account) => {
                  srchDataList.push({
                    id: account.payerAcctId.toString(),
                    displayName: family.payerFamName + ' - ' + plan.payerPlanName + ' - ' + account.payerAcctName
                  });
                });
              });
            });
            return srchDataList;
          })
        );
  }

  public getPayerAccountDataForComboboxPaging(filterStr: string, restartRowNum: number, pageSize: number,
                                              familyId: number = null, planId: number = null): Observable<Array<SearchDataList>> {
    return familyId && planId ?
      this.getPayerAccounts(familyId, planId, null, filterStr, restartRowNum, pageSize)
        .pipe(map(accounts => {
          return accounts.map(account => {
            return {
              id: account.payerAcctId.toString(),
              displayName: account.payerAcctName
            } as SearchDataList;
          });
        })) :
      this.payerSearch(null, filterStr, restartRowNum, pageSize)
        .pipe(map(payerList => {
          const srchDataList: Array<SearchDataList> = [];
          payerList.forEach(family => {
            family.payerPlans.forEach(plan => {
              plan.payerAccts.forEach(account => {
                srchDataList.push({
                  id: account.payerAcctId.toString(),
                  displayName: `${family.payerFamName} - ${plan.payerPlanName} - ${account.payerAcctName}`
                });
              });
            });
          });
          return srchDataList;
        }));
  }

  public getPayerLobDataForCombobox(
    filterStr: string, familyId: number = null, planId: number = null, accountId: number = null
  ): Observable<Array<SearchDataList>> {
    const filter = filterStr !== '' ? `[{ "operator": "ilike", "value": "%${filterStr}%", "property": "name" }]` : null;
    return familyId !== null && planId !== null && accountId !== null ?
      this.payerLOBService.getPayerLobs(familyId, planId, accountId, null, filter)
        .pipe(map((lobs: Array<PayerLobList>) => {
          return lobs.map(lob => {
            return {
              id: lob.payerLobId.toString(),
              displayName: lob.payerLobName
            } as SearchDataList;
          });
        })) :
      this.getPayerDataForCombobox(filterStr);
  }

  public getPayerLobDataForComboboxPaging(filterStr: string, restartRowNumber: number, pageSize: number,
                                          familyId: number = null, planId: number = null,
                                          accountId: number = null): Observable<Array<SearchDataList>> {
    const filter = filterStr ? `[{ "operator": "ilike", "value": "%${filterStr}%", "property": "name" }]` : null;
    return familyId !== null && planId !== null && accountId !== null ?
      this.payerLOBService.getPayerLobs(familyId, planId, accountId, null, filter, restartRowNumber, pageSize)
        .pipe(map(lobs => {
          return lobs.map(lob => {
            return {
              id: lob.payerLobId.toString(),
              displayName: lob.payerLobName
            } as SearchDataList;
          });
        })) :
      this.getPayerDataForComboboxPaging(filterStr, restartRowNumber, pageSize);
  }

  public importAddress(fileToUpload: File): Observable<BulkAddressImport> {
    return this.payerLOBService.importAddressChanges(fileToUpload);
  }

  public getPayerDataForCombobox = (filterStr: string): Observable<Array<SearchDataList>> => {
    filterStr = filterStr ? JSON.stringify([{
      property: 'name',
      value: filterStr + '%',
      operator: IntegraSearchOperators.like,
    } as ApiFilterRequest]) : null;
    return this.payerSearch(null, filterStr, 0, 10).pipe(
      map((payerList) => {
        const srchDataList: Array<SearchDataList> = [];
        payerList.forEach((family) => {
          family.payerPlans?.forEach((plan) => {
            plan.payerAccts?.forEach((account) => {
              account.payerLobs?.forEach((lob) => {
                const name = family.payerFamName + ' - ' + plan.payerPlanName + ' - ' + account.payerAcctName + ' - ' + lob.payerLobName;
                srchDataList.push({
                  id: lob.payerLobId.toString(),
                  displayName: name
                });
              });
            });
          });
        });
        return srchDataList;
      })
    );
  }

  private getPayerLobForComboboxPaging(filter: string, restartRowNumber: number,
                                       pageSize: number): Observable<Array<SearchDataList>> {
    return this.payerLobSearch(restartRowNumber, pageSize, null, filter).pipe(
      map((payerList) => {
        const searchDataList: Array<SearchDataList> = [];
        payerList.forEach((payer) => {
          searchDataList.push({
            id: payer.payerLobId.toString(),
            displayName: payer.payerName,
            searchName: payer.payerName
          });
        });
        return searchDataList;
      })
    );
  }

  public getPayerDataForComboboxPaging(filterStr: string, restartRowNumber: number,
                                       pageSize: number): Observable<Array<SearchDataList>> {
    const filter = filterStr ? JSON.stringify([{
      property: 'wildSearch',
      value: filterStr,
      operator: IntegraSearchOperators.like
    } as ApiFilterRequest]) : null;

    return this.getPayerLobForComboboxPaging(filter, restartRowNumber, pageSize);
  }

  public getPayerLobByFamIdForComboboxPaging(payerFamId: number, filterStr: string, restartRowId: number,
                                              pageSize: number): Observable<Array<SearchDataList>> {
    const filter: ApiFilterRequest[] = [];
    if (filterStr && filterStr > '') {
      filter.push({
        property: 'wildSearch',
        value: filterStr,
        operator: IntegraSearchOperators.like,
        group: 10
      })
    }
    if (payerFamId && payerFamId > 0) {
      filter.push({
        property: 'payerFamilyID',
        value: payerFamId.toString(),
        operator: IntegraSearchOperators.equals,
        group: 20
      })
    }
    const apiFilterStr = filter.length > 0 ? JSON.stringify(filter) : null;

    return this.getPayerLobForComboboxPaging(apiFilterStr, restartRowId, pageSize);
  }

  public getPayerFamDataForComboboxPaging(filterStr: string, restartRowNumber: number,
                                          pageSize: number): Observable<Array<SearchDataList>> {
    const filters: ApiFilterRequest[] = [
      {
        property: 'status',
        value: 'ACTIVE',
        operator: IntegraSearchOperators.equals,
        group: 99,
      },
    ];

    if (filterStr) {
      filters.unshift({
        property: 'name',
        value: `${filterStr}%`,
        operator: IntegraSearchOperators.like,
        group: 1,
      });
    }

    const filter = JSON.stringify(filters);
    return this.payerFamSearch(restartRowNumber, pageSize, null, filter).pipe(
      map((payerList) => {
        const srchDataList: Array<SearchDataList> = [];
        payerList.forEach((payer) => {
          srchDataList.push({
            id: payer.payerFamId.toString(),
            displayName: payer.payerFamName,
            searchName: payer.payerFamName
          });
        });
        return srchDataList;
      })
    );
  }

  getPayerLobsByFamilyForComboBox(
    restartRowNum: number, pageSize: number, filter: string, payerFamId: string, apiConfig: {longDisplayName: boolean},
  ) {
      const apiFilters: ApiFilterRequest[] = [
        {operator: IntegraSearchOperators.equals, value: payerFamId, property: 'payerFamilyID', group: 1},
      ];
      if (filter && filter > '') {
        apiFilters.push({ operator: IntegraSearchOperators.like, value: `${filter}%`, property: 'name', group: 2 });
      }

      const filterStr = JSON.stringify(apiFilters);
      const result = this.payerSearchService.searchPayerDtls(null, filterStr, restartRowNum, pageSize, 'response')
      .pipe(map(result => {
        const restartRowHdr = result.headers.get('restart-row-id');
        return {
          restartRowNum: restartRowHdr ? parseInt(restartRowHdr) : null,
          items: result.body.map(item => ({
            id: item.payerLobId.toString(),
            displayName: apiConfig.longDisplayName ? item.payerName : item.payerLobName,
          })),
        };
      }));

      return result;
  }

  getDataListForLookup(listTypeName: string, apiConfig: any, filter: string): Observable<ListItemDtl[]> {
    if (listTypeName === 'accounts') {
      if (apiConfig.payerPlanId) {
        return this.getPayerAccounts(apiConfig.payerFamId, apiConfig.payerPlanId, filter).pipe(
          map(PayersMappingService.payerAccountToListItem)
        );
      } else {
        return this.getPayerFamilyAccountsList(apiConfig.payerFamId).pipe(
          map(PayersMappingService.payerAccountToListItem)
        );
      }
    }
  }

  /* ACTIVITY LOG */
  public getPayerFamilyChangesByID(payerFamId: number): Observable<Array<Change>> {
    return this.payerFamilyService.getPayerFamilyChangesByID(payerFamId);
  }

  public getPayerFamilyActivityByID(payerFamId: number): Observable<Array<Action>> {
    return this.payerFamilyService.getPayerFamilyActivityByID(payerFamId);
  }

  public getPayerPlanChangesByID(payerFamId: number, payerPlanId: number): Observable<Array<Change>> {
    return this.payerPlanService.getPayerPlanChangesByID(payerFamId, payerPlanId);
  }

  public getPayerAccountChangesByID(payerFamId: number, payerPlanId: number, payerAcctId: number): Observable<Array<Change>> {
    return this.payerAccountsService.getPayerAccountChangesByID(payerFamId, payerPlanId, payerAcctId);
  }

  public getPayerAccountActivityByID(payerFamId: number, payerPlanId: number, payerAcctId: number): Observable<Array<Action>> {
    return this.payerAccountsService.getPayerAccountActivityByID(payerFamId, payerPlanId, payerAcctId);
  }

  public getPayerLobChangesByID(
    payerLobId: number, payerPlanId: number, payerFamId: number, payerAcctId: number
  ): Observable<Array<Change>> {
    return this.payerLOBService.getPayerLobChangesByID(payerFamId, payerPlanId, payerAcctId, payerLobId);
  }

  public getPayerLobActivityByID(payerFamId: number, payerPlanId: number, payerAcctId: number, payerLobId: number
  ): Observable<Array<Action>> {
    return this.payerLOBService.getPayerLobActivityByID(payerFamId, payerPlanId, payerAcctId, payerLobId);
  }

  /* END ACTIVITY LOG */

  /* PAYER PLAN */

  public getPayerPlanByPlanID(payerPlanId: number): Observable<PayerPlanDetails> {
    return this.payerPlanService.getPayerPlanByPlanID(payerPlanId);
  }

  public getPayerPlans(
    payerFamId: number, sort?: string, filter?: string, restartRowId?: number, pageSize?: number
  ): Observable<Array<PayerPlanList>> {
    return this.payerPlanService.getPayerPlans(payerFamId, sort, filter, restartRowId, pageSize);
  }

  public savePayerPlan(payerPlan: PayerPlan, payerFamId: number, payerPlanId?: number): Observable<PayerPlan> {
    if (payerPlanId) {
      return this.updatePayerPlan(payerFamId, payerPlanId, payerPlan);
    } else {
      return this.createPayerPlan(payerFamId, payerPlan);
    }
  }

  public createPayerPlan(payerFamId: number, payerPlan: PayerPlan): Observable<PayerPlan> {
    return this.payerPlanService.createPayerPlan(payerFamId, payerPlan);
  }

  public updatePayerPlan(payerFamId: number, payerPlanId: number, payerPlan: PayerPlan): Observable<PayerPlan> {
    return this.payerPlanService.updatePayerPlan(payerFamId, payerPlanId, payerPlan);
  }

  /* END PAYER PLAN */

}
