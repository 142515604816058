<kendo-editor
  [disabled]="disabled"
  [(ngModel)]="value"
  (change)="writeValue($event)"
  (valueChange)="valueChange.emit($event)"
  (blur)="onBlur()"
>
  <kendo-toolbar>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
      <kendo-toolbar-button
        kendoEditorAlignJustifyButton
      ></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button
        kendoEditorInsertUnorderedListButton
      ></kendo-toolbar-button>
      <kendo-toolbar-button
        kendoEditorInsertOrderedListButton
      ></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
  </kendo-toolbar>
</kendo-editor>
