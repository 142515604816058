import { drawing } from '@progress/kendo-drawing';
import { isFunction } from './../common';
const {
  dotsPattern,
  verticalStripesPattern,
  crosshatchPattern,
  diagonalStripesPattern,
  gridPattern
} = drawing;
const patternMap = {
  dots: dotsPattern,
  verticalStripes: verticalStripesPattern,
  crosshatch: crosshatchPattern,
  diagonalStripes: diagonalStripesPattern,
  grid: gridPattern
};
export function evaluatePattern(options, point) {
  return isFunction(options) ? options(point) : options;
}
export function createPatternFill(options, fill, point) {
  const patternOptions = evaluatePattern(options, point);
  const pattern = patternOptions && patternMap[patternOptions.type];
  return pattern ? pattern(Object.assign({}, fill, patternOptions)) : fill;
}